import React, { useState,useEffect } from 'react';
import './Adminsidebar.css';
import { NavLink,BrowserRouter as Router,Route,Redirect,Switch} from 'react-router-dom';
import { checkAuthenticated,load_user,fetchPayment } from './../actions/auth';
import {connect} from 'react-redux';
import image from './JIMS.png';
import logo from './school3.png';
import Customers from './Customers';
import Products from './Products';
import Dashboard from './Dashboard';
import Cinvoice from './Cinvoice';
import SlideToggle from "react-slide-toggle";
import Vinvoice from './Vinvoice';
import Login from './Login';
import Settings from './Settings';
import Payment from './Payment';
import Addstudent from './Addstudent';
import Viewstudents from './Viewstudents';
import Newstaff from './Newstaff';
import Viewstaffs from './Viewstaffs';
import Viewdorms from './Viewdorms';
import Addclass from './Addclass';
import Viewclass from './Viewclass';
import Addassignment from './Addassignment';
import Viewassignment from './Viewassignment';
import Addexam from './Addexam';
import Viewexam from './Viewexam';
import Recordresult from './Recordresult';
import Viewmarks from './Viewmarks';
import Addfee from './Addfee';
import Viewfeetype from './Viewfeetype';
import Makepayment from './Makepayment';
import Viewpayments from './Viewpayments';
import Addbook from './Addbook';
import Issuebook from './Issuebook';
import Issuedbooks from './Issuedbooks';
import Viewbooks from './Viewbooks';
import Allguardian from './Allguardian';
import Allstaff from './Allstaff';
import Selectguardian from './Selectguardian';
import Selectstaff from './Selectstaff';
import Adddorm from './Adddorm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding,faTimes,faBars,faSignOut,faUser,faUserAlt,faUserCircle,faGraduationCap,faAngleDown,faAngleUp,faUserFriends,faHome,faCreditCard,faBook,faCommentSms,faBookOpen,faReceipt} from '@fortawesome/free-solid-svg-icons'
import { FaGraduationCap, FaSms } from 'react-icons/fa';
import Student from './Student';
import Viewteacher from './Viewteacher';
import Createinv from './Createinv';
import Viewinv from './Viewinv';
import Inv from './Inv';
import Outbox from './Outbox';
import Allowances from './Allowances';
import Adduser from './Adduser';
import Viewuser from './Viewuser';
import Deductions from './Deductions';
import Advance from './Advance';
import Tabs from './Tabs';
import Payroll from './Payroll';
import Payslip from './Payslip';
import P9 from './P9';
const Adminsidebar =({click,isAuthenticated,checkAuthenticated,handleClick,fetchPayment,useremail,isadmin,isprimary,handleClick1,click1,handleClick2,click2,handleClick3,click3,handleClick4,click8,handleClick8,click4,handleClick5,click5,handleClick6,click6,handleClick7,click7,handleClick9,click9,handleClick10,click10,isstaff,isparent,students,staff,classes,dorms,exams,finance,library,payroll,users})=> {
  const [clicked, setClick] = useState(false);
  const [assignment, setAssignment] = useState(false);
  const [mobilescreen, setMobileScreen] = useState(false);
  const [studentclicked, setStudent] = useState(false);
  const [staffclicked, setStaff] = useState(false);
  const [classclicked, setClass] = useState(false);
  const [dormclicked, setDorm] = useState(false);
  const [examclicked, setExam] = useState(false);
  const [feeclicked, setFee] = useState(false);
  const [libraryclicked, setLibrary] = useState(false);
  const [sms, setSms] = useState(false);
  const handleSubmenu = () => setClick(!clicked);
  const reLoadPage=()=>{
       
    window.location.reload();
}
const checkPath=()=>{
  if(window.location.pathname == '/addstudent'| window.location.pathname == '/viewstudents'){
   
setStudent(true)
setStaff(false)
setClass(false)
setDorm(false)
setExam(false)
setFee(false)
setSms(false)
setLibrary(false)
  }
  if(window.location.pathname == '/addstaff'|window.location.pathname == '/viewstaff'){
    setStudent(false)
    setStaff(true)
    setClass(false)
    setDorm(false)
    setExam(false)
    setFee(false)
    setSms(false)
    setLibrary(false)
      }
      if(window.location.pathname == '/addclass'|window.location.pathname == '/viewclass'){
        setStudent(false)
        setStaff(false)
        setClass(true)
        setDorm(false)
        setExam(false)
        setFee(false)
        setSms(false)
        setLibrary(false)
          }
          if(window.location.pathname == '/adddorm'|window.location.pathname == '/viewdorm'){
            setStudent(false)
            setStaff(false)
            setClass(false)
            setDorm(true)
            setExam(false)
            setFee(false)
            setSms(false)
            setLibrary(false)
              }
              if(window.location.pathname == '/addexam'|window.location.pathname == '/viewexam'|window.location.pathname == '/recordresult'){
                setStudent(false)
                setStaff(false)
                setClass(false)
                setDorm(false)
                setExam(true)
                setFee(false)
                setSms(false)
                setLibrary(false)
                  }
                  if(window.location.pathname == '/addfee'|window.location.pathname == '/viewfeetype'|window.location.pathname == '/makepayment'|window.location.pathname == '/viewpayments'){
                    setStudent(false)
                    setStaff(false)
                    setClass(false)
                    setDorm(false)
                    setExam(false)
                    setFee(true)
                    setSms(false)
                    setLibrary(false)
                      }
                      if(window.location.pathname == '/addbook'|window.location.pathname == '/issuebook'|window.location.pathname == '/issuedbooks'|window.location.pathname == '/viewbooks'){
                        setStudent(false)
                        setStaff(false)
                        setClass(false)
                        setDorm(false)
                        setExam(false)
                        setFee(false)
                        setLibrary(true)
                        setSms(false)
                          }
                          if(window.location.pathname == '/allguardian'|window.location.pathname == '/selectguardian'|window.location.pathname == '/allstaff'|window.location.pathname == '/selectstaff'){
                            setStudent(false)
                            setStaff(false)
                            setClass(false)
                            setDorm(false)
                            setExam(false)
                            setFee(false)
                            setSms(true)
                            setLibrary(false)
                              }
}
useEffect(()=>{
  checkAuthenticated();
  
  //check if mobile screen
 
  if (window.innerWidth < 960) {
    setMobileScreen(true);
  } else {
    setMobileScreen(false);
  }
  fetchPayment(useremail)
    },[]) 

    var testme=true;
    if(isAuthenticated=="false"){
     
      }
    return (
      <div className=''>{
        <Router>
        <div className={click ? ' sidenav active' : 'sidenav'}>
          
         <div className='closebtn1' onClick={handleClick}></div>
          <ul className=''>
            
            
          <NavLink  to='/' > <div className='jimslogo ml-5 mt-3'> <img src={logo}height="30px" width={''} /></div>
          <h6 className=' mr-1 whitecolor'>SCHOOLSUITE</h6></NavLink>
           <div className='sidenav1'>
             {isadmin=="true" | isstaff=="true" | isparent=="true"| students?<div className='slide1 mm bordertop'>
              <SlideToggle collapsed className='slide1 '
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible" >
       
        <FontAwesomeIcon icon={faGraduationCap}className="linkitem1 mr-2" />
      <label className={studentclicked?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick1();}}>
       Students
      </label><span className={studentclicked? 'whiteicon':''}> {click1? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
        {isadmin=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/addstudent'className='' activeClassName="activelink" > Add Student 
                </NavLink></li>:""}
        {isadmin=="true" | isstaff=="true" | isparent=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/viewstudents' activeClassName="activelink" > View Students
                </NavLink></li>:""}
              

        </div>
      </div>
    </div>
  )}
/>
</div>:""}
{isadmin=="true"| staff ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
    
       <FontAwesomeIcon icon={faUserFriends}className="linkitem1 mr-2" />
      <label className={staffclicked?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick2();}}>
        Staff
      </label><span className={staffclicked? 'whiteicon':''}> {click2? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
              <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/addstaff' activeClassName="activelink" > Add Staff
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/viewstaff' activeClassName="activelink" > View Staffs
                </NavLink></li>
              

        </div>
      </div>
    </div>
  )}
/>
</div>:""}
{isadmin=="true"| classes ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
      <FontAwesomeIcon icon={faHome}className="linkitem1 mr-2" />
       
      <label className={classclicked?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick3();}}>
        Classes
      </label><span className={classclicked? 'whiteicon':''}>{click3? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
              <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/addclass' activeClassName="activelink" > Add Class
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/viewclass' activeClassName="activelink" > View Classes
                </NavLink></li>
              

        </div>
      </div>
    </div>
  )}
/>
</div>:""}
{isadmin=="true"| dorms ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
      <FontAwesomeIcon icon={faBuilding}className="linkitem1 mr-2" />
       
      <label className={dormclicked?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick4();}}>
       Dorms
      </label><span className={dormclicked? 'whiteicon':''}>{click4? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
              <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/adddorm' activeClassName="activelink" > Add Dorm
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/viewdorms' activeClassName="activelink" > View Dorm
                </NavLink></li>
              

        </div>
      </div>
    </div>
  )}
/>
</div>:""}
{assignment ? <div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
       <i class="fa fa-address-book linkitem1" aria-hidden="true"></i> 
      <label className="my-collapsible__toggle toggle1 mt-2 pr-1"  onClick={() => { toggle(); handleSubmenu();handleClick5();}}>
        Assignments
      </label><i class={click5 ? 'fa fa-angle-up ml-3 sideicon mt-2' : 'fa fa-angle-down ml-3 sideicon mt-2'} aria-hidden="true"></i>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
              <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/addassignment' activeClassName="activelink" > Add Assignment
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/viewassignment' activeClassName="activelink" > Assignments 
                </NavLink></li>
               
              

        </div>
      </div>
    </div>
  )}
/>
</div>:""}
{isadmin=="true" | isstaff=="true"| exams? 
<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
        <FontAwesomeIcon icon={faBookOpen}className="linkitem1 mr-2" />
      <label className={examclicked?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick5();}}>
        Exams
      </label><span className={examclicked? 'whiteicon':''}>{click5? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
              <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/addexam' activeClassName="activelink" > Add Exams
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/viewexam' activeClassName="activelink" > Exams 
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/recordresult' activeClassName="activelink" > Records Results
                </NavLink></li>
              

        </div>
      </div>
    </div>
  )}
/>
</div>:""}
{isadmin=="true"| finance ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
      <FontAwesomeIcon icon={faCreditCard}className="linkitem1 mr-2" />
      
      <label className={feeclicked?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick6()}}>
        Finance
      </label><span className={feeclicked? 'whiteicon':''}>{click6? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
        <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/addfee' activeClassName="activelink" > Add Fee Item
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/viewfeetype' activeClassName="activelink" > View F Structure
                </NavLink></li>
              <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/createinvoice' activeClassName="activelink" > Create Invoice
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/viewinvoices' activeClassName="activelink" > View Invoices
                </NavLink></li>
               
              

        </div>
      </div>
    </div>
  )}
/>
</div>:""    }  
{isadmin=="true"| isstaff=="true"| library ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
       
       <FontAwesomeIcon icon={faBook}className="linkitem1 mr-2" />
      <label className={libraryclicked?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick7();}}>
        Library
      </label><span className={libraryclicked? 'whiteicon':''}>{click7? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
              <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/addbook' activeClassName="activelink" > Add Book
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/issuebook' activeClassName="activelink" > Issue Book
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/issuedbooks' activeClassName="activelink" > Issued Books
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/viewbooks' activeClassName="activelink" > View Books
                </NavLink></li>
               
              

        </div>
      </div>
    </div>
  )}
/>
</div>  :""  }

{isadmin=="true"| sms ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
       
       <FontAwesomeIcon icon={faCommentSms}className="linkitem1 mr-2" />
      <label className={sms?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick8();}}>
        Sms
      </label><span className={sms? 'whiteicon':''}>{click8? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
              <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/allguardian' activeClassName="activelink" > All Guardian
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/selectguardian' activeClassName="activelink" > Select Guardian
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/allstaff' activeClassName="activelink" > All Staff
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/selectstaff' activeClassName="activelink" > Select Staff
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/outbox' activeClassName="activelink" > Outbox
                </NavLink></li>
              

        </div>
      </div>
    </div>
  )}
/>
</div>:"" }

{isadmin=="true"| payroll | isstaff ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
       
       <FontAwesomeIcon icon={faReceipt}className="linkitem1 mr-2" />
      <label className={sms?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick9();}}>
        Payroll & HR
      </label><span className={sms? 'whiteicon':''}>{click9? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
        {isadmin=="true"| payroll  && <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/allowances' activeClassName="activelink" > Allowances
                </NavLink></li>}
                {isadmin=="true"| payroll  &&      <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/deductions' activeClassName="activelink" > Deductions
                </NavLink></li>}
                {isadmin=="true"| payroll  && <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/advances' activeClassName="activelink" > Advances
                </NavLink></li>}
                {isadmin=="true"| payroll  &&  <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/p9forms' activeClassName="activelink" > P9 forms
                </NavLink></li>}
                {isadmin=="true"| payroll |isstaff && <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/payroll' activeClassName="activelink" > Generate Payroll
                </NavLink></li>}
              

        </div>
      </div>
    </div>
  )}
/>
</div>:"" }    

{isadmin=="true"| users ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
       
       <FontAwesomeIcon icon={faUser}className="linkitem1 mr-2" />
      <label className={sms?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick10();}}>
        Users
      </label><span className={sms? 'whiteicon':''}>{click10? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
              <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/adduser' activeClassName="activelink" > Add User
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/viewusers' activeClassName="activelink" > View Users
                </NavLink></li>
      

        </div>
      </div>
    </div>
  )}
/>
</div>:"" }  
</div>
          </ul>
         
          
         </div>
          <div className='col-lg-12 ml-5 content'>
          <Switch>
            
          
           <Route  path="/"exact={true}>
            <Dashboard />
          </Route> 
          {isadmin=="true" && <Route exact path="/addstudent/:id" component={Addstudent}/>}
          {isadmin=="true" |isparent=="true"|isstaff=="true" && <Route exact path="/viewstudent/:id" component={Student}/>}
          {isadmin=="true" && <Route exact path="/viewteacher/:id" component={Viewteacher}/>}
          {isadmin=="true" && <Route exact path="/editstaff/:id" component={Tabs}/>}
          {isadmin=="true" && <Route exact path="/editclass/:id" component={Addclass}/>}
          {isadmin=="true" && <Route exact path="/editdorm/:id" component={Adddorm}/>}
          {isadmin=="true" |isstaff=="true" && <Route exact path="/editexam/:id" component={Addexam}/>}
          {isadmin=="true" |isstaff=="true" && <Route exact path="/viewmarks/:id" component={Viewmarks}/>}
          {isadmin=="true" &&  <Route exact path="/editfeetype/:id" component={Addfee}/>}
          <Route exact path="/cinvoice">
            <Cinvoice />
          </Route> 
          {isadmin=="true" &&<Route exact path="/addstudent">
            <Addstudent />
          </Route> }

          {isadmin=="true" |isstaff=="true" && <Route exact path="/recordresult">
            <Recordresult />
          </Route> }
          {isadmin=="true" &&<Route exact path="/allguardian">
            <Allguardian />
          </Route> }
          {isadmin=="true" &&<Route exact path="/viewfeetype">
            <Viewfeetype />
          </Route> }
          {isadmin=="true" |isstaff=="true" &&<Route exact path="/issuedbooks">
            <Issuedbooks />
          </Route> }
          {isadmin=="true" |isstaff=="true" &&<Route exact path="/viewbooks">
            <Viewbooks />
          </Route> }
          {isadmin=="true" &&<Route exact path="/allstaff">
            <Allstaff />
          </Route> }
          {isadmin=="true" && <Route exact path="/selectstaff">
            <Selectstaff />
          </Route> }
          {isadmin=="true" &&<Route exact path="/adddorm">
            <Adddorm />
          </Route>}
          {isadmin=="true" && <Route exact path="/viewdorms">
            <Viewdorms/>
          </Route>}
          {isadmin=="true" && <Route exact path="/selectguardian">
            <Selectguardian />
          </Route>}
          {isadmin=="true" && <Route exact path="/outbox">
            <Outbox/>
          </Route>}
          {isadmin=="true" && <Route exact path="/addfee">
            <Addfee />
          </Route> }
          {isadmin=="true" |isstaff=="true" &&<Route exact path="/addbook">
            <Addbook />
          </Route> }
          {isadmin=="true" |isstaff=="true" && <Route exact path="/issuebook">
            <Issuebook />
          </Route>}
          {isadmin=="true" &&<Route exact path="/createinvoice">
            <Createinv />
          </Route>}
          {isadmin=="true" &&<Route exact path="/editinvoice/:inv">
            <Createinv />
          </Route>}
          {isadmin=="true" &&<Route exact path="/viewinvoice/:inv">
            <Inv />
          </Route>}
          {isadmin=="true" &&<Route exact path="/viewpayslip/:id">
            <Payslip />
          </Route>}
          {isadmin=="true" && <Route exact path="/viewinvoices">
            <Viewinv/>
          </Route> }
         {isadmin=="true" |isstaff=="true" && <Route exact path="/addexam">
            <Addexam />
          </Route> }
        {isadmin=="true" |isstaff=="true" &&  <Route exact path="/viewexam">
            <Viewexam />
          </Route> }
        {isadmin=="true" |isstaff=="true" |isparent=="true"&&  <Route exact path="/viewstudents">
            <Viewstudents />
          </Route> }
          {isadmin=="true" && <Route exact path="/addstaff">
            <Newstaff/>
          </Route> }
          {isadmin=="true" && <Route exact path="/viewstaff">
            <Viewstaffs/>
          </Route> }
          
          {isadmin=="true" &&  <Route exact path="/addclass">
            <Addclass/>
          </Route> }
          {isadmin=="true" &&  <Route exact path="/deductions">
            <Deductions/>
          </Route> }
          {isadmin=="true" &&  <Route exact path="/payroll">
            <Payroll/>
          </Route> }
          {isadmin=="true" &&  <Route exact path="/p9forms">
            <P9/>
          </Route> }

          {isadmin=="true" &&  <Route exact path="/allowances">
            <Allowances/>
          </Route> }
          {isadmin=="true" &&  <Route exact path="/advances">
            <Advance/>
          </Route> }
          {isadmin=="true" && <Route exact path="/viewclass">
            <Viewclass/>
          </Route>}
          {isadmin=="true" && <Route exact path="/adduser">
            <Adduser/>
          </Route>}
          {isadmin=="true" && <Route exact path="/viewusers">
            <Viewuser/>
          </Route>}
          <Route exact path="/addassignment">
            <Addassignment/>
          </Route>
          <Route exact path="/viewassignment">
            <Viewassignment/>
          </Route>
          <Route exact path="/vinvoice">
            <Vinvoice/>
          </Route> 
         
          <Route exact path="/account">
            <Settings />
          </Route> 
          <Route exact path="/payment">
            <Payment />
          </Route> 
      <Route path="/customers">
            <Customers />
          </Route> 
          <Route exact path="/products">
            <Products />
          </Route> 
          
          </Switch>
          </div>
          </Router>}
         </div>
      )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  isadmin:state.auth.isadmin,
  isprimary:state.auth.isprimary,
  students:state.auth.students,
  staff:state.auth.staff,
  classes:state.auth.classes,
  dorms:state.auth.dorms,
  exams:state.auth.exams,
  finance:state.auth.finance,
  library:state.auth.library,
  sms:state.auth.sms,
  payroll:state.auth.payroll,
  users:state.auth.users,
  isstaff:state.auth.isstaff,
  isparent:state.auth.isparent,
  useremail:state.auth.useremail
  });
export default connect(mapStateToProps,{checkAuthenticated,load_user,fetchPayment})(Adminsidebar)

