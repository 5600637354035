import axios from 'axios';
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_FAIL,
    AUTHENTICATED_SUCCESS,
    LOGOUT,
    IS_ACTIVE,
    NOT_ACTIVE,
    INVOICENUMBER_SET,
    INVOICENUMBER_REMOVE,
    USER_SUCCESS,
    USER_FETCHED,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    PAYMENT_FAIL,
    PAYMENT_SUCCESS,
    REGISTER_FAIL,
    REGISTER_SUCCESS
} from './types';
import axiosInstance from '../axios'
import {Link,Redirect,Router,useNavigate} from 'react-router-dom';


export const checkAuthenticated=()=> async dispach=>{
    
if(localStorage.getItem('access')){
    
    const body=localStorage.getItem('access');
    try{
const res = axios.post('/validate/',{token:body})
if((await res).data.code== 'true')
{
    dispach({
        type: AUTHENTICATED_SUCCESS
    });

}else{
    
    dispach({
        type: AUTHENTICATED_FAIL
    });
    
}
    }catch(err){
       
    }
}else{
    dispach({
        type: AUTHENTICATED_FAIL
    });
    
    return <Redirect to='/login'/>
  
}

};

export const load_user=()=>async dispach=>{

   const config={
    headers:{
        Authorization: `JWT ${localStorage.getItem('access')}`,
        'Content-Type':'application/json',
        acccept:'application/json'
    }
   };
        try{
            const res= await axiosInstance.get('/users/me/',config);
            dispach({
                type: USER_SUCCESS,
                payload: res.data
            })
              
            }catch(err){
                dispach({
                    type: USER_LOADED_FAIL,
                })
            } 
            


};

export const register_user=(data)=>async dispach=>{

    const config={
         'Content-Type':'application/json'
    };
         try{
             const res= await axiosInstance.post('/users/',data,config);
             dispach({
                 type: REGISTER_SUCCESS,
                 payload: res.data
             })
               
             }catch(err){
                 dispach({
                     type: REGISTER_FAIL,
                 })
             } 
             
 
 
 };

export const login=(email,password)=>async dispach=>{
    
try{
   
const res= await axios
.post('/login/',{
    username:email,
    password:password,
})
.then(res => {
   if(res.data.schoolid){
    console.log("hello")
    localStorage.setItem('access', res.data.access)
    localStorage.setItem('useremail',res.data.schoolid); 
    localStorage.setItem('user1',res.data.lastname); 
    localStorage.setItem('username',res.data.username); 
    localStorage.setItem('email',res.data.email); 
    localStorage.setItem('isadmin',res.data.isadmin); 
    localStorage.setItem('isprimary',res.data.isprimary); 
    localStorage.setItem('isstaff',res.data.isstaff); 
    localStorage.setItem('isparent',res.data.isparent); 
    localStorage.setItem('students',res.data.students);
    localStorage.setItem('staff',res.data.staff);  
    localStorage.setItem('classes',res.data.classes); 
    localStorage.setItem('dorms',res.data.dorms); 
    localStorage.setItem('exams',res.data.exams); 
    localStorage.setItem('finance',res.data.finance);
    localStorage.setItem('library',res.data.sms);  
    localStorage.setItem('payroll',res.data.payroll); 
    localStorage.setItem('users',res.data.users); 
    dispach(load_user())
dispach({
   type: LOGIN_SUCCESS,
   payload: res.data
});
window.location.href = "/";
   }else{
    dispach({
        type: LOGIN_FAIL,
        
     });
   }
});

}
catch(err){
    dispach({
        type: LOGIN_FAIL,
    })
    dispach(checkAuthenticated());
    console.log(err);

}

};

export const fetchUser=(email)=>async dispach=>{
   
try{
  
const [res]= axios
.post('/totalcompany/',{
    useremail:email
})
.then((ress)=>{
console.log(ress.data)
ress.data.length && localStorage.setItem('adminname', JSON.stringify(ress.data[0].names));

dispach({
    type: USER_FETCHED,
    payload: ress.data
})

})

      
}
catch(err){
   

}

};

export const fetchPayment=(email)=>async dispach=>{
  
    try{
        
    const [res]=  axios
    .post('/paysearch/',{
        useremail:email
    })
    .then((ress)=>{
        if(ress.data.length){
            
    localStorage.setItem('payment', JSON.stringify(true));
    dispach({
        type: PAYMENT_SUCCESS,
        payload: ress.data
    })}else{
        
    localStorage.setItem('payment', JSON.stringify(false));
    dispach({
        type: PAYMENT_FAIL,
    })}
    
    })
    
          
    }
    catch(err){
       
    
    }
    
    };

export const logout=()=>dispach=>{
    localStorage.removeItem('useremail');
    window.location.href = "/login";
    dispach({
        type:LOGOUT
    });
};
export const setId=(invoice)=>dispach=>{
    
    localStorage.setItem('id', JSON.stringify(invoice));
    dispach({
        type:INVOICENUMBER_SET,
        payload:invoice
    });
};
export const removeInvoice=()=>dispach=>{
    dispach({
        type:INVOICENUMBER_REMOVE,
    });
};

export const fetchUser1=(useremail)=>dispach=>{
     //fetch user
     let data1 ;
     axios({
       method:'post',
       url:'/totalcompany/',
       data:{useremail:useremail},
     })
     .then(res => {
         data1 = res.data;
          //set fields
     dispach({
        type: USER_FETCHED,
        payload: data1[0].names
    })
     
     })
     .catch(err => {})
    
};

export const checkActive=()=>dispach=>{

    if(window.location.pathname == '/poster'|window.location.pathname == '/business-card' |window.location.pathname == '/wedding-card' |window.location.pathname == '/logo'){
    dispach({
        type:IS_ACTIVE
    })}else{
        dispach({
            type:NOT_ACTIVE
        })
    }
};
export const resetPassword=(email,token)=> async dispach=>{
    const config={
        headers:{
            'Content-Type':'application/json',
        }
       };
       const body={email:email,token:token};
       try{
         axios.post("/sendresetemail/",body)
         .then(res => {
           const data1 = res.data;
         if(data1=="success"){
            dispach({
                type:PASSWORD_RESET_SUCCESS
                
            })
            localStorage.setItem('preset',true);
         }else{
            dispach({
                type:PASSWORD_RESET_FAIL
            })
         }
        
        })
        .catch(err => {
            console.log('error here')

        })
       }
       catch(err){
        
        dispach({
            type:PASSWORD_RESET_FAIL
        })
       }
}

export const resetPasswordConfirm=(uid,token,new_password,re_new_password)=> async dispach=>{
   
    const headers={
            'Content-Type':'application/json',
        
       };
       const body=JSON.stringify({uid,token,password:new_password,re_new_password});
       try{
        await axios.post("/resetpassword/",{uid,token,password:new_password,re_new_password})
        dispach({
            type:PASSWORD_RESET_CONFIRM_SUCCESS
        })
       }
       catch(err){
        dispach({
            type:PASSWORD_RESET_CONFIRM_FAIL
        })
       }
}