import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify'; 
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
 
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";

// Then import the virtualized Select HOC
import VirtualizedSelect from 'react-virtualized-select'

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Alert,
} from "reactstrap";
import axios from "axios";
import  { encrypt , decrypt } from 'react-crypt-gsm';
import useKeyboardShortcut from 'use-keyboard-shortcut'
import { useSelect } from 'react-select-search';
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation,useParams } from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addstudent.css'
import SelectSearch from 'react-select-search';
import Select from 'react-dropdown-select'
const Addstudent=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,id,useremail,payment,match,username1})=> {
 
  const [showhide,setShowhide]=useState(false);
  const [customerFetch,setCustomerFetch]=useState(false);
  const [amountvisible,setAmountVisible]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [showhide1,setShowhide1]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [names, setName] = useState("");
  const [filteredinvoice, setFilteredInvoice] = useState("");
  const [name, setNames] = useState("");
  const [studentadm, setStudentadm] = useState("");
  const [studentadmError, setStudentadmError] = useState(false);
  const [first_name, setFname] = useState("");
  const [first_nameError, setFnameError] = useState(false);
  const [last_name, setLname] = useState("");
  const [last_nameError, setLnameError] = useState(false);
  const [dob, setDob] = useState("");
  const [dobError, setDobError] = useState(false);
  const [class_name, setCname] = useState("");
  const [class_nameError, setCnameError] = useState(false);
  const [dormitory, setDormitory] = useState("");
  const [dormitoryError, setDormitoryError] = useState(false);
  const [religion, setReligion] = useState("");
  const [religionError, setReligionError] = useState(false);
  const [adm_date, setAdmdate] = useState("");
  const [adm_dateError, setAdmdateError] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState(false);
  const [gender, setGender] = useState("");
  const [gender1, setGender1] = useState("Select Gender");
  const [genderError, setGenderError] = useState(false);
  const [county, setCounty] = useState("");
  const [countyError, setCountyError] = useState(false);
  const [health, setHealth] = useState("");
  const [healthError, setHealthError] = useState(false);
  const [parent_first_name, setPfname] = useState("");
  const [parent_first_nameError, setPfnameError] = useState(false);
  const [parent_last_name, setPlname] = useState("");
  const [parent_last_nameError, setPlnameError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [email,setEmail]=useState("");
  const [emailError,setEmailError]=useState(false);
  const [mobile,setMobile]=useState("");
  const [mobileError,setMobileError]=useState(false);
  const [town,setTown]=useState("");
  const [townError,setTownError]=useState(false);
  const [id1,setId]=useState("");
  const [inumber,setInumber]=useState("hello");
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(false);
  const [wait,setWait]=useState(false);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [dropopen,setDropOpen]=useState(false);
  const [dropdown,setDropDown]=useState("Unpaid");
  const [issuedate,setIssueDate]=useState("");
  const [duedate,setDueDate]=useState("");
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const [studentNumber,setSNumber]=useState('');
  const [studentnid,setSNumberid]=useState('');
  const [relation,setRelation]=useState('');
  const [relationError,setRelationError]=useState('');
  const [dorm,setDom]=useState('');
  const [dorm1,setDom1]=useState('');
  const [musyoki,setMusyoki]=useState('musyoki');
  const [inputList1,setInputList1]=useState([]);
  const [heading,setHeading]=useState(['Names', 'Mobile','Action']);
  const [inputList, setInputList] = useState([]);
  const [notInternet,setNoInternet]=useState(false);
  const [success,setSuccess]=useState(false);
  const [validated,setValidated]=useState(false);
  const [result,setResult]=useState(false);
  const [options, setOptions] = useState( [
    { id: 1, country: "1 Blue" },
    { id: 2, country: "2 Blue" }, 
    { id: 3, country: "3 Blue" },
    { id: 4, country: "4 Blue" },
] )
const [inputStyle, setInputStyle] = useState( { })
const [inputStyle1, setInputStyle1] = useState( { })
const [inputStyle2, setInputStyle2] = useState( { })
const [inputStyle3, setInputStyle3] = useState( { })
const [inputStyle4, setInputStyle4] = useState( { })

const [options1, setOptions1] = useState( [
  { id: 1, country: "Twiga" },
  { id: 2, country: "Ndovu" }, 
  { id: 3, country: "Kiboko" },
  { id: 4, country: "Fisi" },
] )

const [options2, setOptions2] = useState( [
  { id: 1, country: "Christian" },
  { id: 2, country: "Muslim" }, 
  { id: 3, country: "Hindu" },
  { id: 4, country: "Pagan" },
] )

const [options3, setOptions3] = useState( [
  { id: 1, country: "Boarding" },
  { id: 2, country: "Day" }, 
  
] )

const [options4, setOptions4] = useState( [
  { id: 1, country: "Male" },
  { id: 2, country: "Female" }, 
  
] )
const [options5, setOptions5] = useState( [
  { id: 1, country: "Father" },
  { id: 2, country: "Mother" }, 
  { id: 3, country: "Relative" }, 
  { id: 4, country: "Sponsor" }, 
  
] )
const [selectedOptions, setSelectedOptions] = useState([])
const [selectedOptions1, setSelectedOptions1] = useState([])
const [selectedOptions2, setSelectedOptions2] = useState([])
const [selectedOptions3, setSelectedOptions3] = useState([])
const [selectedOptions4, setSelectedOptions4] = useState([])
const [adminMenu, setadminMenu] = useState(false);
const options7 = ['One', 'Two', 'Three', 'Four', 'Five'];

const ref = useRef();

  toast.configure()
  const showToast = () => toast("Operation succesful",{position: toast.POSITION.TOP_CENTER});
  const adminMenuClick = () => {
    setadminMenu(!adminMenu);
   
  }
  const showToastMessage = () => {

    toast.success('Record saved succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };
  const showToastMessage1 = () => {

    toast.success('Record updated succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };
  const showToastMessage2 = () => {

    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  };    
 const fetchStudentNumber=()=>{
  let data4 ;
  let data5
  axios({
   method:'post',
   url:'/totalstudentnumber/',
   data:{useremail:useremail},
 })
 .then(res => {
     data4 = res.data;
     if(data4.length>0){
data5=parseInt(data4[0].stdnumber)+1
      setSNumber(data5)
      setStudentadm('ADM-'+data5 );
      setSNumberid(data4[0].id)
     }else{
      setStudentadm('ADM-1')
      setSNumber(1)
     }
     
 })
 .catch(err => {})

 }
  useEffect(()=>{
 
    //check authentication
    fetchStudentNumber()
    checkAuthenticated();
    fetchPayment(useremail)
    //fetch classes
    let data4 ;
      axios({
       method:'post',
       url:'/totalclass/',
       data:{useremail:useremail},
     })
     .then(res => {
         data4 = res.data;
         setOptions(data4);
       
     })
     .catch(err => {})
     //fetch dorms

   let data5 ;
      axios({
       method:'post',
       url:'/totaldorms/',
       data:{useremail:useremail},
     })
     .then(res => {
         data5 = res.data;
         setOptions1(data5);
       
     })
     .catch(err => {}) 
//check if edit is true

const invoicenumber = /[^/]*$/.exec(window.location.href)[0];
//const result = invoicenumber.includes("-") && invoicenumber.length<40 ? true : false;
if(match.params.id>=0)

 {
  setResult(true)
  setSavebtn(false)
 }
    
      },[])
      useEffect(()=>{
 //fetch edit records when editing
 if(result){
  setSavebtn(false)
  
 //fetch student records
 axios({
  method:'post',
  url:'/studentsearch/',
  data:{id:match.params.id},
})
.then((Response)=>{
 const datareceived=Response.data;

//set fields
setStudentadm(datareceived[0].studentadm)
setEmail(datareceived[0].email)
setFname(datareceived[0].first_name)
setLname(datareceived[0].last_name)
setDob(datareceived[0].dob)
setSelectedOptions([{'label':datareceived[0].class_name}])
setSelectedOptions1([{'label':datareceived[0].dormitory}])
setSelectedOptions2([{'label':datareceived[0].religion}])
setSelectedOptions3([{'label':datareceived[0].category}])
setSelectedOptions4([{'label':datareceived[0].gender}])
setAdmdate(datareceived[0].adm_date)
setCounty(datareceived[0].county)
setHealth(datareceived[0].health)
setPfname(datareceived[0].parent_first_name)
setPlname(datareceived[0].parent_last_name)
setPhone(datareceived[0].phone)
setTown(datareceived[0].town)
setId2(datareceived[0].id)
setDom(datareceived[0].dormitory)
setDom1(datareceived[0].dormitory)
setCname(datareceived[0].class_name)
setReligion(datareceived[0].religion)
setCategory(datareceived[0].category)
setGender(datareceived[0].gender)
setRelation(datareceived[0].relation)
  })
 //set edit
 setEdit(true);
 setEditbtn(true)

   //set local invoice
  // setInvoiceNumber(invoicenumber)
  
 }else{
  setSavebtn(true)
  
 }

      },[result])

      function hidesuccess() {
        return new Promise(resolve => {
          setTimeout(() => {
            setSuccess(false);
          }, 5000);
        });
      }
      
  
  const clearFields=()=>{
    setStudentadm('')
    setFname('')
    setLname('')
    setDob('')
    setSelectedOptions([])
    setSelectedOptions1([])
    setSelectedOptions2([])
    setSelectedOptions3([])
    setSelectedOptions4([])
    setCounty('')
    setTown('')
    setAdmdate('')
    setHealth('')
    setPfname('')
    setPlname('')
    //setEmail('')
    setPhone('')
   
    

  }
 

    useEffect(()=>{
      checkAuthenticated();
      
        },[]) 
        useEffect(()=>{
          //save customer
if(validated){

//save student
if(useremail=="4")
{
showToastMessage2();
}
else{
if(edit)
{

const editData = async () => {
 
  const result = await axios({
    method:'put',
    url:`/api/student/${match.params.id}/`,
    data:{studentadm,first_name:first_name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),last_name:last_name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),dob,class_name:class_name,dormitory:dorm,religion:religion,
    adm_date,category:category,gender:gender,county:county.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),town:town.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),health,parent_first_name:parent_first_name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),parent_last_name:parent_last_name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),phone,email:email.toLowerCase(),relation,owner:useremail,editedby:username1},
 })
  .then((Response)=>{
   if (Response.data){
    // setSaveSuccess(true)
     setWait(false)
     removeErrors()
showToastMessage1()
    // setSuccess(true)
     setValidated(false)
    // hidesuccess();
  
           }
  })
  .catch((error) => {
   if (error.code="ERR_BAD_RESPONSE"){
     setShowhide2(true)
     setWait(false)
     setNoInternet(true)
           }
   
  })
  
  }
  editData()
}else
{
  const saveData = async () => {
    
 fetchStudentNumber()
    const result = await axios({
      method:'post',
      url:'/api/student/',
      data:{studentadm,first_name:first_name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),last_name:last_name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),dob,class_name:class_name,dormitory:dorm,religion:religion,
       adm_date,category:category,gender:gender,county:county.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),town:town.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),health,parent_first_name:parent_first_name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),parent_last_name:parent_last_name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),phone,email:email.toLowerCase(),relation,owner:useremail,postedby:username1},
    })
    .then((Response)=>{
     if (Response.data){
       setSaveSuccess(true)
       setWait(false)
       setSavebtn(true)
       showToastMessage()
       //setSuccess(true)
       setValidated(false)
       //hidesuccess();
       clearFields()
    removeErrors()
             }
    })
    .catch((error) => {
     if (error.code="ERR_BAD_RESPONSE"){
       setShowhide2(true)
       setWait(false)
       setNoInternet(true)
             }
     
    })
    //Save student number
    if(studentNumber==1){
    const result1 = await axios({
      method:'post',
      url:'/api/studentnumber/',
      data:{stdnumber:studentNumber,owner:useremail},
      })
      .then((Response)=>{
     fetchStudentNumber()
      })
      .catch((error) => {
     
      })}else{
        const result1 = await axios({
          method:'put',
          url:`/api/studentnumber/${studentnid}/`,
          data:{stdnumber:studentNumber,owner:useremail},
          })
          .then((Response)=>{
         fetchStudentNumber()
          })
          .catch((error) => {
         
          })

      }

      const unique_id = uuid();
      const small_id = unique_id.slice(0,8);
      const encryptedStr = encrypt(small_id);
       //check if user exists
       const result2 = await axios({
        method:'post',
        url:`/searchparent/`,
        data:{username:email,smallpas:small_id,password:encryptedStr.content,parent_last_name,phone:phone,schoolid:useremail,isstaff:"True",owner:useremail},
      
        })
        .then((Response)=>{
          
          setEmail("")
         })
      
    }
    saveData()
}
}
}
          
            },[validated]) 
  if(isAuthenticated=="false"){
   return <Redirect to='/login'/>}
  
   if(payment=="false"){
   return <Redirect to='/payment'/>}
    const removeErrors=()=>{
      setFnameError(false) 
      setLnameError(false)
      setDobError(false)
      setCnameError(false)
      setInputStyle({
        'border-color':'#D3D3D3'
      })
      setReligionError(false)
      setInputStyle2({
        'border-color':'#D3D3D3'
      })
      setCategoryError(false)
                  
      setInputStyle3({
        'border-color':'#D3D3D3'
      })
      setGenderError(false)
                    
      setInputStyle4({
        'border-color':'#D3D3D3'
      })
      setAdmdateError(false)
      setCountyError(false)
      setTownError(false)
      setPfnameError(false)
      setPlnameError(false)
      setPhoneError(false)
      setEmailError(false)
      setRelationError(false)
    }

    const validateFields1=(e)=>{

    }

    const onOptionChangeHandler = (event) => {
      setDom(event.target.value) 
  }   
  const onOptionChangeHandler1 = (event) => {
    setCname(event.target.value) 
}  

const onOptionChangeHandler2 = (event) => {
  setCategory(event.target.value) 
}  
const onOptionChangeHandler3 = (event) => {
  setGender(event.target.value) 
} 
const onOptionChangeHandler4 = (event) => {
  setRelation(event.target.value) 
} 
const onOptionChangeHandler5 = (event) => {
  setReligion(event.target.value) 
} 

const onOptionChangeHandler6 = (event) => {
  setCname(event.target.value) 
} 
    const validateFields=(e)=>{
    
   e.preventDefault()
  
      if(studentadm && relation && gender && first_name && last_name && dob && adm_date
        &&  county && town && parent_first_name && parent_last_name
        && phone && email  && !email.trim().length==0 && !studentadm.trim().length==0 && !first_name.trim().length==0 
        && !last_name.trim().length==0 && !dob.trim().length==0  && 
        !adm_date.trim().length==0 &&
          !county.trim().length==0 &&
          !class_name.trim().length==0 &&
          class_name !=="Select Class" &&
          !religion.trim().length==0 &&
          religion !=="Select Religion" &&
          !category.trim().length==0 &&
          category!=="Select Category" &&
          !gender.trim().length==0 &&
          gender!=="Select Gender" &&
          !religion.trim().length==0 &&
          religion!=="Select Relationship" &&
          !relation.trim().length==0 &&
          relation!=="Select Relationship" &&
          !town.trim().length==0 && !parent_first_name.trim().length==0 && !
          parent_last_name.trim().length==0 && !phone.trim().length==0 && !email.trim().length==0){
                 
        setValidated(true)}
        else{
          if(studentadm.trim().length==0){
            setStudentadmError(true)
           
            }
            if(!relation | relation=="Select Relationship"){
              setRelationError(true)
             
              }
      if(first_name.trim().length==0){
      setFnameError(true)
     
      }
      if(last_name.trim().length==0){
        setLnameError(true)
        
        }
        
        if(dob.trim().length==0){
          setDobError(true)
         
          }
         
              if(religion.trim().length==0){
                setReligionError(true)
          
                setInputStyle2({
                  'border-color':'red'
                })}
                if(category.trim().length==0){
                  setCategoryError(true)
                  
                  setInputStyle3({
                    'border-color':'red'
                  })}
                  if(gender.trim().length==0){
                    setGenderError(true)
                    
                    setInputStyle4({
                      'border-color':'red'
                    })}
    
              if(adm_date.trim().length==0){
              setAdmdateError(true)
               } 
          
                 if(county.trim().length==0){
                  setCountyError(true)
                
                  } 
                if(town.trim().length==0){
                 
                 setTownError(true)
                } 
                         
               if(parent_first_name.trim().length==0){
                 setPfnameError(true)
           
                       } 
             if(parent_last_name.trim().length==0){
           
             setPlnameError(true)
                  } 
               if(phone.trim().length==0){
                  setPhoneError(true)
                 
                     } 
                     if(class_name.trim().length==0){
                      setCnameError(true)
                     
                         } 

               if(email.trim().length==0){
               
              setEmailError(true)
                    } 
          }
    }
     
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
      <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record {edit?"Updated":"Saved"} succesffully</div></div>
 <div className='path'><i className='fa fa-home'></i>/ Students / {edit?'Edit Student':'Add Student'}</div>
        <div className='bodysection6 col-lg-7 '>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
      

<div className=' col-lg-12 theader'>< h5>Student Details</h5> </div>
<Form >
  <div className='row rowmb pl-4'>
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
</FormGroup>}
<FormGroup className='mr-2 ml-2 mb-0'>

<Input className={studentadmError &&'errorborder'} type="text" placeholder="Student ADM"value={studentadm} onChange={e => setStudentadm(e.target.value)} name="email"  required/>
<div className={studentadmError ?'errmessage1':'errmessagehide1'}>Student Number is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input type="text"className={first_nameError &&'errorborder'} placeholder="First Name"name="author" value={first_name} onChange={e => setFname(e.target.value)} required />
<div className={first_nameError ?'errmessage1':'errmessagehide1'}>First name is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 '>
<Input type="text" className={last_nameError &&'errorborder'} placeholder="Last Name"name="author" value={last_name} onChange={e => setLname(e.target.value)} required />
<div className={last_nameError ?'errmessage1':'errmessagehide1'}>Last name is Reguired</div>
</FormGroup>

<FormGroup className='mr-2 ml-2 mtop'>
<div  style={{width:'220px',"border-color":"red",'font-family': "Times New Roman"}}className={class_nameError &&'errorborder'} >
                
           <select onChange={onOptionChangeHandler6} className={class_nameError ? 'errorborder newselect1':'newselect'}>
 
 <option>{edit && class_name.trim().length !== 0?  class_name :'Select Class'}</option>
 {options.map((item, index) => {
     return <option key={index} >
         {item.classnumeral+' '+item.classstream}
     </option>
 })}
</select>
            </div>

<div className={class_nameError ?'errmessage1':'errmessagehide1'}>Class name is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 mtop'>
<div style={{width:'220px'}} >
<select onChange={onOptionChangeHandler} className={dormitoryError ? 'errorborder newselect1':'newselect'}>
 
 <option>{edit && dorm.trim().length !== 0?  dorm :'Select Dormitory'}</option>
 {options1.map((option, index) => {
     return <option key={index} >
         {option.name}
     </option>
 })}
</select> 
            </div>



                <div className={dormitoryError ?'errmessage1':'errmessagehide1'}>Dormitory name is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 mtop'>
<div style={{width:'220px'}}className={last_nameError &&'errorborder'} >
              
          <select onChange={onOptionChangeHandler5} className={religionError ? 'errorborder newselect1':'newselect'}>
 
 <option>{edit && religion.trim().length !== 0?  religion :'Select Religion'}</option>
 {options2.map((item, index) => {
     return <option key={index} >
         {item.country}
     </option>
 })}
</select> 
            </div>
<div className={religionError ?'errmessage1':'errmessagehide1'}>Religion name is Reguired</div>
</FormGroup>


<FormGroup className='mr-2 ml-2 mtop'>
<div style={{width:'220px'}}className={last_nameError &&'errorborder'} >
              
           <select onChange={onOptionChangeHandler2} className={categoryError ? 'errorborder newselect1':'newselect'}>
 
 <option>{edit && category.trim().length !== 0?  category :'Select Category'}</option>
 {options3.map((option, index) => {
     return <option key={index} >
         {option.country}
     </option>
 })}
</select>     
            </div>
            
<div className={categoryError ?'errmessage1':'errmessagehide1'}>Category is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 mtop'>
<div style={{width:'220px'}}className={last_nameError &&'errorborder'} >
              
          <select onChange={onOptionChangeHandler3} className={genderError ? 'errorborder newselect1':'newselect'}>
 
 <option>{ gender.trim().length !== 0?  gender :'Select Gender'}</option>
 {options4.map((option, index) => {
     return <option key={index} >
         {option.country}
     </option>
 })}
</select>    
          
            </div>
<div className={genderError ?'errmessage1':'errmessagehide1'}>Gender is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 mtop'>
<Input type="text"className={countyError &&'errorborder'} placeholder="County"value={county} onChange={e => setCounty(e.target.value)} name="email"  required/>
<div className={countyError ?'errmessage1':'errmessagehide1'}>County is Reguired</div>
</FormGroup>


 
  <FormGroup className='mr-2 ml-2 mtop'>
<Input  ref={ref} onBlur={(e) => (e.target.type = "text")} onFocus={(e) => (e.target.type = "date")}
 type="text"className={dobError &&'errorborder'} placeholder="Date of Birth"name="author" value={dob} onChange={e => setDob(e.target.value)} required />
<div className={dobError ?'errmessage1':'errmessagehide1'}>Dob is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 mtop'>
<Input onBlur={(e) => (e.target.type = "text")} onFocus={(e) => (e.target.type = "date")} type="text"className={adm_dateError?'errorborder datewidth':"datewidth"} placeholder="Admission Date"name="author" value={adm_date} onChange={e => setAdmdate(e.target.value)} required />
<div className={adm_dateError ?'errmessage1':'errmessagehide1'}>Adm date is Reguired</div>
</FormGroup>

<FormGroup className='mr-2 ml-2 mtop'>
<Input type="text"className={townError &&'errorborder'} placeholder="Town"value={town} onChange={e => setTown(e.target.value)} name="email"  required/>
<div className={townError ?'errmessage1':'errmessagehide1'}>Town is Reguired</div>
</FormGroup>

  </div>
  <div className='row mtop'>
  <div className='col-lg-7 ml-3 ' >
  <FormGroup className=' mt-2 mr-2'>
<Input width="300px"type="textarea" placeholder="Any Student Health concerns?"value={health} onChange={e => setHealth(e.target.value)} name="email"  required/>
</FormGroup>

</div>
<div className='col-lg-4 '>

</div>
</div>
  <h5 className='  pt-2 pl-4'>Parent's /Guardian Details</h5>
  <div className='row pl-4'>
 
{amountvisible && <FormGroup className='mr-5 ml-2'>
<Label>Amount</Label>
<Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
</FormGroup>}
<FormGroup className='mr-2 ml-2 '>
<Input type="text"className={parent_first_nameError &&'errorborder'} placeholder="First Name"value={parent_first_name} onChange={e => setPfname(e.target.value)} name="email"  required/>
<div className={parent_first_nameError ?'errmessage1':'errmessagehide1'}>Parent First name is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 '>
<Input type="text"className={parent_last_nameError &&'errorborder'} placeholder="Last Name"name="author" value={parent_last_name} onChange={e => setPlname(e.target.value)} required />
<div className={parent_last_nameError ?'errmessage1':'errmessagehide1'}>Parent Last Name is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 '>
<div style={{width:'220px'}}className={last_nameError &&'errorborder'} >
              
          <select onChange={onOptionChangeHandler4} className={relationError ? 'errorborder newselect1':'newselect'}>
 
 <option>{edit && relation?  relation :'Select Relationship'}</option>
 {options5.map((option, index) => {
     return <option key={index} >
         {option.country}
     </option>
 })}
</select>   
            </div>
<div className={relationError ?'errmessage1':'errmessagehide1'}>Relationship is Reguired</div>
</FormGroup>

  
 
 {amountvisible && <FormGroup className='mr-5 ml-3'>
 <Label>Amount</Label>
 <Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
 </FormGroup>}
 <FormGroup className='mr-2 ml-2 mtop'>
 <Input type="number"className={phoneError &&'errorborder'} placeholder="Phone Number"value={phone} onChange={e => setPhone(e.target.value)} name="email"  required/>
 <div className={phoneError ?'errmessage1':'errmessagehide1'}>Phone is Reguired</div>
 </FormGroup>
 <FormGroup className='mr-2 ml-2 mtop'>
 <Input type="text"className={emailError &&'errorborder'} placeholder="Email"name="author" value={email} onChange={e => setEmail(e.target.value)} required />
 <div className={emailError ?'errmessage1':'errmessagehide1'}>Email is Reguired</div>
 </FormGroup>


   </div>  
      <div className='row pl-4'>
  <Button color="primary"className="mt-2 mb-3 ml-2" type="submit"onClick={(e)=>validateFields(e)}>
      {editbtn && "Update"}
      {savebtn && "Submit"}
      {wait && " wait.."}
    </Button>  
    
    </div>
    </Form>
       
</div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Addstudent))