import React, {useState,useEffect,useRef} from 'react'
import './Dashboard.css';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import { FaAccessibleIcon} from 'react-icons/fa';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CChart } from '@coreui/react-chartjs'
import { faHome,faGraduationCap,faUserFriends,faBuilding } from '@fortawesome/free-solid-svg-icons'
function Dashboard({isAuthenticated,checkAuthenticated,useremail,payment,fetchPayment,isstaff,isparent,isadmin}) {
  const [totalInvoice, setTotalInvoice] = useState([]);
  const [loading,setLoading]=useState(false);
  const [amountrecord,setArecord]=useState('');
  const [totalProducts, setTotalProducts] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState([]);
  const [totalsales, setTotalSales] = useState([]);
  const [searchInvoice, setSearchInvoice] = useState([]);
  const [unpaidInvoices, setUnpaidInvoices] = useState([]);
  const [sales, setSales] = useState();
  const [amount, setAmount] = useState();
  const [gender, setGender]= useState([])
  const [stNum, setStNum]= useState([])
  const [staffGender, setStaffGender]= useState([])
  const [detailsbkp,setDetailsbkp]=useState('');
  const [data1,setData1]=useState([""]);
  const [data3,setData3]=useState([""]);
  const [classes, setClasses]= useState([])
  const data2 = {
    labels: ['Mon','Tue','Wed','Thurs','Fri'],
    datasets: [
        {
            label: 'Attendance for Week 1',
            data: [25,24,25,25,3],
            borderColor: ['rgba(255,206,86,0.2)'],
            backgroundColor: ['rgba(232,99,132,1)',
            'rgba(232,211,6,1)',
            'rgba(54,162,235,1)',
            'rgba(255,159,64,1)',
            'rgba(153,102,255,1)' ],
            pointBackgroundColor: 'rgba(255,206,86,0.2)',
        }

    ]
}


const data = {
  options: {
    legend: {
      display: '',
    },
  },
  labels: classes,
  datasets: [
    {
      backgroundColor: ['#72A0C1','#1560bd','#008E97','#1034A6','#003399','#073980', '#7CB9E8','#6CB4EE','#6CB4EE','#6699CC','#318CE7','#0a2351','#00BFFF','#034694'],
      data: stNum,
      
      
    }


// Sample data
  ]}

  

  useEffect(()=>{
  if(isAuthenticated=="false"){
    
   window.location.href = "/login";
  }
  if(isparent=="true"|isstaff=="true"){
    
    window.location.href = "/viewstudents";
   }
  },[]) 
  useEffect(()=>{
    if(useremail){
      
    const invoicenumber="99999"
    checkAuthenticated();
    fetchPayment(useremail)
    fetchAllSales()
    //fetch total invoices
    axios({
      method:'post',
      url:'/totalstudents/',
      data:{useremail:useremail},
    })
    .then((Response)=>{
       //filter record
     const datareceived=Response.data
     setTotalInvoice(datareceived)
     console.log(datareceived)
    
      })
axios({
  method:"post",
  url:"/getstudents/",
  data:{useremail:useremail}
})
.then((result)=>{
  console.log(result.data)
  setGender(result.data)
  if(result.data.length=="1"){
    setGender(result.data,result.data)
  }
}

)

axios({
  method:"post",
  url:"/getstaffs/",
  data:{useremail:useremail}
})
.then((result)=>{
  console.log(result.data)
  setStaffGender(result.data)
}

)

axios({
  method:"post",
  url:"/getstudentclasses/",
  data:{useremail:useremail}
})
.then((result)=>{
  console.log(result.data)
  const result1 = result.data.map(option => option.class_name);
  const result2 = result.data.map(option => option.count);
setStNum(result2)
setClasses(result1)
console.log(result1);
  //setStaffGender(result.data)
}

)

      //fetch unpaid invoices
    axios({
      method:'post',
      url:'/totalstaffs/',
      data:{useremail:useremail},
    })
    .then((Response)=>{
       //filter record
     const datareceived=Response.data
     setUnpaidInvoices(datareceived)
     console.log(datareceived)
    
      })
     
     
       //fetch total products
    axios({
      method:'post',
      url:'/totalclass/',
      data:{useremail:useremail},
    })
    .then((Response)=>{
       //filter record
     const datareceived=Response.data
     setTotalProducts(datareceived)
     console.log(datareceived)
    
      })

       //fetch total customers
    axios({
      method:'post',
      url:'/totaldorms/',
      data:{useremail:useremail},
    })
    .then((Response)=>{
       //filter record
     const datareceived=Response.data
     setTotalCustomers(datareceived)
     console.log(datareceived)
    
      })
       //fetch total sales
    axios({
      method:'post',
      url:'/totalclass/',
      data:{useremail:useremail},
    })
    .then((Response)=>{
       //set record
     const datareceived=Response.data
     setTotalSales(datareceived?datareceived:0)
     console.log(datareceived)
    
      
      })
    }
      },[useremail]) 
      useEffect(()=>{

 if(totalsales.length!=0){
 //calculate total sales

 const res=totalsales.reduce((prev,item)=>{
        
  return prev +(item.price*item.quantity);
},0)
setSales(res?res:0)
 }
 
      },[totalsales]) 
      const getTotalBalances1=(inv)=>{

      }
      useEffect(()=>{
checkAuthenticated()

      },[isAuthenticated])
  
      useEffect(()=>{
    
        //fetch total payment
        
       fetchAllPayment()
       
         },[]) 
         useEffect(()=>{
        
            //fetch total payment
        
          fetchAmount()
           
             },[data1]) 
         const fetchAllPayment=async()=>{
          
          let data4 ;
    
         const fee= await axios({
           method:'post',
           url:'/totalfeepayment/',
           data:{useremail:useremail},
         })
         .then(res => {
             data4 = res.data;
             setData1(data4);
             const total=(data4.reduce((total,currentItem) =>  total = total + currentItem.amount , 0 ));
             console.log(total) 
             setDetailsbkp(total);
             setLoading(false)
         })
         .catch(err => {})
       
    }
    const fetchAllSales=()=>{
      axios({
        method:'post',
        url:'/totalsales/',
        data:{useremail:useremail},
      })
      .then((Response)=>{
         //set record
       const datareceived=Response.data
       setData3(datareceived)
      
        })}
        const getTotalInvoice=(inv)=>{
         
          const res=data3.reduce((prev,item)=>{
                 
            return prev +(item.price*item.quantity);
          },0)
          return res;
        } 
        const fetchAmount=async()=>{
      
            let copy1=[];
     //fetch amount
     if(data1.length>0){
        for (var i = 0; i < data1.length; i++) {
            
            let data4 ;
            
            const result = await axios({
             method:'post',
             url:'/amountsearch/',
             data:{useremail:useremail,fee:data1[i].feetype},
           })
           .then(res => {
               data4 = res.data;
              // setArecord(...amountrecord,data4);
              
               //setDetailsbkp(data4);
               setLoading(false)
               copy1.push({amount:data4[0].amount})
               
           })
           .catch(err => {})
        
        }
        const sum =  (copy1.reduce((total,currentItem) =>  total = total + currentItem.amount , 0 ));
               console.log(sum) 
        setArecord(sum);
        }
        } 

 if(payment=="false"){
  
  /*return <Redirect to='/payment'/>*/}
 
 
  return (
    <div>
{isadmin=="true" &&<div className='bodysection3 col-lg-10 '>
<h4 className='mt-3'>Dashboard</h4>
<div className='row no-gutters '>
<div className='col orderscol d-flex align-items-center justify-content-center p-3 mobilecenter'>
<div className='ordersrow row  '>
  <div className='col-lg-2 d-flex align-items-center justify-content-center mr-5 mobile '>
  
  <FontAwesomeIcon icon={faGraduationCap} className="mobile stbg p-3"/>
             
  </div>
  <div className='ordersnumber col mobilediv'><h3>{totalInvoice.length}</h3>
  <div className='ordertext'>Students</div>
  </div>
  </div>
 </div>
 <div className='col orderscol d-flex align-items-center justify-content-center p-3 mobilecenter'>
<div className='ordersrow row'>
  <div className='col-lg-2 d-flex align-items-center justify-content-center mr-5'>
  
  <FontAwesomeIcon icon={faUserFriends} className="mobile stbg1 p-3"/>
  </div>
  <div className='ordersnumber col'><h3>{unpaidInvoices.length}</h3>
  <div className='ordertext'> Staffs</div>
  </div>
  </div>
 </div>
 <div className='col orderscol d-flex align-items-center justify-content-center p-3 mobilecenter'>
<div className='ordersrow row'>
  <div className='col-lg-2 d-flex align-items-center justify-content-center mr-5'>
  
  <FontAwesomeIcon icon={faHome}className="mobile stbg2 p-3" />
  </div>
  <div className='ordersnumber col'><h3>{totalProducts? totalProducts.length:0}</h3>
  <div className='ordertext'>Classes</div>
  </div>
  </div>
 </div>
 <div className='col orderscol d-flex align-items-center justify-content-center p-3 mobilecenter'>
<div className='ordersrow row'>
  <div className='col-lg-2 d-flex align-items-center justify-content-center mr-5'>
 

  <FontAwesomeIcon icon={faBuilding}className="mobile stbg3 p-3" />
  </div>
  <div className='ordersnumber col'><h3>{totalCustomers.length}</h3>
  <div className='ordertext'> Dorms</div>
  </div>
  </div>
 </div>
 
</div>
<div class="card-deck mt-5 d-flex align-items-center justify-content-center ">
  <div class="card ">
    <div class="card-body ">
    <h4 class="card-title">Students per gender</h4>
    <CChart
   
    width="200"
    height="100"
  type="doughnut"
  data={{
    labels: ['Boys', 'Girls'],
    datasets: [
      {
        backgroundColor: ['#85C1E9', '#AED6F1'],
        data: gender,
      },
    ],
  }}
  
/>
    </div>
  </div>
  <div class="card ">
    <div class="card-body text-center">
    <h4 class="card-title">Staff per gender</h4>
    <CChart
   
    width="200"
    height="100"
  type="doughnut"
  data={{
    labels: ['Male','Female'],
    datasets: [
      {
        backgroundColor: ['#85C1E9', '#AED6F1'],
        data: staffGender,
      },
    ],
  }}
  options={{
    legend: {
      display: false
  },
   
    tooltips: {
      enabled: false
    }
  }}
/>
    </div>
  </div>
  <div class="card ">
    <div class="card-body text-center">
    <h4 class="card-title">Fee paid </h4>
    <CChart
   
    width="200"
    height="100"
  type="doughnut"
  
  data={{
    labels: [ 'Paid', 'Balances'],
    datasets: [
      {
        backgroundColor: [ '#85C1E9', '#AED6F1'],
        data: [ detailsbkp, getTotalInvoice()-detailsbkp],
      },
    ],
  }}
  options={{
    legend: {
      display: '',
    },
   
    tooltips: {
      enabled: false
    },
    title: {
      display: false,}
  }}
/>
    </div>
  </div>
  <div class="card ">
    <div class="card-body text-center">
    <h4 class="card-title">Students per class</h4>
    <CChart
   
   width="200"
   height="100"
 type="doughnut"
 data={data}
 
/>
    </div>
  </div>
</div>
</div>}

    </div>
  )
}
const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  isstaff:state.auth.isstaff,
  isparent:state.auth.isparent,
  isadmin:state.auth.isadmin,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchPayment})(Dashboard)