import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
 import './Newstaff.css'
 import validator from 'validator'
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import  { encrypt , decrypt } from 'react-crypt-gsm';
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 import Select1 from 'react-select'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  
  
} from "reactstrap";
//import bcrypt from "bcrypt";
import axios from "axios";
import Select from 'react-dropdown-select'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import bcrypt from 'bcryptjs'
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation} from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addstudent.css'

const Newstaff=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,id,useremail,payment,match,username1})=> {
 
  const [name, setName]=useState();
  const [nameError,setNError]=useState(false);
  const [phone, setPhone]=useState();
  const [phoneError,setPhError]=useState(false);
  const [email, setEmail]=useState();
  const [emailError,setEError]=useState(false);
  const [username, setUsername]= useState();
  const [emailEMesaage, setEEMassage]= useState('');
  const [usernameEMessage, setUsernameEMessage]= useState('');
  const [passwordEMessage, setPasswordEMesaage]= useState('');
  const [password, setPassword]= useState('');
  const [cpassword, setCPassword]= useState('');
  const [usernameError,setUError]=useState(false);
  const [sellingprice, setSPrice]=useState();
  const [passwordError,setPError]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [edit,setEdit]=useState(false);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [notInternet,setNoInternet]=useState(false);
  const [buyingprice,setBPrice]=useState();
  const [buyingPError,setBPError]=useState(false);
  const [savebtn,setSavebtn]=useState(true)
  const [wait,setWait]=useState(false)
  const [cpasswordError,setCPError]=useState(false)
  const [serial,setSerial]=useState();
  const [validated,setValidated]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [success,setSuccess]=useState(false);
  const [result,setResult]=useState(false);
  const [result1,setResult1]=useState(false);
  const [dorms,setDorms]=useState(false);
  const [exams,setExams]=useState(false);
  const [sms,setSms]=useState(false);
  const [payroll,setPayroll]=useState(false);
  const [students,setStudents]=useState(false);
  const [staff,setStaff]=useState(false);
  const [classes,setClasses]=useState(false);
  const [finance,setFinance]=useState(false);
  const [library,setLibrary]=useState(false);
  const [users,setUsers]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [amountvisible,setAmountVisible]=useState(false);
  const [category, setCategory] = useState( [] );
  const [roles, setRoles] = useState( []);
const [categoryError,setCategoryError]=useState(false);
  const [serialError,setSerialError]=useState(false)
  const [unit,setUnit]=useState([
    { id: 1, country: "Male" },
    { id: 2, country: "Female" }, 
  ])
  const [unitError,setunitError]=useState(false)
  //const bcrypt = require('bcrypt');
  
  const showToastMessage = () => {
    toast.success('Record saved succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage1 = () => {
    toast.success('Record updated succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage2 = () => {

    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 
  const removeErrors=()=>{
    setNError(false)
    setPError(false)
    setEError(false)
    setPError(false)
    setUError(false)
    setPhError(false)
  }
  const validateFields=(e)=>{
    e.preventDefault()
    if(name && email && username && password && phone && cpassword && password==cpassword){
     
    saveData()
    }
    
    else{
      if(!name){
        setNError(true)
       
        }
       
          if(!username){
            setUError(true)
           setUsernameEMessage('Username is required')
            }
            if(!email){
              setEEMassage('Enter an email')
              setEError(true)
             
              }
              if( email && !email.trim().length==0 && !validator.isEmail(email)){
                setEEMassage('Enter a valid email')
                setEError(true)
              }
              if(!password){
                setPasswordEMesaage('Password is required')
                setPError(true)
               
                }
                if(password && cpassword && password!==cpassword){
                  setPasswordEMesaage('Enter matching passwords')
                  setPError(true)
                 
                  }
                if(!cpassword){
                  setCPError(true)
                 
                  }   
                  if(!phone){
                   setPhError(true)
                   
                    }        
    }
  }
  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const clearFields=()=>{
    setName('')
    setUsername('')
    setEmail('')
    setPhone('')
   setCPassword('')
   setPassword('')
  }
 
  useEffect(()=>{
//check if edit is true
const itemnumber = /[^/]*$/.exec(window.location.href)[0];
//alert(match.params.id1)
let pid= match.params.pid
 if(pid>=0)
 {
setResult1(true)
 setSavebtn(false)
 //alert('ok')
 }
     //fetch edit records when editing
     if(result1){
      
     //fetch Item records
     axios({
      method:'post',
      url:`/productsearch/`,
      data:{id:match.params.pid},
    })
    .then((Response)=>{
     const datareceived=Response.data;
     console.log(datareceived)
    //set fields
    
    
      })
     //set edit
     setEdit(true);
     setEditbtn(true)
    
       //set local invoice
      // setInvoiceNumber(invoicenumber)
      
     }else{
      
      
     }
//fetch category
let data5 ;
axios({
 method:'post',
 url:'/totalcategory/',
 data:{useremail:useremail},
})
.then(res => {
   data5 = res.data;
   setCategory(data5)
   //setUnit(data5)
})
.catch(err => {})


//fetch unit
let data7 ;
axios({
 method:'post',
 url:'/totalunit/',
 data:{useremail:useremail},
})
.then(res => {
   data7 = res.data;
   //setCategory(data5)
   setUnit(data7)
})
.catch(err => {})

  },[])

  useEffect(()=>{
    
if(result1){
  //alert(result1)
 //fetch student records
 axios({
  method:'post',
      url:`/productsearch/`,
      data:{id:match.params.pid},
 
})
.then(res => {
   
    //setData(res.data);
   
    //alert(res.data[0].id)
    setEditbtn(true)
})
.catch(err => {})
}

  },[result1])
  useEffect(()=>{
    //save 
if(validated){

//save staff
if(useremail=="4")
{
showToastMessage2();
}
else{
if(editbtn)
{

const editData = async () => {

const result = await axios({
method:'put',
url:`/api/item/${match.params.pid}/`,
data:{username,password:password,lastname:name,owner:useremail},
})
.then((Response)=>{
if (Response.data){
//setSaveSuccess(true)
setWait(false)
removeErrors()
showToastMessage1()
//setSuccess(true)
setValidated(false)
//hidesuccess();

     }
})
.catch((error) => {
if (error.code="ERR_BAD_RESPONSE"){

setWait(false)
setNoInternet(true)
     }

})

}
editData()
}else
{

  

saveData()
}
}
}
    
      },[validated]) 

      const saveData = async () => {
        //alert('unitValue')
        const encryptedStr = encrypt(password);
       
       const result = await axios({
       method:'post',
       url:'/searchstaff1/',
       data:{username,password:encryptedStr.content,smallpas:password,lastname:name,email,phone,schoolid:useremail,students,staff,classes,dorms,exams,finance,library,sms,payroll,users,owner:useremail},
       })
       .then((Response)=>{
         alert(Response.data)
       if (Response.data=='exists'){
        
         setUsernameEMessage('Username already Exists')
         setUError(true)
              }else{
              
               setSaveSuccess(true)
               setWait(false)
               setSavebtn(true)
               removeErrors()
               showToastMessage()
               //setSuccess(true)
               setValidated(false)
              //hidesuccess();
             clearFields()
              }
       })
       .catch((error) => {
       if (error.code="ERR_BAD_RESPONSE"){
       
        setWait(false)
        setNoInternet(true)
              }
       
       })
       
       
       
       }
const renderCategory=()=>{
return (category.map(data =>({label:data.bookid})))
  }
   if(payment=="false"){
    return <Redirect to='/payment'/>}
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record {edit?"Updated":"Saved"} succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>{edit? '/ Products / Edit Item':'/ Users / Add User'}</div>
        <div className='bodysection col-lg-5  '>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
       
<div className=' col-lg-12 theader'>< h5>User Details</h5> </div>
<Form >
  <div className='row pl-4'>
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
</FormGroup>}
<FormGroup className='mr-2 ml-2 '>
Name
<Input className={nameError &&'errorborder'} type="text" placeholder="Name"value={name} onChange={e => setName(e.target.value)} name="email"  required/>
<span className={nameError ?'errmessage':'errmessagehide'}>Name is Reguired</span>
</FormGroup>

<FormGroup className='mr-2 ml-2 '>
Email
<Input  className={emailError &&'errorborder'} type="email" placeholder="Email"name="author" value={email} onChange={e => setEmail(e.target.value)} required />
<span className={emailError ?'errmessage':'errmessagehide'}>.{emailEMesaage}</span>
</FormGroup>

  
 
 {amountvisible && <FormGroup className='mr-5 ml-3 '>
 <Label>Amount</Label>
 <Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
 </FormGroup>}
 <FormGroup className='mr-2 ml-2 '>
 Phone
 <Input className={phoneError &&'errorborder'} type="number" placeholder="Phone"value={phone} onChange={e => setPhone(e.target.value)} name="email"  required/>
 <div className={phoneError ?'errmessage':'errmessagehide'}>Phone is Reguired</div>
 </FormGroup>
 <FormGroup className='mr-2 ml-2'>
 Username
 <Input className={usernameError &&'errorborder'} type="text" placeholder=" Username"name="author" value={username} onChange={e => setUsername(e.target.value)} required />
 <div className={usernameError ?'errmessage':'errmessagehide'}>.{usernameEMessage}</div>
 </FormGroup>
 <FormGroup className='mr-2 ml-2'>
 Password
 <Input className={passwordError &&'errorborder'} type="password" placeholder="password"name="author" value={password} onChange={e => setPassword(e.target.value)} required />
 <div className={passwordError ?'errmessage':'errmessagehide'}>.{passwordEMessage}</div>
 </FormGroup>
 <FormGroup className='mr-2 ml-2'>
Confirm Password
 <Input className={cpasswordError &&'errorborder'} type="password" placeholder="Confirm Password"name="author" value={cpassword} onChange={e => setCPassword(e.target.value)} required />
 <div className={cpasswordError ?'errmessage':'errmessagehide'}>Confirm Password is Reguired</div>
 </FormGroup>
   </div>
  <div className='col-lg-6 row ml-1' ><div className='col'>
  <FormGroup className='mr-2 ml-2 pl-3'>
  <span className=''>Roles</span><br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setStudents(e.target.checked)} required />Students<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={e => setStaff(e.target.checked)} required />Staff<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={e => setClasses(e.target.checked)} required />Classes<br/>
 <Input type="checkbox" placeholder="Quantity"name="author"  onChange={e => setDorms(e.target.checked)} required />Dorms<br/>
 <Input type="checkbox" placeholder="Quantity"name="author"  onChange={e => setExams(e.target.checked)} required />Exams<br/>
 <Input type="checkbox" placeholder="Quantity"name="author"  onChange={e => setPayroll(e.target.checked)} required />Payroll<br/>
 
 </FormGroup>
 </div>
 <div className='col'>

  <span className=''></span><br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setFinance(e.target.checked)} required />Finance<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={e => setLibrary(e.target.checked)} required />Library<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={e => setSms(e.target.checked)} required />Sms<br/>
 <Input type="checkbox" placeholder="Quantity"name="author"  onChange={e => setPayroll(e.target.checked)} required />Payroll<br/>
 <Input type="checkbox" placeholder="Quantity"name="author"  onChange={e => setUsers(e.target.checked)} required />Users<br/>
 
 </div>
</div>
 
      <div className='row pl-4'>
  <Button color="primary"className=" ml-2 mb-3" type="submit"onClick={(e)=> validateFields(e)}>
      {editbtn && "Update"}
      {savebtn && !editbtn && "Submit"}
      {wait && " Please wait.."}
    </Button>  
    
    </div>
    </Form>
       
</div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Newstaff))