import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
 
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
 
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import useKeyboardShortcut from 'use-keyboard-shortcut'

import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation,useParams } from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addclass.css'

const Addbook=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,id,useremail,payment,match,username1})=> {
 
  const [showhide,setShowhide]=useState(false);
  const [customerFetch,setCustomerFetch]=useState(false);
  const [amountvisible,setAmountVisible]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [showhide1,setShowhide1]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [names, setName] = useState("");
  const [filteredinvoice, setFilteredInvoice] = useState("");
  const [name, setNames] = useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [id1,setId]=useState("");
  const [inumber,setInumber]=useState("hello");
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(true);
  const [editbtn1,setEditbtn1]=useState(false);
  const [savebtn1,setSavebtn1]=useState(false);
  const [wait,setWait]=useState(false);
  const [wait1,setWait1]=useState(false);
  const [hide,setHide]=useState(true);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [dropopen,setDropOpen]=useState(false);
  const [dropdown,setDropDown]=useState("Unpaid");
  const [issuedate,setIssueDate]=useState("");
  const [duedate,setDueDate]=useState("");
  const [product,setProduct]=useState("");
  const [price,setPrice]=useState("");
  const [subtotal,setSubTotal]=useState("");
  const [search,setSearch]=useState("");
  const [searchcustomer,setSearchCustomer]=useState("");
  const [quantity,setQuantity]=useState("");
  const [index,setIndex]=useState("");
  const [dateentered,setDateEntered]=useState("");
  const [details,setDetails]=useState(['']);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [details2bkp,setDetails2bkp]=useState(['']);
  const [details1,setDetails1]=useState(['']);
  const [details2,setDetails2]=useState(['']);
  const [details3,setDetails3]=useState(['']);
  const [products,setProducts]=useState(['']);
  const [irecords,setIRecords]=useState([]);
  const [grandtotal,setGrandTotal]=useState('');
  const [grandtotal1,setGrandTotal1]=useState('');
  const [totalPartialAmount,setTotalPartialAmount]=useState('');
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const [musyoki,setMusyoki]=useState('musyoki');
  const [inputList1,setInputList1]=useState([]);
  const [heading,setHeading]=useState(['Names', 'Mobile','Action']);
  const [inputList, setInputList] = useState([]);
  const [inputList2, setInputList2] = useState([]);
  const [payments, setPayments] = useState(['']);
  const [data2,setData2]=useState([""]);
  const inputRef = useRef(null);
  const [notFound,setNotFound]=useState(false);
  const [notInternet,setNoInternet]=useState(false);
  const [classnumeral,setCnumeral]=useState("");
  const [classnumeralError,setCnumeralError]=useState(false);
  const [classstream,setCsream]=useState("");
  const [classstreamError,setCsreamError]=useState(false);
  const [validated,setValidated]=useState(false);
  const [success,setSuccess]=useState(false);
  const [result,setResult]=useState(false);
  const [author,setAuthor]=useState("");
  const [authorError,setAuthorError]=useState(false);
  const [title,setTitle]=useState("");
  const [titleError,setTitleError]=useState(false);
  const [bookid,setBid]=useState("");
  const [bookidError,setBidError]=useState(false);
  const [subject,setSubject]=useState("");
  const showToastMessage = () => {

    toast.success('Record saved succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };
  const showToastMessage1 = () => {

    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  };

          useEffect(()=>{
            
        if(validated){
        if(useremail=="4")
        {
showToastMessage1();
        }
        else{
        //save book
        if(edit)
        {
        
        const editData = async () => {
        
        const result = await axios({
        method:'put',
        url:`/api/book/${match.params.id}/`,
        data:{classnumeral,classstream},
        })
        .then((Response)=>{
        if (Response.data){
        setSaveSuccess(true)
        setWait(false)
        
       // setSuccess(true)
        setValidated(false)
       // hidesuccess();
        
             }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
        setShowhide2(true)
        setWait(false)
        setNoInternet(true)
             }
        
        })
        
        }
        editData()
        }else
        {
        const saveData = async () => {
        
        const result = await axios({
        method:'post',
        url:'/api/book/',
        data:{title:title.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),author:author.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),bookid:bookid.toUpperCase(),subject:subject.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),status:'Available',owner:useremail,postedby:username1},
        })
        .then((Response)=>{
        if (Response.data){
         setSaveSuccess(true)
         setWait(false)
         setSavebtn(true)
        // setSuccess(true)
         setValidated(false)
        // hidesuccess();
        removeErrors()
        showToastMessage()
        clearFields()
               }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
         setShowhide2(true)
         setWait(false)
         setNoInternet(true)
               }
        
        })
        
        }
        saveData()
        }
      }
        }
            
              },[validated]) 
const removeErrors=()=>{
  setTitleError(false)
  setAuthorError(false)
  setBidError(false)
}
  const validateFields=(e)=>{
    e.preventDefault()
    if(title && author && bookid ){
      setValidated(true)}
    
    else{
      if(title.trim().length==0){
        setTitleError(true)
       
        }
        if(author.trim().length==0){
          setAuthorError(true)
         
          }
          if(bookid.trim().length==0){
            setBidError(true)
           
            }
    }}

    function hidesuccess() {
      return new Promise(resolve => {
        setTimeout(() => {
         // setSuccess(false);
        }, 5000);
      });
    }
    const clearFields=()=>{
setTitle('')
setAuthor('')
setSubject('')
setBid('')
    }
   if(payment=="false"){
    return <Redirect to='/payment'/>}
  return (
    <div>
      <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>/ Library / Add Book</div>
        <div className='bodysection col-lg-5 '>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
       
<div className=' col-lg-12 theader'>< h5>Add Book</h5> </div>
<Form >
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input  type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />

</FormGroup>}
<FormGroup className='mr-2 ml-2'>
<Label>Title</Label>
<Input className={titleError &&'errorborder'} type="text" placeholder="Title"value={title} onChange={e => setTitle(e.target.value)} name="email"  required/>
<div className={titleError ?'errmessage':'errmessagehide'}>Title is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>Author</Label>
<Input className={authorError &&'errorborder'} type="text" placeholder="Author"value={author} onChange={e => setAuthor(e.target.value)} name="email"  required/>
<div className={authorError ?'errmessage':'errmessagehide'}>Author is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>Book ID.</Label>
<Input className={bookidError &&'errorborder'} type="text" placeholder="Bood id/ serial"value={bookid} onChange={e => setBid(e.target.value)} name="email"  required/>
<div className={bookidError ?'errmessage':'errmessagehide'}>Book Id. is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>Subject</Label>
<Input  type="text" placeholder="Subject"value={subject} onChange={e => setSubject(e.target.value)} name="email"  required/>

</FormGroup>
 
      <div className='row ml-1 label2'>
  <Button color="primary"className=" mb-3 ml-2 mt-4" type="submit"onClick={(e)=> validateFields(e)}>
      {editbtn && "Update"}
      {savebtn && "Submit"}
      {wait && " Please wait.."}
    </Button>  

    </div>
    </Form>
       
</div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Addbook))