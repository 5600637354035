import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
 
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";

// Then import the virtualized Select HOC
import VirtualizedSelect from 'react-virtualized-select'
import { RotatingLines } from 'react-loader-spinner'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Alert,
} from "reactstrap";
import axios from "axios";
import useKeyboardShortcut from 'use-keyboard-shortcut'
import { useSelect } from 'react-select-search';
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation,useParams } from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Recordresult.css'
import SelectSearch from 'react-select-search';
import Select from 'react-dropdown-select'
const Recordresult=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,id,useremail,payment,username1})=> {
 
  const [showhide,setShowhide]=useState(false);
  const [customerFetch,setCustomerFetch]=useState(false);
  const [amountvisible,setAmountVisible]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [showhide1,setShowhide1]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [names, setName] = useState("");
  const [filteredinvoice, setFilteredInvoice] = useState("");
  const [name, setNames] = useState("");
  const [studentadm, setStudentadm] = useState("");
  const [studentadmError, setStudentadmError] = useState(false);
  const [first_name, setFname] = useState("");
  const [first_nameError, setFnameError] = useState(false);
  const [last_name, setLname] = useState("");
  const [last_nameError, setLnameError] = useState(false);
  const [dob, setDob] = useState("");
  const [dobError, setDobError] = useState(false);
  const [class_name, setCname] = useState("");
  const [class_nameError, setCnameError] = useState(false);
  const [dormitory, setDormitory] = useState("");
  const [dormitoryError, setDormitoryError] = useState(false);
  const [religion, setReligion] = useState("");
  const [religionError, setReligionError] = useState(false);
  const [adm_date, setAdmdate] = useState("");
  const [adm_dateError, setAdmdateError] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState(false);
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState(false);
  const [county, setCounty] = useState("");
  const [countyError, setCountyError] = useState(false);
  const [health, setHealth] = useState("");
  const [healthError, setHealthError] = useState(false);
  const [parent_first_name, setPfname] = useState("");
  const [parent_first_nameError, setPfnameError] = useState(false);
  const [parent_last_name, setPlname] = useState("");
  const [parent_last_nameError, setPlnameError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [email,setEmail]=useState("");
  const [emailError,setEmailError]=useState(false);
  const [mobile,setMobile]=useState("");
  const [mobileError,setMobileError]=useState(false);
  const [town,setTown]=useState("");
  const [townError,setTownError]=useState(false);
  const [id1,setId]=useState("");
  const [inumber,setInumber]=useState("hello");
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(false);
  const [wait,setWait]=useState(false);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [dropopen,setDropOpen]=useState(false);
  const [dropdown,setDropDown]=useState("Unpaid");
  const [issuedate,setIssueDate]=useState("");
  const [duedate,setDueDate]=useState("");
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const [musyoki,setMusyoki]=useState('musyoki');
  const [inputList1,setInputList1]=useState([]);
  const [heading,setHeading]=useState(['Names', 'Mobile','Action']);
  const [inputList, setInputList] = useState([]);
  const [notInternet,setNoInternet]=useState(false);
  const [success,setSuccess]=useState(false);
  const [loading,setLoading]=useState(false);
  const [validated,setValidated]=useState(false);
  const [searchClicked,setSearchClicked]=useState(false);
  const [data, setData] = useState([]);
  const [examdata, setExamData] = useState([]);
  const [marks, setMarks] = useState("");
  const [times, setTimes] = useState("");
  const [inputFields, setInputFields] = useState([
    
])
const [dataList, setDataList] = useState([])
const [exam, setExam] = useState([])
const [stream, setStream] = useState([])
  const [options, setOptions] = useState( [
    { id: 1, country: "1 Blue" },
    { id: 2, country: "2 Blue" }, 
    { id: 3, country: "3 Blue" },
    { id: 4, country: "4 Blue" },
    { id: 5, country: "5 Blue" },
] )
const [inputStyle, setInputStyle] = useState( { })
const [inputStyle1, setInputStyle1] = useState( { })
const [inputStyle2, setInputStyle2] = useState( { })
const [inputStyle3, setInputStyle3] = useState( { })
const [inputStyle4, setInputStyle4] = useState( { })

const [options1, setOptions1] = useState( [
  { id: 1, country: "PP1" },
  { id: 1, country: "PP2" },
  { id: 1, country: "Grade 1" },
  { id: 2, country: "Grade 2" }, 
  { id: 3, country: "Grade 3" },
  { id: 4, country: "Grade 4" },
  { id: 5, country: "Grade 5" },
  { id: 6, country: "Grade 6" },
] )

const [options2, setOptions2] = useState( [
  { id: 1, country: "Blue" },
  { id: 2, country: "Yellow" }, 
  { id: 3, country: "Green" },
  { id: 4, country: "Red" },
] )

const [options3, setOptions3] = useState( [
  { id: 1, subject: "language" },
  { id: 2, subject: "Psychomotor_Creative" },
  { id: 3, subject: "braille" },
  { id: 4, subject: "english" },
  { id: 5, subject: "kiswahili" }, 
  { id: 6, subject: "mathematics" }, 
  { id: 7, subject: "music" }, 
  { id: 8, subject: "musicandmovement" }, 
  { id: 9, subject: "environmental" }, 
  { id: 10, subject: "hygiene" }, 
  { id: 11, subject: "literacy" }, 
  { id: 12, subject: "creative" }, 
  { id: 13, subject: "cre" }, 
  { id: 14, subject: "homescience" }, 
  { id: 15, subject: "social" }, 
  { id: 16, subject: "physicalandhealth" }, 
  { id: 17, subject: "scienceandt" }, 
] )

const [options4, setOptions4] = useState( [
  { id: 1, country: "Male" },
  { id: 2, country: "Female" }, 
  
] )
const [selectedOptions, setSelectedOptions] = useState([])
const [selectedOptions1, setSelectedOptions1] = useState([])
const [selectedOptions2, setSelectedOptions2] = useState([])
const [selectedOptions3, setSelectedOptions3] = useState([])
const [selectedOptions4, setSelectedOptions4] = useState([])
const [adminMenu, setadminMenu] = useState(false);
const ref = useRef();
  
  toast.configure()
  const showToast = () => toast("Operation succesful",{position: toast.POSITION.TOP_CENTER});
  const adminMenuClick = () => {
    setadminMenu(!adminMenu);
   
  }
    
  const showToastMessage = () => {
    toast.success('Record saved succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
  const showToastMessage2 = () => {

    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 

  const showToastMessage3 = () => {

    toast.error('No student records found for selected class', {
        position: toast.POSITION.BOTTOM_CENTER
    });
  }; 
  useEffect(()=>{
 
    //check authentication
    checkAuthenticated();
    fetchPayment(useremail)
    
    
//check if edit is true
const invoicenumber = /[^/]*$/.exec(window.location.href)[0];
const result = invoicenumber.includes("-") && invoicenumber.length<40 ? true : false;

     //fetch edit records when editing
     if(result){
     //fetch student records
     axios({
      method:'post',
      url:'/studentsearch/',
      data:{adm:id},
    })
    .then((Response)=>{
     const datareceived=Response.data;
     console.log(datareceived)
    //set fields
    setStudentadm(datareceived[0].studentadm)
    setEmail(datareceived[0].email)
    setFname(datareceived[0].first_name)
    setLname(datareceived[0].last_name)
    setDob(datareceived[0].dob)
    setSelectedOptions([{'label':datareceived[0].class_name}])
    setSelectedOptions1([{'label':datareceived[0].dormitory}])
    setSelectedOptions2([{'label':datareceived[0].religion}])
    setSelectedOptions3([{'label':datareceived[0].category}])
    setSelectedOptions4([{'label':datareceived[0].gender}])
    setAdmdate(datareceived[0].adm_date)
    setCounty(datareceived[0].county)
    setHealth(datareceived[0].health)
    setPfname(datareceived[0].parent_first_name)
    setPlname(datareceived[0].parent_last_name)
    setPhone(datareceived[0].phone)
    setTown(datareceived[0].town)
    setId2(datareceived[0].id)
      })
     //set edit
     setEdit(true);
     setEditbtn(true)
    
       //set local invoice
       setInvoiceNumber(invoicenumber)
      
     }else{
      setSavebtn(true)
      
     }
     //fetch stream
     let data4 ;
      axios({
       method:'post',
       url:'/totalclass/',
       data:{useremail:useremail},
     })
     .then(res => {
         data4 = res.data;
         setStream(data4);
         
     })
     .catch(err => {})

     //fetch exams
     let data5 ;
      axios({
       method:'post',
       url:'/totalexam/',
       data:{useremail:useremail},
     })
     .then(res => {
         data5 = res.data;
         setExam(data5);
         console.log(data5)
     })
     .catch(err => {})
      },[])
    
      function hidesuccess() {
        return new Promise(resolve => {
          setTimeout(() => {
            setSuccess(false);
          }, 5000);
        });
      }
      
  
  const clearFields=()=>{
    setStudentadm('')
    setFname('')
    setLname('')
    setDob('')
    setSelectedOptions([])
    setSelectedOptions1([])
    setSelectedOptions2([])
    setSelectedOptions3([])
    setSelectedOptions4([])
    setCounty('')
    setTown('')
    setAdmdate('')
    setHealth('')
    setPfname('')
    setPlname('')
    setEmail('')
    setPhone('')
    
  }
 

    useEffect(()=>{
      checkAuthenticated();
      
        },[]) 
        useEffect(()=>{
          if(inputFields.length>0){
            window.location.reload();
          }
          
            },[selectedOptions3,selectedOptions2,selectedOptions1,selectedOptions]) 
      //fetch students
        useEffect(()=>{
         
         //fetch students

         if(selectedOptions.length>0 && selectedOptions1.length>0 && selectedOptions2.length>0 && selectedOptions3.length>0){
         
          setLoading(true)
          let data4 ;
const result = axios({
       method:'post',
       url:'/fetchstudent/',
       data:{useremail:useremail,classnumeral:selectedOptions1[0].label.split(" ")[1],classstream:selectedOptions2[0].label},
     })
     .then(res => {
         data4 = res.data;
         setData(data4);
    if(data4.length<1){
showToastMessage3()
    }
     })
     .catch(err => {}) 
          
 } },[selectedOptions3,selectedOptions2.selectedOptions1,selectedOptions]) 
        useEffect(()=>{
          
         
           //check if record exists in exam
           const fetchData=async(e)=>{
       const check= setInputFields([])
     
           let data4
           let copy1=[];
           let copy2=[];
           for (var i = 0; i < data.length; i++) {
            const result = await axios({
              method:'post',
              url:'/fetchexamrecord1/',
              data:{useremail:useremail,examid:selectedOptions[0].label,studentadm: data[i].studentadm,subject: selectedOptions3[0].label},
            })
            .then(res => {
                data4 = res.data;
                
                if (data4){
                   copy1.push({examid: selectedOptions[0].label, subject: selectedOptions3[0].label, studentadm: data[i].studentadm,first_name: data[i].first_name,last_name: data[i].last_name,classname:selectedOptions1[0].label+' '+selectedOptions2[0].label, marks:data4}) 
                   
                  }else
                 {
                   copy2.push({examid: selectedOptions[0].label, subject: selectedOptions3[0].label, studentadm: data[i].studentadm,first_name: data[i].first_name,last_name: data[i].last_name,classname:selectedOptions1[0].label+' '+selectedOptions2[0].label, marks: ''}) 
                  // setInputFields(...inputFields,copy2)
                   console.log(inputFields)
                 }
              
            })
            .catch(err => {}) 
           
           }
           if(copy2.length>0)
           {
           setInputFields(...inputFields,copy2)
           }
           if(copy1.length>0){
           setInputFields(...inputFields,copy1)
           }
           //setInputFields([...inputFields,copy1])
           
          }
          
           fetchData()
           setLoading(false)
         
          console.log(inputFields)
            },[data]) 

       const fetchData=async(e)=>{
        e.preventDefault()
//fetch students
let data4 ;
const result = await axios({
       method:'post',
       url:'/fetchstudent/',
       data:{useremail:useremail,classnumeral:selectedOptions1[0].label,classstream:selectedOptions2[0].label},
     })
     .then(res => {
         data4 = res.data;
         setData(data4);
         let newfield
           //set form field
           for (var i = 0; i < data.length; i++) {
           newfield = {examid: '', subject: '', studentadm: data[0].studentadm, marks: ''}
           setInputFields([...inputFields, newfield])
           setTimes(...times,"another")
           setDataList(dataList => {
           
            let newfield = {examid: '', subject: '', studentadm: data[0].studentadm,first_name: data[0].first_name,last_name: data[0].last_name, marks: ''};
            return newfield;
            })
           }
           console.log(inputList)
     })
     .catch(err => {})}

  const handleFormChange = (index, event) => {
   let data = [...inputFields];
   data[index][event.target.name] = event.target.value;
   setInputFields(data);
   
}      
    

        useEffect(()=>{
          //save customer
if(validated){

//save student
if(edit)
{
const fetchStudents=()=>{
    
}
const editData = async () => {
 
  const result = await axios({
    method:'put',
    url:`/api/student/${id2}/`,
    data:{first_name,last_name,dob,class_name:selectedOptions[0].label,dormitory:selectedOptions1[0].label,religion:selectedOptions2[0].label,
     adm_date,category:selectedOptions3[0].label,gender:selectedOptions4[0].label,county,town,health,parent_first_name,parent_last_name,phone,email,owner:useremail},
  })
  .then((Response)=>{
   if (Response.data){
     setSaveSuccess(true)
     setWait(false)

     setSuccess(true)
     setValidated(false)
     hidesuccess();
  
           }
  })
  .catch((error) => {
   if (error.code="ERR_BAD_RESPONSE"){
     setShowhide2(true)
     setWait(false)
     setNoInternet(true)
           }
   
  })
  
  }
  editData()
}else
{
  const saveData = async () => {
 
    const result = await axios({
      method:'post',
      url:'/api/student/',
      data:{studentadm,first_name,last_name,dob,class_name:selectedOptions[0].label,dormitory:selectedOptions1[0].label,religion:selectedOptions2[0].label,
       adm_date,category:selectedOptions3[0].label,gender:selectedOptions4[0].label,county,town,health,parent_first_name,parent_last_name,phone,email,owner:useremail},
    })
    .then((Response)=>{
     if (Response.data){
       setSaveSuccess(true)
       setWait(false)
       setSavebtn(true)
       setSuccess(true)
       setValidated(false)
       hidesuccess();
    clearFields()
             }
    })
    .catch((error) => {
     if (error.code="ERR_BAD_RESPONSE"){
       setShowhide2(true)
       setWait(false)
       setNoInternet(true)
             }
     
    })
    
    }
    saveData()
}

}
          
            },[validated]) 

const handleSubmit=(e)=>{
  if(useremail=="4")
{
showToastMessage2();
}
else{
  //save to exam table
e.preventDefault()

  let cartLen = inputFields.length;
  
     for (let i = 0; i < cartLen; i++) {
      
        const examid= inputFields[i].examid;
        const adm=inputFields[i].studentadm;
        const classname=inputFields[i].classname;
        const subject=inputFields[i].subject;
        const marks=inputFields[i].marks;
  axios({
    method:'post',
    url:'/saveexam1/',
    data:{examid,adm,classname,subject,marks,owner:useremail,username:username1},
    })
    .then((Response)=>{
    if (Response.data){
      if(i==cartLen-1){
        showToastMessage()
      }
    
      console.log(Response.data)
     setSaveSuccess(true)
     setWait(false)
     setSavebtn(true)
     
     //setSuccess(true)
     setValidated(false)
    // hidesuccess();
    clearFields()
           }
    })
    .catch((error) => {
    if (error.code="ERR_BAD_RESPONSE"){
     setShowhide2(true)
     setWait(false)
     setNoInternet(true)
           }
    
    })}
   
  }
}

const setSearchClickedTrue=()=>{
    setSearchClicked(true)
}
  if(isAuthenticated=="false"){
  /* return <Redirect to='/login'/>*/}
  
   if(payment=="false"){
    return <Redirect to='/payment'/>}
    const validateFields=(e)=>{
      
   e.preventDefault()
      if(studentadm && first_name && last_name && dob && selectedOptions && selectedOptions1 && selectedOptions2 && adm_date
        && selectedOptions3 && selectedOptions4 && county && town && parent_first_name && parent_last_name
        && phone && email  && !email.trim().length==0 && !studentadm.trim().length==0 && !first_name.trim().length==0 
        && !last_name.trim().length==0 && !dob.trim().length==0 && !selectedOptions.length==0 && 
        !selectedOptions1.length==0 && !selectedOptions2.length==0 && !adm_date.trim().length==0 &&
         !selectedOptions3.length==0 && !selectedOptions4.length==0 && !county.trim().length==0 &&
          !town.trim().length==0 && !parent_first_name.trim().length==0 && !
          parent_last_name.trim().length==0 && !phone.trim().length==0 && !email.trim().length==0){
                 
        setValidated(true)}
        else{
          if(studentadm.trim().length==0){
            setStudentadmError(true)
           
            }
      if(first_name.trim().length==0){
      setFnameError(true)
     
      }
      if(last_name.trim().length==0){
        setLnameError(true)
        
        }
        
        if(dob.trim().length==0){
          setDobError(true)
         
          }
          if(selectedOptions.length==0){
            setCnameError(true)
      
            setInputStyle({
              'border-color':'red'
            })}
            if(selectedOptions1.length==0){
              
            setDormitoryError(true)
              setInputStyle1({
                'border-color':'red'
              })}
              if(selectedOptions2.length==0){
                setReligionError(true)
          
                setInputStyle2({
                  'border-color':'red'
                })}
                if(selectedOptions3.length==0){
                  setCategoryError(true)
                  
                  setInputStyle3({
                    'border-color':'red'
                  })}
                  if(selectedOptions4.length==0){
                    setGenderError(true)
                    
                    setInputStyle4({
                      'border-color':'red'
                    })}
    
              if(adm_date.trim().length==0){
              setAdmdateError(true)
               } 
          
                 if(county.trim().length==0){
                  setCountyError(true)
                
                  } 
                if(town.trim().length==0){
                 
                 setTownError(true)
                } 
                         
               if(parent_first_name.trim().length==0){
                 setPfnameError(true)
           
                       } 
             if(parent_last_name.trim().length==0){
           
             setPlnameError(true)
                  } 
               if(phone.trim().length==0){
                  setPhoneError(true)
                 
                     } 
               if(email.trim().length==0){
               
              setEmailError(true)
                    } 
          }
    }
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
        
      };
     
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
      <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record {edit?"Updated":"Saved"} succesffully</div></div>
      <div className='path'><i className='fa fa-home'></i>/ Exams / Record Marks</div>
        <div className='bodysection2 col-lg-6 '>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
        {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
<div className=' col-lg-12 theader'>< h5>Record Marks</h5> </div>
<Form >
 
  <div className='row ml-4'>
 
{amountvisible && <FormGroup className='mr-5 ml-2'>
<Label>Amount</Label>
<Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
</FormGroup>}

<FormGroup className='mr-2 ml-2'>
<div  style={{width:'250px',"border-color":"red",'font-family': "Times New Roman"}}className={last_nameError &&'errorborder'} >
                <Select onClick={adminMenuClick}  style={inputStyle} className={last_nameError &&'errorborder'}placeholder="Select Exam" options={ exam.map( ( item, index ) => { 
                return { value: item.id, label: item.examname }
                } ) } values={selectedOptions} onChange={ ( values ) => setSelectedOptions([...values]) } />
            </div>

<div className={class_nameError ?'errmessage':'errmessagehide'}>Class name is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<div style={{width:'250px'}}className={last_nameError &&'errorborder'} >
                <Select style={inputStyle1} className={last_nameError &&'errorborder'}placeholder="Select Class" options={ options1.map( ( item, index ) => { 
                return { value: item.id, label: item.country }
                } ) } values={selectedOptions1} onChange={ ( values ) => setSelectedOptions1([...values]) } />
            </div>

<div className={dormitoryError ?'errmessage':'errmessagehide'}>Dormitory name is Reguired</div>
</FormGroup>

</div >

<div className='row  ml-4'>
 
{amountvisible && <FormGroup className='mr-5 ml-2'>
<Label>Amount</Label>
<Input type="text"style={{width:"250px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
</FormGroup>}

<FormGroup className='mr-2 ml-2'>
<div  style={{width:'250px',"border-color":"red",'font-family': "Times New Roman"}}className={last_nameError &&'errorborder'} >
                <Select onClick={adminMenuClick}  style={inputStyle} className={last_nameError &&'errorborder'}placeholder="Select Stream" options={ stream.map( ( item, index ) => { 
                return { value: item.id, label: item.classstream }
                } ) } values={selectedOptions2} onChange={ ( values ) => setSelectedOptions2([...values]) } />
            </div>

<div className={class_nameError ?'errmessage':'errmessagehide'}>Class name is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<div style={{width:'250px'}}className={last_nameError &&'errorborder'} >
                <Select style={inputStyle1} className={last_nameError &&'errorborder'}placeholder="Select Subject" options={ options3.map( ( item, index ) => { 
                return { value: item.id, label: item.subject }
                } ) } values={selectedOptions3} onChange={ ( values ) => setSelectedOptions3([...values]) } />
            </div>

<div className={dormitoryError ?'errmessage':'errmessagehide'}>Dormitory name is Reguired</div>
</FormGroup>


</div >
{loading &&
<div className='spinnerdiv'>
<RotatingLines
  strokeColor="grey"
  strokeWidth="5"
  animationDuration="1"
  width="40"
  visible={true}
/>
</div>}

{inputFields.length>0 && inputFields.map((x, i) => {
        return (
          <div className="box row mb-1 ml-4 mr-2 ">
             <div className='col'>
            
            <Input
             type='hidden'
              name="name1"
   placeholder="Product Name"
              value={x.studentadm}
              onChange={e => handleInputChange(e, i)}
              required
              className='whitelabel'
            />
             <Label>
              {x.studentadm}
            </Label>
            </div>
            <div className='col'>
            
            <Input
            type='hidden'
              name="name1"
   placeholder="Product Name"
              value={x.first_name}
              onChange={e => handleInputChange(e, i)}
              required
              className='whitelabel'
            />
              <Label>
              {x.first_name}
            </Label>
            </div>
            <div className='col'>
              
          
            <Input
           type='hidden'
              className="ml10 whitelabel"
              name="quantity1"
   placeholder="Enter Quantity"
              value={x.last_name}
              onChange={e =>{ handleInputChange(e, i)}}
            />
             <Label>
              {x.last_name}
            </Label>
            </div>
             <div className='col'>
              
            <Input
            type='number'
              className="ml10"
              name="marks"
   placeholder="Marks"
              value={x.marks}
              onChange={e => handleFormChange(i, e)}
            /></div>
            
           
          </div>
        );
      })}
      
      <div className='row ml-4 mb-2'>
  {data.length>0 &&<Button color="primary"className="mt-2 mb-3 ml-2" type="submit" onClick={(e)=> handleSubmit(e)}>
      {editbtn && "Update"}
      { "Submit"}
      {wait && " wait.."}
    </Button>  }
    
    </div>
    </Form>
       
</div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Recordresult))