import React,{useEffect,useState} from 'react'
import axios from "axios";
import { Table } from 'react-bootstrap';
import SlideToggle from "react-slide-toggle";
import { Card,Modal} from 'react-bootstrap';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import {Redirect,Link} from 'react-router-dom';
import {connect} from 'react-redux';
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Select1 from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
function Advance({isAuthenticated,checkAuthenticated,useremail,payment,fetchPayment,isstaff,isadmin,username1}) {
  const [data,setData]=useState([""]);
  const [names,setName]=useState("");
  const [email,setEmail]=useState("");
  const [amount,setAmount]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [id,setId]=useState("");
  const [edit,setEdit]=useState(false);
  const [showhide,setShowhide]=useState(false);
  const [heading,setHeading]=useState(['Names', 'Email', 'Mobile','Town','Action']);
  const [deleteid,setDeleteId]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [data1, setdata1] = useState([])
  const [showhide2,setShowhide2]=useState(false);
  const [showhide3,setShowhide3]=useState(false);
  const [showhide4,setShowhide4]=useState(false);
  const [selectedOptions1, setSelectedOptions1] = useState([''])
  const { ExportCSVButton } = CSVExport;
   const { SearchBar } = Search;
   const options = {
     page: 1,
     sizePerPage: 10,
     nextPageText: '>',
     prePageText: '<',
     showTotal: true
   };
   const closeModal1=()=> {
    setShowhide1(false);
    
    }
    const closeModal2=()=> {
      setShowhide2(false);
      
      }
      const closeModal3=()=> {
        setShowhide3(false);
        
        }
        const closeModal4=()=> {
          setShowhide4(false);
          
          }
   const columns = [{
     dataField: 'employeeid',
     text: 'Emp. ID',
     headerStyle: () => {
       return { width: "150px" };
     }
   }
 ,{
  dataField: 'amount',
  text: 'Amount',
  headerStyle: () => {
    return { width: "150px" };
  }
},

{
     dataField: "id",
     text: "Month",
     headerStyle: () => {
       return { width: "100px" };
     },
     csvExport: false,
     editable: false,
     formatter: (cellContent, row) => {
       return (
         <div>
          {row.month+ '/'+ row.year}
         </div>
       );
     },
 },
 {
  dataField: 'approved',
  text: 'Approved',
  headerStyle: () => {
    return { width: "150px" };
  }
},{
  dataField: 'disbursed',
  text: 'Disbursed',
  headerStyle: () => {
    return { width: "150px" };
  }
}
,

 {
  dataField: "id",
  text: "Action",
  headerStyle: () => {
    return { width: "100px" };
  },
  csvExport: false,
  editable: false,
  formatter: (cellContent, row) => {
    return (
      <div>
   {isadmin=="true"&&<div className='row pl-3'><span className='border'><i class="fa fa-trash btn1 border1" onClick={e =>{ handleModalShowHide1(row.id)}}></i></span>
    
    <div class="wrapper">
    <div className='dotsdivactive'>
    <SlideToggle collapsed className=''
  render={({ toggle, setCollapsibleElement }) => (
    <div className="">
    <span className='border dots fixwidth  ' onClick={() => { toggle();}} >...</span>   
      <label className={" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle();}}>
     
      </label><span className={ 'whiteicon'}>{}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner" onClick={''}>
       <ul className='uldiv mr-1'>
       <li className='ulpadding' onClick={e =>{ handleModalShowHide2(row.id)}}>  Reject
                </li>
                <li className='ulpadding changecursor'onClick={e =>{ handleModalShowHide3(row.id)}}>Approve
                </li>
                <li className='ulpadding changecursor'onClick={e =>{ handleModalShowHide4(row.id)}}>Disburse
                </li> 

       </ul>
             

        </div>
      </div>
    </div>
  )}
/>
    </div>
    </div>
   
    </div>}
    </div>
    );
  },
}
];
 const renderList=()=>{
  return (data1.map(data =>({label:data.idnumber})))
} 
const handleSelectChange = (value) => {
  console.log(value.label)

  setSelectedOptions1(value);
  if(selectedOptions1.length>0){
    console.log(value)
  }
 
}

  useEffect(()=>{
    let data1 ;
 
    if(isadmin=="true"){
    axios({
      method:'post',
      url:'/totaladvance/',
      data:{useremail:useremail,username:username1},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
      console.log(data1)
    })
    .catch(err => {})
  }
  if(isstaff=="true"){
    axios({
      method:'post',
      url:'/searchadvance/',
      data:{useremail:useremail,staff:username1},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
      console.log(data1)
    })
    .catch(err => {})
  }
    checkAuthenticated();
    fetchPayment(useremail)
    
      if(!payment){
        return <Redirect to='/payment'/>}
      },[]) 
      const handleModalShowHide=()=> {
        setShowhide(!showhide);
    }
    const closeModal=()=> {
      setShowhide(false);
  }
  const refreshData=()=>{
    let data1 ;
if(isadmin){

    axios({
      method:'post',
      url:'/totaladvance/',
      data:{useremail:useremail,username:username1},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
       
    })
    .catch(err => {})
  }
  }
  const handledate = () => {
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return date;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if(!selectedOptions1.label | !amount){
   
       }else
    if(id){
     
      axios({
        method:'put',
        url:`/api/deductionnames/${id}/`,
        data:{name:names.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),owner:useremail,handledate},
      }).then((Response)=>{
        setEdit(false);
        setId('');
        //clear fields
    cleaFields();
      })
      setUpdateSuccess(true)
    }
    else{
     let dateobj= new Date()
     let month= dateobj.getUTCMonth() + 1
     let year= dateobj.getUTCFullYear()
   
   axios({
     method:'post',
     url:'/processadvance/',
     data:{id:selectedOptions1.label,amount:amount,month:month,year:year,owner:useremail,handledate},
   }).then((Response)=>{
    //clear fields
    if(Response.data=='exists'){
showToastMessage1()
    }else{
      setSaveSuccess(true)
      cleaFields();
    }
   
   })
   
   
  }
  };
  const editItem = (detail) => {
    setId(detail.id);
    setEdit(true);
    setShowhide(true);
    setName(detail.name);
    setEmail(detail.email);
    setMobile(detail.mobile);
    setTown(detail.town);

  };
  const showToastMessage = () => {
    toast.success('Record deleted succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
  const showToastMessage1 = () => {
    toast.error('Advance Record already exists', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
  const showToastMessage2 = () => {
    toast.success('Advance Record updated successfuly', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
  const handleDelete = (item) => {

    console.log(item);
    axios({
      method:'delete',
      url:`/api/advance/${deleteid}/`,
    }).then((Response)=>{
      handleModalShowHide1()
      showToastMessage()
      refreshData();
    })
  
  };
  const cleaFields=()=>{
    setName('');
    setEmail('');
    setMobile('');
    setTown('');
    refreshData();
  }
  useEffect(()=>{
    checkAuthenticated();
        //fetch staff records
        if(isadmin=="true"){
        axios({
          method:'post',
          url:'/totalstaffs/',
          data:{useremail:useremail},
        })
        .then((Response)=>{
         const datareceived=Response.data;
         console.log(datareceived)
        //set fields
       setdata1(datareceived)
        
          })}
          if(isstaff=="true"){
            axios({
              method:'post',
              url:'/searchstaff/',
              data:{useremail:useremail,id:username1},
            })
            .then((Response)=>{
             const datareceived=Response.data;
             console.log(datareceived)
            //set fields
           setdata1(datareceived)
            
              })}
      },[]) 
      useEffect(()=>{
     
      },[isAuthenticated]) 
      const handleModalShowHide1=(id)=> {
        setDeleteId(id)
        setShowhide1(!showhide1);
        console.log("clicked");
      }
      const handleModalShowHide2=(id)=> {
        setDeleteId(id)
        setShowhide2(!showhide2);
        console.log("clicked");
      }
      const handleModalShowHide3=(id)=> {
        setDeleteId(id)
        setShowhide3(!showhide3);
        console.log("clicked");
      }
      const handleModalShowHide4=(id)=> {
        setDeleteId(id)
        setShowhide4(!showhide4);
        console.log("clicked");
      }
      const handleReject = (item) => {

        console.log(item);
        axios({
          method:'put',
          url:`/api/advance/${deleteid}/`,
          data:{approved:"No"}
        }).then((Response)=>{
          handleModalShowHide2()
          showToastMessage2()
          refreshData();
        })
      
      };
      const handleApprove = (item) => {
    
        console.log(item);
        axios({
          method:'put',
          url:`/api/advance/${deleteid}/`,
          data:{approved:"Yes"}
        }).then((Response)=>{
          handleModalShowHide3()
          showToastMessage2()
          refreshData();
        })
      
      };
      const handleDisburse = (item) => {
    
        console.log(item);
        axios({
          method:'put',
          url:`/api/advance/${deleteid}/`,
          data:{disbursed:"Yes"}
        }).then((Response)=>{
          handleModalShowHide4()
          showToastMessage2()
          refreshData();
        })
      
      };

        if(payment=="false"){
          return <Redirect to='/payment'/>}
  return (
    <div>
      <div className='path'><i className='fa fa-home'></i>{edit? '/ Products / Edit Item':'/ Payroll / Advances'}</div>
        <ToastContainer
       hideProgressBar={true}
      />
        <div className='bodysection col-lg-7'>
        <div className=' col-lg-12 theader'>< h5>Advance List</h5> </div>
        <Button  onClick={handleModalShowHide} className="m-2 btn-primary">
           Apply
       </Button>
       <Modal show={showhide}>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title>Advance details</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record Submitted succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
<FormGroup>
<Label>Employee ID</Label>
<Select1
       value={selectedOptions1}
       defaultValue=""
       placeholder="Select staff"
       options={renderList()}
       onChange={ (value) => handleSelectChange(value) }
       name="ColumnSelect"
       label=""
       isClearable
/>
</FormGroup>


<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       
       required
     
     />
</FormGroup>
<FormGroup>
<Label>Amount</Label>
<Input className={''} type="number" placeholder="Amount"value={amount} onChange={e => setAmount(e.target.value)} name="email"  required/>

</FormGroup>
           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
       <div className='bg-light p-1'>
       <ToolkitProvider
keyField="id"
data={[...data] }
columns={ columns }
exportCSV={{ onlyExportFiltered: true, exportAll: false }}
search
>
{
props => (
<div>
<ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 

<SearchBar { ...props.searchProps } />

<BootstrapTable keyField='id'
{...props.baseProps}
data={ data } 
columns={ columns } 
pagination={ paginationFactory(options) }className="pgnav"
wrapperClasses="table-responsive">  

</BootstrapTable>
</div> 
)
}
</ToolkitProvider>
</div>
           </div>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide4} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'green','font-size':'18px'}}>Disburse Advance?</label><br/>
           <label style={{'font-size':'15px'}}>You are about to Disburse the Advance. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={handleDisburse}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal4}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide3} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'green','font-size':'18px'}}>Approve Advance?</label><br/>
           <label style={{'font-size':'15px'}}> You are about to Approve the Advance. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={handleApprove}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal3}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide2} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Reject Advance?</label><br/>
           <label style={{'font-size':'15px'}}> You are about to Reject the Advance. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={handleReject}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal2}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Advance?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting an Advance Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={handleDelete}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  isadmin:state.auth.isadmin,
  isstaff:state.auth.isstaff,
  username1:state.auth.username1,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchPayment})(Advance)