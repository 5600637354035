import {useState,useEffect} from 'react'
import Adminnav from './components/adminnav';
import Footer from './components/Footer';
import Adminsidebar from './components/Adminsidebar';
import "@coreui/chartjs/dist/css/coreui-chartjs.css";
import { Switch,Route,BrowserRouter as Router,matchPath } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Cinvoice from './components/Cinvoice';
import Vinvoice from './components/Vinvoice';
import Products from './components/Products';
import Customers from './components/Customers';
import Settings from './components/Settings';

import Login from './components/Login';
import { checkAuthenticated,load_user, resetPassword } from '././actions/auth';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import Payment from './components/Payment';
import Resetpassword from './components/Resetpassword';
import Resetpasswordconfirm from './components/Resetpasswordconfirm';
const App = ({isAuthenticated,checkAuthenticated,load_user}) => {

  const [click, setClick] = useState(false);
  const [click1, setClick1] = useState(false);
  const [click2, setClick2] = useState(false);
  const [click3, setClick3] = useState(false);
  const [click4, setClick4] = useState(false);
  const [click5, setClick5] = useState(false);
  const [click6, setClick6] = useState(false);
  const [click7, setClick7] = useState(false);
  const [click8, setClick8] = useState(false);
  const [click9, setClick9] = useState(false);
  const [click10, setClick10] = useState(false);
  const [adminMenu1, setadminMenu1] = useState(false);
  const [adminMenu2, setadminMenu2] = useState(false);
  const handleClick8 = () => 
  {
    setClick8(!click8);
console.log("clicked")
  }
  const handleClick = () => 
  {
    setClick(!click);
console.log("clicked")
  }
  const handleClick1 = () => 
  {
    setClick1(!click1);

  }
  const handleClick2 = () => 
  {
    setClick2(!click2);

  }
  const handleClick3 = () => 
  {
    setClick3(!click3);

  }
  const handleClick4 = () => 
  {
    setClick4(!click4);

  }
  const handleClick5 = () => 
  {
    setClick5(!click5);

  }
  const handleClick6 = () => 
  {
    setClick6(!click6);

  }
  const handleClick7 = () => 
  {
    setClick7(!click7);

  }
  const handleClick10 = () => 
  {
    setClick10(!click10);

  }
  const handleClick9 = () => 
  {
    setClick9(!click9);

  } 
  const adminMenuClick1 = () => {
    setadminMenu1(!adminMenu1);
   
  }
  const adminMenuClick2 = () => {
    setadminMenu2(!adminMenu2);
    
  }

  useEffect(()=>{
   checkAuthenticated();

      },[]) 
      if(isAuthenticated=="false" && window.location.pathname !== '/login'&& !matchPath(window.location.pathname, { path: "/password/reset/", exact: false })){
       
        window.location.href = "/login";
        
   }
   
  return (
    <div className="containerx">
      
      <Router>
      
        {isAuthenticated=="true" &&  <Adminsidebar click ={click} handleClick={handleClick} click1 ={click1} 
        handleClick1={handleClick1} click2 ={click2} handleClick2={handleClick2} click3 ={click3} 
        handleClick3={handleClick3} click4 ={click4} handleClick4={handleClick4}click5 ={click5} 
        handleClick5={handleClick5} click6 ={click6} handleClick6={handleClick6}  click7 ={click7} handleClick7={handleClick7} 
        click8 ={click8} handleClick8={handleClick8} click9 ={click9} handleClick9={handleClick9} click10 ={click10} handleClick10={handleClick10}/>}
      {isAuthenticated=='true'  && <Adminnav click ={click} handleClick={handleClick} adminMenuClick1={adminMenuClick1} adminMenu={adminMenu1} adminMenuClick2={adminMenuClick2} adminMenu2={adminMenu2} />}
      
      {isAuthenticated=='true' && <Footer click ={click} handleClick={handleClick} adminMenuClick1={adminMenuClick1} adminMenu={adminMenu1}   />}
       
      <Switch>
      
      {isAuthenticated=="false" && <Route path='/login' component={Login}/>}
           <Route path='/resetpassword' component={Resetpassword}/>
           
           <Route path="/password/reset/confirm/:uid/:token" component={Resetpasswordconfirm}/>
          </Switch>
           </Router>
    </div>
  );
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated
  });
export default connect(mapStateToProps,{checkAuthenticated,load_user})(App)

