import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
 
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import useKeyboardShortcut from 'use-keyboard-shortcut'

import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation,useParams } from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addclass.css'

const Addassignment=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,id,useremail,payment,match})=> {
 
  const [showhide,setShowhide]=useState(false);
  const [customerFetch,setCustomerFetch]=useState(false);
  const [amountvisible,setAmountVisible]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [showhide1,setShowhide1]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [names, setName] = useState("");
  const [filteredinvoice, setFilteredInvoice] = useState("");
  const [name, setNames] = useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [id1,setId]=useState("");
  const [inumber,setInumber]=useState("hello");
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(true);
  const [editbtn1,setEditbtn1]=useState(false);
  const [savebtn1,setSavebtn1]=useState(false);
  const [wait,setWait]=useState(false);
  const [wait1,setWait1]=useState(false);
  const [hide,setHide]=useState(true);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [dropopen,setDropOpen]=useState(false);
  const [dropdown,setDropDown]=useState("Unpaid");
  const [issuedate,setIssueDate]=useState("");
  const [duedate,setDueDate]=useState("");
  const [product,setProduct]=useState("");
  const [price,setPrice]=useState("");
  const [subtotal,setSubTotal]=useState("");
  const [search,setSearch]=useState("");
  const [searchcustomer,setSearchCustomer]=useState("");
  const [quantity,setQuantity]=useState("");
  const [index,setIndex]=useState("");
  const [dateentered,setDateEntered]=useState("");
  const [details,setDetails]=useState(['']);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [details2bkp,setDetails2bkp]=useState(['']);
  const [details1,setDetails1]=useState(['']);
  const [details2,setDetails2]=useState(['']);
  const [details3,setDetails3]=useState(['']);
  const [products,setProducts]=useState(['']);
  const [irecords,setIRecords]=useState([]);
  const [grandtotal,setGrandTotal]=useState('');
  const [grandtotal1,setGrandTotal1]=useState('');
  const [totalPartialAmount,setTotalPartialAmount]=useState('');
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const [musyoki,setMusyoki]=useState('musyoki');
  const [inputList1,setInputList1]=useState([]);
  const [heading,setHeading]=useState(['Names', 'Mobile','Action']);
  const [inputList, setInputList] = useState([]);
  const [inputList2, setInputList2] = useState([]);
  const [payments, setPayments] = useState(['']);
  const [data2,setData2]=useState([""]);
  const inputRef = useRef(null);
  const [notFound,setNotFound]=useState(false);
  const [notInternet,setNoInternet]=useState(false);
  const [classnumeral,setCnumeral]=useState("");
  const [classnumeralError,setCnumeralError]=useState(false);
  const [classstream,setCsream]=useState("");
  const [classstreamError,setCsreamError]=useState(false);
  const [validated,setValidated]=useState(false);
  const [success,setSuccess]=useState(false);
  const [result,setResult]=useState(false);
  const [assignmentname,setAname]=useState("");
  const [assignmentnameError,setAnameError]=useState(false);
  const [teacher,setTeacher]=useState("");
  const [teacherError,setTError]=useState(false);
  const [subject,setSubject]=useState("");
  const [subjectError,setSubjectError]=useState(false);
  const [deadline,setDeadline]=useState("");
  const [deadlineError,setDError]=useState(false);
  const [filename,setFname]=useState(null);
  const [filenameError,setFnameError]=useState(false);
  const showToastMessage = () => {
    toast.success('Record saved succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
  useEffect(()=>{
    //check if edit is true
    const invoicenumber = /[^/]*$/.exec(window.location.href)[0];
     if(match.params.id>=0)
     {
      setResult(true)
      setSavebtn(false)
     }
         //fetch edit records when editing
         if(result){
         //fetch class records
         axios({
          method:'post',
          url:'/assignmentsearch/',
          data:{adm:id},
        })
        .then((Response)=>{
         const datareceived=Response.data;
         console.log(datareceived)
       
          })
         //set edit
         setEdit(true);
         setEditbtn(true)
        
           //set local invoice
           setInvoiceNumber(invoicenumber)
          
         }else{
          
          
         }
    
      },[])

      useEffect(()=>{
        if(result){
         //fetch class records
         axios({
          method:'post',
          url:'/assignmentsearch/',
          data:{id:match.params.id},
        })
        .then((Response)=>{
         const datareceived=Response.data;
         console.log(datareceived)
        //set fields
        setAname(datareceived[0].assignmentname)
        setTeacher(datareceived[0].teacher)
        setSubject(datareceived[0].subject)
        setTeacher(datareceived[0].teacher)
        setDeadline(datareceived[0].deadline)
        setFname(datareceived[0].filename)
          })
         //set edit
         setEdit(true);
         setEditbtn(true)
        
        
        }
        
          },[result])
          useEffect(()=>{
            //save customer
        if(validated){
        
        //save student
        if(edit)
        {
        
        const editData = async () => {
        
        const result = await axios({
        method:'put',
        url:`http://localhost:8000/api/assignment/${match.params.id}/`,
        data:{assignmentname,teacher,subject,deadline,filename},
        })
        .then((Response)=>{
        if (Response.data){
        setSaveSuccess(true)
        setWait(false)
        
        setSuccess(true)
        setValidated(false)
        hidesuccess();
        
             }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
        setShowhide2(true)
        setWait(false)
        setNoInternet(true)
             }
        
        })
        
        }
        editData()
        }else
        {
        const saveData = async () => {
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
         
          const formfield= new FormData()
          formfield.append('assignmentname',assignmentname)
          formfield.append('teacher',teacher)
          formfield.append('subject',subject)
          formfield.append('deadline',deadline)
          formfield.append('filename',filename)
          formfield.append('owner',useremail)

          for(var pair of formfield.entries()) {
            console.log(pair[0]+ ', '+ pair[1]); 
         }

        const result = await axios({
        method:'post',
        url:'/api/assignment/',
        data:{formfield},
        })
        .then((Response)=>{
        if (Response.data){
         setSaveSuccess(true)
         setWait(false)
         setSavebtn(true)
        // setSuccess(true)
         setValidated(false)
       //  hidesuccess();
       showToastMessage()
        clearFields()
               }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
         setShowhide2(true)
         setWait(false)
         setNoInternet(true)
               }
        
        })
        
        }
        saveData()
        }
        
        }
            
              },[validated]) 

  const validateFields=(e)=>{
    e.preventDefault()
    
    if(assignmentname && teacher && subject && deadline && filename){
      setValidated(true)}
    
    else{
      if(assignmentname.trim().length==0){
        setAnameError(true)
       
        }
        if(teacher.trim().length==0){
          setTError(true)
         
          }
          if(deadline.trim().length==0){
            setDError(true)
           
            }
            if(!filename){
              setFnameError(true)
             
              }
              if(subject.trim().length==0){
                setSubjectError(true)
               
                }
         
    }}

    function hidesuccess() {
      return new Promise(resolve => {
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      });
    }
    const clearFields=()=>{
setCnumeral('')
setCsream('')

    }
   if(payment=="false"){
    return <Redirect to='/payment'/>}
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
 
        <div className='bodysection col-lg-5 pt-2'>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
        {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
 <h5 className='ml-1'>Add Assignment</h5>
<Form >
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input  type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />

</FormGroup>}
<FormGroup className='mr-2 ml-2'>
<Label>Assignment Name</Label>
<Input className={assignmentnameError &&'errorborder'} type="text" placeholder="Assignment"value={assignmentname} onChange={e => setAname(e.target.value)} name="email"  required/>
<div className={assignmentnameError ?'errmessage':'errmessagehide'}>Assignment is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>Teacher Name</Label>
<Input className={teacherError &&'errorborder'} type="text" placeholder="Teacher Name"value={teacher} onChange={e => setTeacher(e.target.value)} name="email"  required/>
<div className={teacherError ?'errmessage':'errmessagehide'}>Teacher is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>Subject</Label>
<Input className={subjectError &&'errorborder'} type="text" placeholder="Class Name"value={subject} onChange={e => setSubject(e.target.value)} name="email"  required/>
<div className={subjectError ?'errmessage':'errmessagehide'}>Subject is Reguired</div>
</FormGroup>

<FormGroup className='mr-2 ml-2 label2'>
<Label>Deadline</Label>
<Input className={deadlineError &&'errorborder'} type="date" placeholder="Class Name"value={deadline} onChange={e => setDeadline(e.target.value)} name="email"  required/>
<div className={deadlineError ?'errmessage':'errmessagehide'}>Deadline is Reguired</div>
</FormGroup>

<FormGroup className='mr-2 ml-2 label2'>
<Label>File</Label>
<Input className={filenameError &&'errorborder'} type="file" placeholder="Class Name" onChange={e => setFname(e.target.files[0])} name="file"  required/>
<div className={filenameError ?'errmessage':'errmessagehide'}>File is Reguired</div>
</FormGroup>
 
      <div className='row ml-1'>
  <Button color="primary"className=" mb-3 ml-2" type="submit"onClick={(e)=> validateFields(e)}>
      {editbtn && "Update"}
      {savebtn && "Submit"}
      {wait && " Please wait.."}
    </Button>  

    </div>
    </Form>
       
</div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Addassignment))