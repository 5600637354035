import React, { useState,useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useParams } from "react-router-dom";
import {useRef} from 'react';
import {connect} from 'react-redux';
import { Table } from 'react-bootstrap';
import { CChart } from '@coreui/react-chartjs'
import { DownloadTableExcel } from 'react-export-table-to-excel';
function Student({useremail}) {
  
    const [activeTab, setActiveTab] = useState("tab1");
    const FirstTab = ({match}) => {
      const [relation,setRelation]=useState('');
      const [studentadm, setStudentadm] = useState("");
      const [studentadmError, setStudentadmError] = useState(false);
      const [first_name, setFname] = useState("");
      const [dorm,setDom]=useState('');
  const [dorm1,setDom1]=useState('');
  const [id2,setId2]=useState("");
      const [first_nameError, setFnameError] = useState(false);
      const [last_name, setLname] = useState("");
      const [last_nameError, setLnameError] = useState(false);
      const [dob, setDob] = useState("");
      const [dobError, setDobError] = useState(false);
      const [class_name, setCname] = useState("");
      const [class_nameError, setCnameError] = useState(false);
      const [dormitory, setDormitory] = useState("");
      const [dormitoryError, setDormitoryError] = useState(false);
      const [religion, setReligion] = useState("");
      const [religionError, setReligionError] = useState(false);
      const [adm_date, setAdmdate] = useState("");
      const [adm_dateError, setAdmdateError] = useState(false);
      const [category, setCategory] = useState("");
      const [categoryError, setCategoryError] = useState(false);
      const [gender, setGender] = useState("");
      const [genderError, setGenderError] = useState(false);
      const [county, setCounty] = useState("");
      const [countyError, setCountyError] = useState(false);
      const [health, setHealth] = useState("");
      const [healthError, setHealthError] = useState(false);
      const [parent_first_name, setPfname] = useState("");
      const [parent_first_nameError, setPfnameError] = useState(false);
      const [parent_last_name, setPlname] = useState("");
      const [town,setTown]=useState("");
      const [parent_last_nameError, setPlnameError] = useState(false);
      const [phone, setPhone] = useState("");
      const [phoneError, setPhoneError] = useState(false);
      const [email,setEmail]=useState("");
      const [emailError,setEmailError]=useState(false); 
      const { id } = useParams();
     useEffect(()=>{
      //fetch student records
 axios({
  method:'post',
  url:'/studentsearch/',
  data:{id:id},
 
})
.then((Response)=>{
 const datareceived=Response.data;
 console.log(datareceived)
//set fields
setStudentadm(datareceived[0].studentadm)
setEmail(datareceived[0].email)
setFname(datareceived[0].first_name)
setLname(datareceived[0].last_name)
setDob(datareceived[0].dob)
setAdmdate(datareceived[0].adm_date)
setCounty(datareceived[0].county)
setHealth(datareceived[0].health)
setPfname(datareceived[0].parent_first_name)
setPlname(datareceived[0].parent_last_name)
setPhone(datareceived[0].phone)
setTown(datareceived[0].town)
setId2(datareceived[0].id)
setDom(datareceived[0].dormitory)
setDom1(datareceived[0].dormitory)
setCname(datareceived[0].class_name)
setReligion(datareceived[0].religion)
setCategory(datareceived[0].category)
setGender(datareceived[0].gender)
setRelation(datareceived[0].relation)
console.log(gender)
  })
     },[]) 
        return (
          <div className="FirstTab">
            <div className="details text-center bg-light">
<h3>{first_name +" "+ last_name}</h3>
<h6>{"Adm:" +" "+ studentadm}</h6>
<h6>{"Gender:" +" "+ gender}</h6>
<h6>{"Date of Birth:" +" "+ dob}</h6>
<h6>{"Religion:" +" "+ religion}</h6>
<h6>{"Admission Date:" +" "+ adm_date}</h6>
<h6>{"Class:" +" "+ class_name}</h6>
<h6>{"Category:" +" "+ category}</h6>
{!dorm=="Select Dormitory"&&<h6>{"Dorm:" +" "+ dorm}</h6>}
<h6>{"County:" +" "+ county}</h6>
<h6>{"Town:" +" "+ town}</h6>
<h6>{"Parent/ Guardian:" +" "+ parent_first_name+ " "+ parent_last_name}</h6>
<h6>{"Relationship:" +" "+ relation}</h6>
<h6>{"Phone:" +" "+ phone}</h6>
<h6>{"Email:" +" "+ email}</h6>
            </div>
          </div>
        );
      };

      const SecondTab = ({useremail,studentadm}) => {
        const [data,setData]=useState([""]);
        const [firstname,setFirstname]=useState([""]);
        const [lastname,setLastname]=useState([""]);
        const [totals,setTotals]=useState([""]);
        const [loading,setLoading]=useState(false);
        const [detailsbkp,setDetailsbkp]=useState(['']);
        const tableRef = useRef(null);
        const [stNum, setStNum]= useState([])
        const [kiswahili,setKiswahili]=useState(false);
  const [math,setMath]=useState(false);
  const [social,setSocial]=useState(false);
  const [literacy,setLiteracy]=useState(false);
  const [hygiene,setHygiene]=useState(false);
  const [environmental,setEnvironmenta]=useState(false);
  const [cre,setCre]=useState(false);
  const [home,setHome]=useState(false);
  const [creative,setCreative]=useState(false);
  const [physical,setPhysical]=useState(false);
  const [english,setEnglish]=useState(false);
  const [science,setScience]=useState(false);
  const [language,setLanguage]=useState(false); 
  const [Psychomotor_Creative,setPsychomotor_Creative]=useState(false); 
  const [braille,setBraille]=useState(false); 
  const [music,setMusic]=useState(false); 
  const [musicandmovement,setMusicandmovement]=useState(false); 
  const [numSubjects,setNumsubjects]=useState('');
    
        const [heading,setHeading]=useState(['Exam','Position','Total','Class','English','Kiswahili','Mathematics','Biology','Chemistry','Physics','CRE','Geography','Agriculure','Business','German','French']);
       
        useEffect(()=>{
          fetchAllMarks()

        },[])

        const fetchAllMarks=()=>{
console.log(studentadm)
console.log(useremail)
          let data4 ;
          axios({
           method:'post',
           url:'/examtablesearch1/',
           data:{useremail:useremail,examname:studentadm},
         })
         .then(res => {
          console.log(res.data.data)
             data4 = res.data.data;
             setData(data4);
             setFirstname(res.data.firstnames)
             setLastname(res.data.lastnames)
             setTotals(res.data.total) 
             setDetailsbkp(data4);
             setLoading(false)
             var count=0
             for (var key in data) {
               if (data[key] !== null && data[key] != "")
               count = count + 1
           }
             const result2 = res.data.data.map(option => option.examid);
             setStNum(result2)
             console.log(result2)

             let copy1=[];
             const result0 = data4.map(option => option.kiswahili); 
             if(result0.some(el => el !== null)){
              setKiswahili(true)
              copy1.push("col")
              console.log(numSubjects)
            }  
            const result1 = data4.map(option => option.english); 
             if(result1.some(el => el !== null)){
              setEnglish(true)
              copy1.push("col")
            } 
            const result3 = data4.map(option => option.mathematics); 
             if(result3.some(el => el !== null)){
              setMath(true)
              copy1.push("col")
            } 
            const result4 = data4.map(option => option.environmental); 
             if(result4.some(el => el !== null)){
              setEnvironmenta(true)
              copy1.push("col")
            } 
            const result5 = data4.map(option => option.hygiene); 
             if(result5.some(el => el !== null)){
              setHygiene(true)
              copy1.push("col")
            } 
            const result6 = data4.map(option => option.literacy); 
             if(result6.some(el => el !== null)){
              setLiteracy(true)
              copy1.push("col")
            } 
            const result7 = data4.map(option => option.cre); 
             if(result7.some(el => el !== null)){
              setCre(true)
              copy1.push("col")
            } 
            const result8 = data4.map(option => option.creative); 
             if(result8.some(el => el !== null)){
              setCreative(true)
              copy1.push("col")
            } 
            const result9 = data4.map(option => option.homescience); 
             if(result9.some(el => el !== null)){
              setHome(true)
              copy1.push("col")
            } 
            const result10 = data4.map(option => option.social); 
             if(result10.some(el => el !== null)){
              setSocial(true)
              copy1.push("col")
            } 
            const result12 = data4.map(option => option.scienceandt); 
             if(result12.some(el => el !== null)){
              setScience(true)
              copy1.push("col")
            } 
            const result11 = data4.map(option => option.physicalandhealth); 
            if(result11.some(el => el !== null)){
             setPhysical(true)
             copy1.push("col")
           } 
           const result13 = data4.map(option => option.language); 
           if(result13.some(el => el !== null)){
            setLanguage(true)
            copy1.push("col")
          } 
          const result14 = data4.map(option => option.Psychomotor_Creative); 
          if(result14.some(el => el !== null)){
           setPsychomotor_Creative(true)
           copy1.push("col")
         } 
         const result15 = data4.map(option => option.braille); 
         if(result15.some(el => el !== null)){
          setBraille(true)
          copy1.push("col")
        } 
        const result16 = data4.map(option => option.music); 
        if(result16.some(el => el !== null)){
         setMusic(true)
         copy1.push("col")
       } 
       const result17 = data4.map(option => option.musicandmovement); 
       if(result17.some(el => el !== null)){
        setMusicandmovement(true)
        copy1.push("col")
      } 
            setNumsubjects(copy1.length)
            console.log(numSubjects)
         })
         .catch(err => {})}
       
         const gradeMarks=(mark)=>{
          var grade="";
        if(mark>79){
        grade="EE";
        }
        if(mark>64 & mark < 80){
          grade="ME";
          }
          if(mark>49 & mark < 65){
            grade="AE";
            }
           
              if(mark<51){
                grade="BE";
                }
        
                return grade
        }
        const  notNull=(inv)=>{
          const filtered = data.filter(data => {
            return data.examid === inv;
          });
          console.log(filtered)
          var count=0
          for (var key in inv) {
            if (inv[key] !== null && inv[key] != "")
            count = count + 1
        }
            
          
return count
        }
        return (
          <div className="SecondTab bg-light">
           <div class="table-responsive ">
           <Table className=" pl-0" ref={tableRef}>
  <thead>
  {data.length==0? <div></div>:
    <tr >
   <th>Mean</th>
   <th>Total</th>
   <th>Student Adm</th>
   <th>Examname</th>
   <th>Class</th>
   {language &&<th>Language</th>}
   {english &&<th>English</th>}
   {kiswahili &&<th>Kiswahili</th>}
   {Psychomotor_Creative &&<th>Psychomotor_Creative</th>}
   {braille &&<th>Braille</th>}
   {music &&<th>Music</th>}
   {musicandmovement &&<th>Music_and_Movement</th>}
   {math && <th>Mathematics</th>}
   {environmental && <th>Environmental</th>}
   {hygiene && <th>Hygiene</th>}
   {literacy && <th>Literacy</th>}
   {cre && <th>Cre</th>}
   {creative && <th>Creative</th>}
   {home && <th>Homescience</th>}
   {science && <th>Science & T</th>}
   {physical && <th>Physical & H</th>}
   {social && <th>Social</th>}
    </tr>
  }
  </thead>
 
                <tbody>     
                {data.length==0? <div>No Records found</div>:data.map((detail,idx )=> (
                  <tr key={detail.id} >
                  <td className="col-2" >{Math.trunc((totals[idx]/(notNull(detail)-5))) + " "+ gradeMarks(totals[idx]/numSubjects)}</td>
                  <td className="col-2" >{ totals[idx]}</td>
                  <td class="col-2">{detail.adm}</td>
                  <td class="col-2">{detail.examid}</td>
                  <td class="col-2">{ detail.classname}</td>
                  {language &&<td class="col-2">{ detail.language?detail.language + " "+ gradeMarks(detail.language):'-'}</td>}
                     {english &&<td class="col-2">{ detail.english?detail.english + " "+ gradeMarks(detail.english):'-'}</td>}
                     {kiswahili &&<td class="col-2">{ detail.kiswahili?detail.kiswahili + " "+ gradeMarks(detail.kiswahili):'-'}</td>}
                     {Psychomotor_Creative &&<td class="col-2">{ detail.Psychomotor_Creative?detail.Psychomotor_Creative + " "+ gradeMarks(detail.Psychomotor_Creative):'-'}</td>}
                     {braille &&<td class="col-2">{ detail.braille?detail.braille + " "+ gradeMarks(detail.braille):'-'}</td>}
                     {music &&<td class="col-2">{ detail.music?detail.music + " "+ gradeMarks(detail.music):'-'}</td>}
                     {musicandmovement &&<td class="col-2">{ detail.musicandmovement?detail.musicandmovement + " "+ gradeMarks(detail.musicandmovement):'-'}</td>}
                     
                  {math &&<td class="col-2">{ detail.mathematics?detail.mathematics + " "+ gradeMarks(detail.mathematics):'-'}</td>}
                  {environmental &&<td class="col-2">{ detail.environmental?detail.environmental + " "+ gradeMarks(detail.environmental):'-'}</td>}
                  {hygiene &&<td class="col-2">{ detail.hygiene?detail.hygiene + " "+ gradeMarks(detail.hygiene):'-'}</td>}
                  {literacy && <td class="col-2">{ detail.literacy?detail.literacy + " "+ gradeMarks(detail.literacy):'-'}</td>}
                  {cre && <td class="col-2">{ detail.cre?detail.cre + " "+ gradeMarks(detail.cre):'-'}</td>}
                  {creative &&<td class="col-2">{ detail.creative?detail.creative + " "+ gradeMarks(detail.creative):'-'}</td>}
                  {home &&<td class="col-2">{ detail.homescience?detail.homescience + " "+ gradeMarks(detail.homescience):'-'}</td>}
                  {science &&<td class="col-2">{ detail.scienceandt?detail.scienceandt + " "+ gradeMarks(detail.scienceandt):'-'}</td>}
                  {physical &&<td class="col-2">{ detail.physicalandhealth?detail.physicalandhealth + " "+ gradeMarks(detail.physicalandhealth):'-'}</td>}
                  {social &&<td class="col-2">{ detail.social?detail.social + " "+ gradeMarks(detail.social):'-'}</td>}
                
                </tr>
             ))}
    </tbody>
    
     
           </Table> 
          
           </div>

           <div class="card ">
    <div class="card-body text-center">
    {data.length>0 &&<h4 class="card-title">Exams Graph</h4>}
    {data.length>0 &&
    <CChart
   
    width="200"
    height="100"
  type="line"
  data={{
    labels: stNum,
    datasets: [
      {
        backgroundColor: ['#85C1E9', '#AED6F1'],
        data: totals,
      },
    ],
  }}
  options={{
    legend: {
      display: false
  },
   
    tooltips: {
      enabled: false
    }
  }}
/>}
    </div>
  </div>
          </div>
        );
      };

      const ThirdTab = ({useremail,studentadm}) => {
       
        const [data,setData]=useState([""]);
        const [loading,setLoading]=useState(false);
        const tableRef = useRef(null);
        const [heading,setHeading]=useState(['Student Adm.','Description','Amount Paid','Date']);
        useEffect(()=>{
         
          
 
 
          //fetch total payment
      
          fetchAllPayment()
         
           },[]) 
       const fetchAllPayment=async()=>{
        
        let data4 ;

       const fee= await axios({
         method:'post',
         url:'/totalfeepayment1/',
         data:{useremail:useremail,adm:studentadm},
       })
       .then(res => {
           data4 = res.data;
           setData(data4);
           console.log(data4) 
          
           setLoading(false)
       })
       .catch(err => {})
     
  }
      
        return (
          <div className="Third Tab bg-light">
             <DownloadTableExcel
                    filename={'fee statement'+" "+id}
                    sheet={id}
                    currentTableRef={tableRef.current}
                >

{data.length>0 &&  <Button className='ml-2 mt-2 text-right'> Download </Button>}

                </DownloadTableExcel>
           <div class="table-responsive ">
<Table className=" pl-0"ref={tableRef}>
  <thead>
    <tr >
    {data.length>0 && heading.map(head => <th>{head}</th>)}
    </tr>
  </thead>
 
                <tbody>     
                {data.length==0? <div>No Records found</div>:data.map((detail,idx)=> (
                  <tr key={detail.id} >
                
                     <td className="col-3" >{detail.studentadm}</td>
                     <td className="col-2" >{detail.feetype}</td>
                     <td className="col-2" >{detail.amount}</td>
                     <td className="col-2" >{detail.dateentered}</td>
                   </tr>
             ))}
    </tbody>
    
     
           </Table> 
           
           </div>
          </div>
        );
      };
      const [studentadm, setStudentadm] = useState("");
      useEffect(()=>{
      
       

        //fetch student records
  axios({
    method:'post',
    url:'/studentsearch/',
    data:{id:id},
   
  })
  .then((Response)=>{
   const datareceived=Response.data;
   console.log(datareceived)
  //set fields
  setStudentadm(datareceived[0].studentadm)
  
    })

  },[]) 
  //  Functions to handle Tab Switching
  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1");
  };
  const handleTab3 = () => {
    // update the state to tab2
    setActiveTab("tab3");
  };

  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2");
  };
  const { id } = useParams();
  return (
    <div>
<div className='path'><i className='fa fa-home'></i>/ Students / View Students/ {studentadm}</div>

    <div className=" bodysection4 col-md-8">
      {/* Tab nav */}
      <div class="">
  <button type="button" className={activeTab === "tab1"?" btn btn-primary active btnmenu" : "btn btn-primary btnmenu"}
    onClick={handleTab1} >Details</button>
 <button type="button" className={activeTab === "tab2"?" btn btn-primary active btnmenu" : "btn btn-primary btnmenu"}
    onClick={handleTab2} >Performance</button>
  <button type="button" className={activeTab === "tab3"?" btn btn-primary active btnmenu" : "btn btn-primary btnmenu"}
    onClick={handleTab3} >Fee Payment</button>
</div>
     <div>
     
      <div className="outlet  ">
      {activeTab === "tab1" && <FirstTab studentadm={studentadm}useremail={useremail} /> }
      {activeTab === "tab2" && <SecondTab useremail={useremail}id={id} studentadm={studentadm}/> }
      {activeTab === "tab3" && <ThirdTab useremail={useremail}id={id} studentadm={studentadm}/> }
      </div>
      </div>
    </div>
    </div>
  )
}
const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  payment:state.auth.payment
  });
  export default connect(mapStateToProps,{})(Student)