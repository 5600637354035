import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { ToastContainer, toast } from 'react-toastify';
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import { ToastProvider, useToasts } from 'react-toast-notifications';
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import useKeyboardShortcut from 'use-keyboard-shortcut'
import Select from 'react-dropdown-select'
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation,useParams } from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addclass.css'
import Select1 from 'react-select'

const Issuebook=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,id,useremail,payment,match,username1})=> {
 
  const [showhide,setShowhide]=useState(false);
  const [customerFetch,setCustomerFetch]=useState(false);
  const [amountvisible,setAmountVisible]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [showhide1,setShowhide1]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [names, setName] = useState("");
  const [filteredinvoice, setFilteredInvoice] = useState("");
  const [name, setNames] = useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [id1,setId]=useState("");
  const [inumber,setInumber]=useState("hello");
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(true);
  const [editbtn1,setEditbtn1]=useState(false);
  const [savebtn1,setSavebtn1]=useState(false);
  const [wait,setWait]=useState(false);
  const [wait1,setWait1]=useState(false);
  const [hide,setHide]=useState(true);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [dropopen,setDropOpen]=useState(false);
  const [dropdown,setDropDown]=useState("Unpaid");
  const [issuedate,setIssueDate]=useState("");
  const [duedate,setDueDate]=useState("");
  const [product,setProduct]=useState("");
  const [price,setPrice]=useState("");
  const [subtotal,setSubTotal]=useState("");
  const [search,setSearch]=useState("");
  const [searchcustomer,setSearchCustomer]=useState("");
  const [quantity,setQuantity]=useState("");
  const [index,setIndex]=useState("");
  const [dateentered,setDateEntered]=useState("");
  const [details,setDetails]=useState(['']);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [details2bkp,setDetails2bkp]=useState(['']);
  const [details1,setDetails1]=useState(['']);
  const [details2,setDetails2]=useState(['']);
  const [details3,setDetails3]=useState(['']);
  const [products,setProducts]=useState(['']);
  const [irecords,setIRecords]=useState([]);
  const [grandtotal,setGrandTotal]=useState('');
  const [grandtotal1,setGrandTotal1]=useState('');
  const [totalPartialAmount,setTotalPartialAmount]=useState('');
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const [musyoki,setMusyoki]=useState('musyoki');
  const [inputList1,setInputList1]=useState([]);
  const [heading,setHeading]=useState(['Names', 'Mobile','Action']);
  const [inputList, setInputList] = useState([]);
  const [inputList2, setInputList2] = useState([]);
  const [payments, setPayments] = useState(['']);
  const [data2,setData2]=useState([""]);
  const inputRef = useRef(null);
  const [notFound,setNotFound]=useState(false);
  const [notInternet,setNoInternet]=useState(false);
  const [classnumeral,setCnumeral]=useState("");
  const [classnumeralError,setCnumeralError]=useState(false);
  const [classstream,setCsream]=useState("");
  const [classstreamError,setCsreamError]=useState(false);
  const [validated,setValidated]=useState(false);
  const [success,setSuccess]=useState(false);
  const [result,setResult]=useState(false);
  const [author,setAuthor]=useState("");
  const [authorError,setAuthorError]=useState(false);
  const [title,setTitle]=useState("");
  const [titleError,setTitleError]=useState(false);
  const [bookid,setBid]=useState("");
  const [bookidError,setBidError]=useState(false);
  const [subject,setSubject]=useState("");
  const [inputStyle, setInputStyle] = useState( { })
const [inputStyle1, setInputStyle1] = useState( { })
const [selectedOptions, setSelectedOptions] = useState([])
const [selectedOptions1, setSelectedOptions1] = useState([])
const [studentrecord,setSrecord]=useState([""]);
const [bookrecord,setBrecord]=useState([""]);
const [dateissued,setDissued]=useState("");
const [dateissuedError,setDissuedError]=useState(false);
const [returnbyError,setRbyError]=useState(false);
const [admError,setSAdmError]=useState(false);
const [returnby,setRby]=useState("");
const [valueState, setValueState] = useState([]);
const [valueState1, setValueState1] = useState([]);
const [responseData,setResponseData]=useState([""]);
const [responseData1,setResponseData1]=useState([""]);
const showToastMessage = () => {

  toast.success('Record saved succesfully', {
      position: toast.POSITION.TOP_CENTER
  });
};
const showToastMessage2 = () => {

  toast.error('You dont have permission to perform this action', {
      position: toast.POSITION.TOP_CENTER
  });
};    
useEffect(()=>{
 //fetch student
 let data5 ;
 axios({
  method:'post',
  url:'/totalstudents/',
  data:{useremail:useremail},
})
.then(res => {
    data5 = res.data;
    setSrecord(data5);
    setResponseData1(data5)
    
})
.catch(err => {})
fetchBooks()

},[]) 
const fetchBooks=()=>{
  
   //fetch book id
 let data4 ;
 axios({
  method:'post',
  url:'/totalbooksavailable/',
  data:{useremail:useremail},
})
.then(res => {
    data4 = res.data;
    setBrecord(data4);
    setResponseData(data4)
    
})
.catch(err => {})
}
          useEffect(()=>{
            //
        if(validated){
        
        //save issue book
        if(useremail=="4")
        {
        showToastMessage2();
        }
        else{
        if(edit)
        {
        
        const editData = async () => {
        
        const result = await axios({
        method:'put',
        url:`/api/book/${match.params.id}/`,
        data:{classnumeral,classstream},
        })
        .then((Response)=>{
        if (Response.data){
        setSaveSuccess(true)
        setWait(false)
        setValidated(false)
        //hidesuccess();
        
             }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
        setShowhide2(true)
        setWait(false)
        setNoInternet(true)
             }
        
        })
        
        }
        editData()
        }else
        {
        const saveData = async () => {
    if(validated){
        const result = await axios({
        method:'post',
        url:'/api/issuedbook/',
        data:{bookid:valueState.label.split(',').shift(),studentadm:valueState1.label.split(',').shift(),dateissued,returnby,owner:useremail,postedby:username1},
        })
        .then((Response)=>{
        if (Response.data){
        removeErrors()
          showToastMessage()
         setSaveSuccess(true)
         setWait(false)
         setSavebtn(true)
         
         setValidated(false)
        
        clearFields()
               }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
         setShowhide2(true)
         setWait(false)
         setNoInternet(true)
               }
        
        })
        //edit book record to borrowed

        axios({
            method:'post',
            url:'/editstatus/',
            data:{bookid:valueState.label.split(',').shift(),useremail:useremail,status:'Borrowed'},
            })
            .then((Response)=>{
            if (Response.data){
              fetchBooks()
                   }
            })
            .catch((error) => {
            if (error.code="ERR_BAD_RESPONSE"){
           
                   }
            
            })
        }}
        saveData()
        fetchBooks()
        }
      }
        }
            
              },[validated]) 
             
const removeErrors=()=>{
  setBidError(false)
  setInputStyle({
    'border-color':'#D3D3D3'
  })
  setSAdmError(false)
              
  setInputStyle1({
    'border-color':'#D3D3D3'
  })
  setDissuedError(false)
  setRbyError(false)
}
  const validateFields=(e)=>{
    e.preventDefault()
    if(valueState && valueState1 && dateissued && returnby ){
      
      setValidated(true)}
    
    else{
        if(valueState.length==0){
            setBidError(true)
          
                setInputStyle({
                  'border-color':'red'
                })
            }
            if(valueState1.length==0){
                setSAdmError(true)
              
                    setInputStyle1({
                      'border-color':'red'
                    })
                }
      if(dateissued.trim().length==0){
        setDissuedError(true)
       
        }
        if(returnby.trim().length==0){
          setRbyError(true)
          }
          
    }}
    const handleSelectChange = (value) => {
      setValueState(value);
      if(valueState.length>0){
        console.log(value)
      }
     
    }
    const handleSelectChange1 = (value) => {
      setValueState1(value);
      console.log(valueState1)
      if(valueState1.length>0){
        
      }
     
    }
   
    const renderList=()=>{
      return (responseData.map(data =>({label:data.bookid+','+data.title})))
    }
    const renderList1=()=>{
      return (responseData1.map(data =>({label:data.studentadm+','+data.first_name+' '+data.last_name})))
    }
    function hidesuccess() {
      return new Promise(resolve => {
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      });
    }
    const clearFields=()=>{
        setSelectedOptions([])
        setSelectedOptions1([])
        setIssueDate('')
        setRby('')
        setDissued('')
        setValueState([])
        setValueState1([])
    }
   if(payment=="false"){
    return <Redirect to='/payment'/>}
  return (
    <div>
      <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>/ Library / Issue Book</div>
        <div className='bodysection col-lg-4 '>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
        
<div className=' col-lg-12 theader'>< h5>Issue Book</h5> </div>
<Form >
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input  type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />

</FormGroup>}
<FormGroup className='mr-2 ml-2'>
<Label>Book Id.</Label>
<Select1
className={returnbyError &&'errorborder'}
       value={valueState}
       defaultValue=""
       placeholder="Select Bookid"
       options={renderList()}
       onChange={ (value) => handleSelectChange(value) }
       name="ColumnSelect"
       label=""
       styles={{
        control: (provided, state) => ({
          ...provided,
          
          borderColor: bookidError? "red":"#D8D8D8"
        }),
        menu: (provided, state) => ({
          ...provided,
          
          
        }),
        option: (provided, state) => ({
           ...provided,
          
        })
      }}
/>

<div className={bookidError ?'errmessage':'errmessagehide'}>Book Id is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>Student Adm.</Label>
<Select1
       value={valueState1}
       defaultValue=""
       placeholder="Select Adm"
       options={renderList1()}
       onChange={ (value) => handleSelectChange1(value) }
       name="ColumnSelect"
       label=""
       styles={{
        control: (provided, state) => ({
          ...provided,
          
          borderColor: admError? "red":"#D8D8D8"
        }),
        menu: (provided, state) => ({
          ...provided,
          
          
        }),
        option: (provided, state) => ({
           ...provided,
          
        })
      }}
/>


<div className={admError ?'errmessage':'errmessagehide'}>Student Adm is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>Date Issued.</Label>
<Input className={dateissuedError &&'errorborder'} type="date" placeholder=""value={dateissued} onChange={e => setDissued(e.target.value)} name="email"  required/>
<div className={dateissuedError ?'errmessage':'errmessagehide'}>Date Issued is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>Return Date</Label>
<Input className={returnbyError &&'errorborder'}  type="date" placeholder="Class Name"value={returnby} onChange={e => setRby(e.target.value)} name="email"  required/>
<div className={returnbyError ?'errmessage':'errmessagehide'}>Return Date is Reguired</div>
</FormGroup>
 
      <div className='row ml-1 label2'>
  <Button color="primary"className=" mb-3 ml-2 mt-4" type="submit"onClick={(e)=> validateFields(e)}>
      {editbtn && "Update"}
      {savebtn && "Submit"}
      {wait && " Please wait.."}
    </Button>  

    </div>
    </Form>
       
</div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Issuebook))