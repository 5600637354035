import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import Select1 from 'react-select'
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import useKeyboardShortcut from 'use-keyboard-shortcut'

import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation,useParams } from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addclass.css'
import Select from 'react-dropdown-select'
const Makepayment=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,id,useremail,payment,match,username1})=> {
 
  const [showhide,setShowhide]=useState(false);
  const [customerFetch,setCustomerFetch]=useState(false);
  const [amountvisible,setAmountVisible]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [showhide1,setShowhide1]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [names, setName] = useState("");
  const [filteredinvoice, setFilteredInvoice] = useState("");
  const [name, setNames] = useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [id1,setId]=useState("");
  const [inumber,setInumber]=useState("hello");
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(true);
  const [editbtn1,setEditbtn1]=useState(false);
  const [savebtn1,setSavebtn1]=useState(false);
  const [wait,setWait]=useState(false);
  const [wait1,setWait1]=useState(false);
  const [hide,setHide]=useState(true);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [dropopen,setDropOpen]=useState(false);
  const [dropdown,setDropDown]=useState("Unpaid");
  const [issuedate,setIssueDate]=useState("");
  const [duedate,setDueDate]=useState("");
  const [product,setProduct]=useState("");
  const [price,setPrice]=useState("");
  const [subtotal,setSubTotal]=useState("");
  const [search,setSearch]=useState("");
  const [searchcustomer,setSearchCustomer]=useState("");
  const [quantity,setQuantity]=useState("");
  const [index,setIndex]=useState("");
  const [dateentered,setDateEntered]=useState("");
  const [details,setDetails]=useState(['']);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [details2bkp,setDetails2bkp]=useState(['']);
  const [details1,setDetails1]=useState(['']);
  const [details2,setDetails2]=useState(['']);
  const [details3,setDetails3]=useState(['']);
  const [products,setProducts]=useState(['']);
  const [irecords,setIRecords]=useState([]);
  const [grandtotal,setGrandTotal]=useState('');
  const [grandtotal1,setGrandTotal1]=useState('');
  const [totalPartialAmount,setTotalPartialAmount]=useState('');
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const [musyoki,setMusyoki]=useState('musyoki');
  const [inputList1,setInputList1]=useState([]);
  const [heading,setHeading]=useState(['Names', 'Mobile','Action']);
  const [inputList, setInputList] = useState([]);
  const [inputList2, setInputList2] = useState([]);
  const [payments, setPayments] = useState(['']);
  const [data2,setData2]=useState([""]);
  const inputRef = useRef(null);
  const [notFound,setNotFound]=useState(false);
  const [notInternet,setNoInternet]=useState(false);
  const [studentadm,setSAdm]=useState("");
  const [studentadmError,setSAdmError1]=useState(false);
  const [feetype,setFtype]=useState("");
  const [feetypeError,setFTError]=useState(false);
  const [amount,setAmount]=useState("");
  const [date,setDate]=useState("");
  const [amountError,setAmountError]=useState(false);
  const [validated,setValidated]=useState(false);
  const [success,setSuccess]=useState(false);
  const [result,setResult]=useState(false);
  const [feerecord,setFrecord]=useState([""]);
  const [studentrecord,setSrecord]=useState([""]);
  const [admError,setSAdmError]=useState(false);
  const [dateError,setDateError]=useState(false);
  const [options, setOptions] = useState( [
    { id: 1, country: "1 Blue" },
    { id: 2, country: "2 Blue" }, 
    { id: 3, country: "3 Blue" },
    { id: 4, country: "4 Blue" },
] )
const [valueState1, setValueState1] = useState([]);
const [valueState, setValueState] = useState([]);
const [options1, setOptions1] = useState( [
    { id: 1, country: "1 Blue" },
    { id: 2, country: "2 Blue" }, 
    { id: 3, country: "3 Blue" },
    { id: 4, country: "4 Blue" },
] )
const [inputStyle, setInputStyle] = useState( { })
const [inputStyle1, setInputStyle1] = useState( { })
const [selectedOptions, setSelectedOptions] = useState([])
const [selectedOptions1, setSelectedOptions1] = useState([])
const [responseData1,setResponseData1]=useState([""]);
const [responseData,setResponseData]=useState([""]);
const showToastMessage = () => {

  toast.success('Record saved succesfully', {
      position: toast.POSITION.TOP_CENTER
  });
};
const showToastMessage2 = () => {

  toast.error('You dont have permission to perform this action', {
      position: toast.POSITION.TOP_CENTER
  });
}; 
  useEffect(()=>{
    //check if edit is true
    const invoicenumber = /[^/]*$/.exec(window.location.href)[0];
     if(match.params.id>=0)
     {
      setResult(true)
      setSavebtn(false)
     }
         //fetch edit records when editing
         if(result){
         //fetch class records
         axios({
          method:'post',
          url:'/feetypetotal/',
          data:{adm:id},
        })
        .then((Response)=>{
         const datareceived=Response.data;
         console.log(datareceived)
       
          })
         //set edit
         setEdit(true);
         setEditbtn(true)
        
           //set local invoice
           setInvoiceNumber(invoicenumber)
          
         }else{
          
          
         }
         //fetch fee type
     let data4 ;
     axios({
      method:'post',
      url:'/totalfeetype/',
      data:{useremail:useremail},
    })
    .then(res => {
        data4 = res.data;
        setFrecord(data4);
        setResponseData(data4)
        
    })
    .catch(err => {})

      //fetch student
      let data5 ;
      axios({
       method:'post',
       url:'/totalstudents/',
       data:{useremail:useremail},
     })
     .then(res => {
         data5 = res.data;
         setSrecord(data5);
         setResponseData1(data5)
     })
     .catch(err => {})
 
    
      },[])

      useEffect(()=>{
        if(result){
         //fetch class records
         axios({
          method:'post',
          url:`/feetypesearch/`,
          data:{id:match.params.id,useremail:useremail}
        })
        .then((Response)=>{
         const datareceived=Response.data;
         console.log(datareceived)
        //set fields
        
        
          })
         //set edit
         setEdit(true);
         setEditbtn(true)
        
        
        }
        
          },[result])
          useEffect(()=>{
            //save 
        if(validated){
        
        //save payment
        if(useremail=="1")
      {
      showToastMessage2();
      }
      else{
        if(edit)
        {
        
        const editData = async () => {
        
        const result = await axios({
        method:'put',
        url:`/api/feetype/${match.params.id}/`,
        data:{},
        })
        .then((Response)=>{
        if (Response.data){
        setSaveSuccess(true)
        setWait(false)
        
        setSuccess(true)
        setValidated(false)
        hidesuccess();
        
             }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
        setShowhide2(true)
        setWait(false)
        setNoInternet(true)
             }
        
        })
        
        }
        editData()
        }else
        {
        const saveData = async () => {
        
        const result = await axios({
        method:'post',
        url:'/api/feepayment/',
        data:{studentadm:valueState1.label.split(',').shift(),feetype:valueState.label,amount,dateentered:date,owner:useremail,postedby:username1},
        })
        .then((Response)=>{
        if (Response.data){
         setSaveSuccess(true)
         setWait(false)
         setSavebtn(true)
         removeErrors()
         showToastMessage()
        // setSuccess(true)
         setValidated(false)
        // hidesuccess();
        clearFields()
               }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
         setShowhide2(true)
         setWait(false)
         setNoInternet(true)
               }
        
        })
        
        }
        saveData()
        }
      }
        }
            
              },[validated]) 
const removeErrors=()=>{
  setSAdmError(false)
  setFTError(false)
  setAmountError(false)
}
  const validateFields=(e)=>{
    e.preventDefault()
    if(valueState && valueState1 && amount ){
      setValidated(true)}
    
    else{
      if(valueState1.length==0){
        setSAdmError(true)
      
        }
        if(valueState.length==0){
            setFTError(true)
          
               
            }
        if(amount.trim().length==0){
          setAmountError(true)
         
          }
          if(date.trim().length==0){
            setDateError(true)
           
            }
    }}
    const handleSelectChange1 = (value) => {
      setValueState1(value);
      console.log(valueState1)
      if(valueState1.length>0){
        
      }
     
    }
    const handleSelectChange = (value) => {
      setValueState(value);
      console.log(valueState)
      if(valueState.length>0){
        
      }
     
    }
    function hidesuccess() {
      return new Promise(resolve => {
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      });
    }
    const renderList1=()=>{
      return (responseData1.map(data =>({label:data.studentadm+','+data.first_name+' '+data.last_name})))
    }
    const renderList=()=>{
      return (responseData.map(data =>({label:data.feetitle})))
    }
    const clearFields=()=>{
      setValueState([])
      setValueState1([])
setAmount('')

    }
   if(payment=="false"){
    return <Redirect to='/payment'/>}
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>/ Finance / Make Payment</div>
        <div className='bodysection col-lg-4 '>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
      
<div className=' col-lg-12 theader'>< h5>New Payment</h5> </div>
<Form >
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input  type="text"style={{width:"200px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />

</FormGroup>}
<FormGroup className='mr-2 ml-2'>
<Label>Student Admission No.</Label>
<Select1
       value={valueState1}
       defaultValue=""
       placeholder="Select ADM"
       options={renderList1()}
       onChange={ (value) => handleSelectChange1(value) }
       name="ColumnSelect"
       label=""
       styles={{
        control: (provided, state) => ({
          ...provided,
          
          borderColor: admError? "red":"#D8D8D8"
        }),
        menu: (provided, state) => ({
          ...provided,
          
          
        }),
        option: (provided, state) => ({
           ...provided,
          
        })
      }}
/>

<div className={admError ?'errmessage':'errmessagehide'}>Student Adm is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>Fee Type</Label>
<Select1
       value={valueState}
       defaultValue=""
       placeholder="Select Fee Item"
       options={renderList()}
       onChange={ (value) => handleSelectChange(value) }
       name="ColumnSelect"
       label=""
       styles={{
        control: (provided, state) => ({
          ...provided,
          
          borderColor: feetypeError? "red":"#D8D8D8"
        }),
        menu: (provided, state) => ({
          ...provided,
          
          
        }),
        option: (provided, state) => ({
           ...provided,
          
        })
      }}
/>


<div className={feetypeError?'errmessage':'errmessagehide'}>Fee Item is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>Amount</Label>
<Input className={amountError &&'errorborder'} type="number" placeholder="Amount"value={amount} onChange={e => setAmount(e.target.value)} name="email"  required/>
<div className={amountError ?'errmessage':'errmessagehide'}>Amount is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>Date</Label>
<Input className={amountError &&'errorborder'} type="date" placeholder="Amount"value={date} onChange={e => setDate(e.target.value)} name="email"  required/>
<div className={dateError ?'errmessage':'errmessagehide'}>Date is Reguired</div>
</FormGroup>
 
      <div className='row ml-1'>
  <Button color="primary"className=" mb-3 ml-2" type="submit"onClick={(e)=> validateFields(e)}>
      {editbtn && "Update"}
      {savebtn && "Submit"}
      {wait && " Please wait.."}
    </Button>  

    </div>
    </Form>
       
</div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Makepayment))