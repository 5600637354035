import React, {useState,useEffect,useRef} from 'react'
import {connect} from 'react-redux';
import "./Login.css"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
 
} from "reactstrap";
import  { encrypt , decrypt } from 'react-crypt-gsm';
import { checkAuthenticated,load_user,login,fetchUser,resetPasswordConfirm} from './../actions/auth';
import {Link, Redirect} from 'react-router-dom';
import validator from 'validator'
import Resetpassword from './Resetpassword';
function Resetpasswordconfirm({login, isAuthenticated,checkAuthenticated,load_user,user,loginCheck,fetchUser,resetPasswordConfirm,match,resetpassword }) {
  const [formData,setformData]=useState({
    email:'',
    password:''
       });
       const [error,setError]=useState()
       const [clicked,setClicked]=useState(false)
       const [emailError,setEmailError]=useState({})
       const [passwordError,setPasswordError]=useState({})
       const {newPassword,reNewPassword}= formData;
       useEffect(()=>{
       
        
          },[]) 
          
          useEffect(()=>{   
            if(newPassword && reNewPassword && !loginCheck && !newPassword.trim().length==0 && !reNewPassword.trim().length==0){
              
              }
          },[loginCheck]) 
      
    
       const onChange=(e)=>setformData({...formData,[e.target.name]:e.target.value});
       const onSubmit=e=>{
           e.preventDefault();
          
           const uid=match.params.uid;
           const token=match.params.token;
           setClicked(false)
           const isValid=formValidation();
           if(newPassword==reNewPassword){
            
            const encryptedStr = encrypt(newPassword);
           resetPasswordConfirm(uid,token,encryptedStr.content,reNewPassword);
           checkAuthenticated();
           setError(isAuthenticated);
           
          }else{
           setClicked(true)
          }
          
           }

           
      const formValidation=()=>{
    const emailErr={};
    const passwordErr={};
    let isValid=true;
    if(!newPassword){
     
      emailErr.emailShort="Email is required";
      isValid=false;
    }
   
    if(!reNewPassword){
      passwordErr.passwordShort="Password is required";
      isValid=false;
    }
    setEmailError(emailErr);
    setPasswordError(passwordErr);
    return isValid
      }
      
        if(resetpassword){
          alert("Your Password has been reset successfully")
          return <Redirect to='/login'/>}
  return (
    <div>
        <div className='loginscreen col-lg-4 '>
<div className=' mt-3  '><h3><b>Password Reset</b></h3></div>
{clicked  && <h6 className='error1' style={{color:"red"}} >Passwords don't match</h6>}
{resetpassword  && <h6 className='error1' style={{color:"green"}} >Password has been reset. You will be redirected to the Login page</h6>}
{resetpassword==false  && <h6 className='error1' style={{color:"red"}} >Something went wrong</h6>}
<form onSubmit={e=>onSubmit(e)}> 
<FormGroup>
<Label>Password</Label>
<Input type='password'
placeholder='enter your password'
name='newPassword'
value={newPassword}
onChange={e=>onChange(e)} required 
minLength={8}
/>
</FormGroup>
<FormGroup>
<Label>Confirm Password</Label>
<Input type='password'
placeholder='enter your password'
name='reNewPassword'
value={reNewPassword}
onChange={e=>onChange(e)} required
minLength={8}
/>
</FormGroup>
<FormGroup>
  <Button className='primary'>Reset</Button>
</FormGroup>
</form>
</div>
    </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  user:state.auth.user,
  loginCheck:state.auth.loginCheck,
  resetpassword:state.auth.resetpassword
  });

export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchUser,resetPasswordConfirm})(Resetpasswordconfirm)