import React,{useEffect,useState} from 'react'
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import {useRef} from 'react';
import { CChart } from '@coreui/react-chartjs'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import 'react-circular-progressbar/dist/styles.css';
import './Viewstudents.css'
import {toast} from 'react-toastify';
import { TailSpin} from  'react-loader-spinner'
  import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {connect} from 'react-redux';
import { setId} from './../actions/auth';
import { Table } from 'react-bootstrap';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import { Card,Modal} from 'react-bootstrap';
import { useSelect } from 'react-select-search';
import {Redirect} from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Cinvoice from './Cinvoice';
import jsPDF from "jspdf";
import "jspdf-autotable";

const Viewmarks=({isAuthenticated,checkAuthenticated,setId,useremail,payment,fetchPayment,match})=> {
  const [dropdown,setDropDown]=useState("");
  const [loading,setLoading]=useState(false);
  const [notFound,setNotFound]=useState(false);
  const [successicon,setSuccessIcon]=useState(false);
  const [nosms,SetNoSms]=useState(false);
  const [emailSuccess,setEmailSuccess]=useState(false);
  const [messageSuccess,setMessageSuccess]=useState(false);
  const [message,setMessage]=useState(false);
  const [loadspinner,setLoadspinner]=useState(false);
  const [total,setTotal]=useState("");
  const [search,setSearch]=useState("");
  const [deleteid,setDeleteId]=useState("");
  const [details,setDetails]=useState([""]);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [data,setData]=useState([""]);
  const [data2,setData2]=useState([""]);
  const [customer,setCustomer]=useState([""]);
  const [data1,setData1]=useState([""]);
  const [totals,setTotals]=useState([""]);
  const [firstname,setFirstname]=useState([""]);
  const [lastname,setLastname]=useState([""]);
  const [invoices,setInvoices]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [kiswahili,setKiswahili]=useState(false);
  const [kiswahili1,setKiswahili1]=useState('');
  const [math,setMath]=useState(false);
  const [social,setSocial]=useState(false);
  const [literacy,setLiteracy]=useState(false);
  const [hygiene,setHygiene]=useState(false);
  const [environmental,setEnvironmenta]=useState(false);
  const [cre,setCre]=useState(false);
  const [home,setHome]=useState(false);
  const [creative,setCreative]=useState(false);
  const [physical,setPhysical]=useState(false);
  const [english,setEnglish]=useState(false);
  const [science,setScience]=useState(false);
  const [language,setLanguage]=useState(false); 
  const [Psychomotor_Creative,setPsychomotor_Creative]=useState(false); 
  const [braille,setBraille]=useState(false); 
  const [music,setMusic]=useState(false); 
  const [musicandmovement,setMusicandmovement]=useState(false); 
  const [numSubjects,setNumsubjects]=useState('');
  const [success,setSuccess]=useState();
  const [notreachable,setNotReachable]=useState(false);
  const [dataReceived,setDataReceived]=useState([]);
  const [labels,setLabels]=useState([]);
  const [mapData,setMapdata]=useState([]);
  const [grades,setGrades]=useState(['EE','ME','AE','BE']);
  const [heading,setHeading]=useState(['Mean','Total', 'Student Adm.', 'Name','Class','English','Kiswahili','Mathematics','Environmental','Hygiene','Literacy','CRE','Creative']);
  const [percentage, setPercentage] = useState(0);
  const tableRef = useRef(null);
  const tableRef1 = useRef(null);
  const tableRef2 = useRef(null);
  let average=[];
  var data4 = {
    labels: ['Joe', 'Jane', 'Smith', 'Mike', 'Ken', 'Kim', 'Mark'],
    datasets: [
        {
            label: 'Appealed',
            fillColor: '#382765',
            data: [1,0,0,0,0,0,0]
        },
        {
            label: 'Approved',
            fillColor: '#7BC225',
            data: [1, 100, 63, 63, 35, 193,0]
        },
        {
            label: 'Denied',
            fillColor: '#2196F3',
            data: [2, 6, 9, 1, 2, 20, 8]
        },

    ]

}

  useEffect(() => {
    setTimeout(() => {
      if (percentage < 100) {
        setPercentage(percentage + 1);
       
      }
    }, 25);
    if(percentage==100){
      
      setPercentage(0);
    }
  }, [percentage]);

  useEffect(() => {
    if(success){
      hidesuccess()
    }
  }, [success]);
  toast.configure()
  const showToast = () => toast("Operation succesful",{position: toast.POSITION.TOP_CENTER});
  useEffect(()=>{
    
     //fetch total students
     
    fetchAllStudents()
   
    
      },[]) 
      const closeModal1=()=> {
        setShowhide1(false);
        setNotFound(false)
        setMessage(false)
        setMessageSuccess(false)
        setEmailSuccess(false)
        setSuccessIcon(false)
        SetNoSms(false)
        setNotReachable(false)
        }
      
      const handleModalShowHide1=(id)=> {
        setDeleteId(id)
        setShowhide1(!showhide1);
        console.log("clicked");
      }
      
      const reLoadPage=()=>{
       
        window.location.reload();
    }
    const kiswahiliAve=async()=>{
      let result1
      const kiswa= await axios({
        method:'post',
        url:'/subjectsaverage/',
        data:{useremail:useremail,examname:match.params.id.replace(/-/g, ' '),subject:"kiswahili"}
      })
     
      setKiswahili1(kiswa.data.kiswahili__avg)
      console.log(kiswa.data.kiswahili__avg)
    }
    const fetchAllStudents=async()=>{
      let copy1=[];
      let copy=[];
      var eng;
      var kiswa;
      let data4 ;
      let enviro ;
      let hygy ;
      let lit ;
      let cr ;
      let crea ;
      let hom ;
      let soc ;
      let scie ;
      let phy ;
      let mat;
      let lang;
      let Psycho;
      let brail;
      let mus;
      let musm;
      let res = await axios.post('/examtablesearch2/',{useremail:useremail,examname:match.params.id.replace(/-/g, ' ')})
     .then(res => {
         data4 = res.data.data;
         console.log(data4)
         setData(data4);
         setFirstname(res.data.firstnames)
         setLastname(res.data.lastnames)
         setTotals(res.data.total) 
         setDetailsbkp(data4);
         setLoading(false)
        
         const result2 = data4.map(option => option.kiswahili); 
         if(result2.some(el => el !== null)){
          setKiswahili(true)
          copy1.push("kiswahili")
         
          
        }  
        const result1 = data4.map(option => option.english); 
         if(result1.some(el => el !== null)){
          setEnglish(true)
          copy1.push("english")
          
        } 
        const result3 = data4.map(option => option.mathematics); 
         if(result3.some(el => el !== null)){
          setMath(true)
          copy1.push("mathematics")
         
        } 
        const result4 = data4.map(option => option.environmental); 
         if(result4.some(el => el !== null)){
          setEnvironmenta(true)
          copy1.push("environmental")
         
          
        } 
        const result5 = data4.map(option => option.hygiene); 
         if(result5.some(el => el !== null)){
          setHygiene(true)
          copy1.push("hygiene")
         
        } 
        const result6 = data4.map(option => option.literacy); 
         if(result6.some(el => el !== null)){
          setLiteracy(true)
          copy1.push("literacy")
         
        } 
        const result7 = data4.map(option => option.cre); 
         if(result7.some(el => el !== null)){
          setCre(true)
          copy1.push("cre")
         
        } 
        const result8 = data4.map(option => option.creative); 
         if(result8.some(el => el !== null)){
          setCreative(true)
          copy1.push("creative")
         
        } 
        const result9 = data4.map(option => option.homescience); 
         if(result9.some(el => el !== null)){
          setHome(true)
          copy1.push("homescience")
         
        } 
        const result10 = data4.map(option => option.social); 
         if(result10.some(el => el !== null)){
          setSocial(true)
          copy1.push("social")
         
        } 
        const result12 = data4.map(option => option.scienceandt); 
         if(result12.some(el => el !== null)){
          setScience(true)
          copy1.push("scienceandt")
         
        } 
        const result11 = data4.map(option => option.physicalandhealth); 
        if(result11.some(el => el !== null)){
         setPhysical(true)
         copy1.push("physicalandhealth")
        
       } 
       const result13 = data4.map(option => option.language); 
        if(result13.some(el => el !== null)){
         setLanguage(true)
         copy1.push("language")
        
       }  
       const result14 = data4.map(option => option.Psychomotor_Creative); 
       if(result14.some(el => el !== null)){
        setPsychomotor_Creative(true)
        copy1.push("Psychomotor_Creative")
       
      }  
      const result15 = data4.map(option => option.braille); 
      if(result15.some(el => el !== null)){
       setBraille(true)
       copy1.push("braille")
      
     }  
     const result16 = data4.map(option => option.music); 
     if(result16.some(el => el !== null)){
      setMusic(true)
      copy1.push("music")
     
    }  

    const result17 = data4.map(option => option.musicandmovement); 
    if(result17.some(el => el !== null)){
      setMusicandmovement(true)
      copy1.push("musicandmovement")
      
    }  
     })
     .catch(err => {})
     const res3 = average.map(option => option.streamname);
     const res4 = average.map(option => option.average);
     const itemPairs = average.map(str => str.split(','));
     //setDataReceived(copy)
      setNumsubjects(copy1.length)
      setLabels(copy1)
      console.log(copy.flatMap((e) => e))
     //setDataReceived(average)
     var newvar=average
     axios({
      method:'post',
      url:'/subjectsaverage/',
      data:{useremail:useremail,examname:match.params.id.replace(/-/g, ' '),subject:copy1}
    })
    .then((res)=>{
      if(res.data){ 
        mat=res.data
       // average=[...eng, ...kiswa, ...mat] 
        //setDataReceived([...eng, ...kiswa, ...mat])
        //setDataReceived(...dataReceived,...mat)
        setDataReceived(res.data)
        console.log(average) 
        copy.push(mat)
     }
   
    })
}
   
    
const filterList = (event) => {
  //search student

  var updatedList = detailsbkp;
  updatedList = updatedList.filter(detail => detail.adm.toLowerCase().includes(search.toLowerCase()) |detail.classname.toLowerCase().includes(search.toLowerCase()) )
  setData(updatedList)
  
  };

  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const deleteData = async () => {
 handleModalShowHide1()
    const result = await axios({
      method:'delete',
      url:`/api/student/${deleteid}/`,
    })
    .then((Response)=>{
     
       setSuccess(true)
       hidesuccess();
       fetchAllStudents()
             
    })
    .catch((error) => {
     if (error.code="ERR_BAD_RESPONSE"){
      
             }
     
    })
    
    }
useEffect(()=>{
  checkAuthenticated();
  fetchPayment(useremail);

    },[]) 
    useEffect(()=>{
   // alert(dataReceived.length)
    var raw =dataReceived ,
    nameIndices = Object.create(null),
    statusHash = Object.create(null),
    data = { labels: [], datasets: [] };

    raw.forEach(function (o) {
     if (!(o.subject in nameIndices)) {
         nameIndices[o.subject] = data.labels.push(o.subject) - 1;
         data.datasets.forEach(function (a) { a.data.push(0); });
     }
     if (!statusHash[o.streamname]) {
         statusHash[o.streamname] = { label: o.streamname, backgroundColor: ['#72A0C1','#1560bd','#008E97','#1034A6','#003399','#073980', '#7CB9E8','#6CB4EE','#6CB4EE','#6699CC','#318CE7','#0a2351','#00BFFF','#034694'], data: data.labels.map(function () { return 0; }) };
         data.datasets.push(statusHash[o.streamname]);
     }
     statusHash[o.streamname].data[nameIndices[o.subject]] = o.average;
 });
 
 console.log(dataReceived);
 //setDataReceived(data)
 console.log(data)
 setMapdata(data)
},[dataReceived]) 
    
useEffect(()=>{

  if(data1.length!=0){
  //calculate total sales
  setTotal(0)
  const res=data1.reduce((prev,item)=>{
         
   return prev +(item.price*item.quantity);
 },0)
 setTotal(res)
  }
       },[data1])
       const getGrades=()=>{
axios({
  method:'post',
  url:'/subjectsaverage/',
  data:{useremail:useremail,examname:match.params.id.replace(/-/g, ' '),subject:""}
})
.then((res)=>{
console.log(res.data)
})
       }
const gradeMarks=(mark)=>{
  var grade="";
if(mark>79){
grade="EE";
}
if(mark>64 & mark < 80){
  grade="ME";
  }
  if(mark>49 & mark < 65){
    grade="AE";
    }
   
      if(mark<51){
        grade="BE";
        }

        return grade
}

const countEe=(lang)=>{
  var numEe = data.reduce(function (n, marks) {
    return n + (marks[lang] >'79');
}, 0);
return numEe;
}
const countMe=(lang)=>{
  var numEe = data.reduce(function (n, marks) {
    return n + (marks[lang] >64 & marks[lang] < 80);
}, 0);
return numEe;
}
const countAe=(lang)=>{
  var numEe = data.reduce(function (n, marks) {
    return n + (marks[lang] >49 & marks[lang] < 65);
}, 0);
return numEe;
}
const countBe=(lang)=>{
  var numEe = data.reduce(function (n, marks) {
    return n + (marks[lang] <51);
}, 0);
return numEe;
}
if(isAuthenticated=="false"){
  
  /*return <Redirect to='/login'/>*/}
  if(payment=="false"){
  
    return <Redirect to='/payment'/>}
    const value = 0.66;
    
  return (
    <div>
      <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
      <div className='path'><i className='fa fa-home'></i>/ Exams / View Exams / {match.params.id.replace(/-/g, ' ')}</div>
      <Router>
        <div className='bodysection col-lg-8'>
        <div className=' col-lg-12 theader'>< h5>Merit List</h5> </div>
       

                <div className='row mt-1'>
<FormGroup className='mr-5 ml-4'>
<Input type="text" placeholder="Search by adm or stream..."name="town" value={search} onChange={e =>{ setSearch(e.target.value);filterList()}}onKeyDown={filterList}onKeyUp={filterList} required />
        
</FormGroup>
<FormGroup className='mr-2 ml-3'>
<Label className='mt-2'>Total Students:</Label>    
</FormGroup>
<FormGroup className='mr-5'>
<Label className='mt-2'>{data.length>0?data.length:0}</Label>    
</FormGroup>
<DownloadTableExcel
                    filename={match.params.id.replace(/-/g, ' ')}
                    sheet={match.params.id.replace(/-/g, ' ')}
                    currentTableRef={tableRef.current}
                >

                   <Button className='ml-3 mb-3'> Download </Button>

                </DownloadTableExcel>

</div>
<div class="table-responsive bg-light">
<Table className=" pl-0" ref={tableRef}>
  <thead>
  {data.length==0? <div></div>:
    <tr >
   <th>Mean</th>
   <th>Total</th>
   <th>Student Adm</th>
   <th>Name</th>
   <th>Class</th>
   {language &&<th>Language</th>}
   {english &&<th>English</th>}
   {kiswahili &&<th>Kiswahili</th>}
   {Psychomotor_Creative &&<th>Psychomotor_Creative</th>}
   {braille &&<th>Braille</th>}
   {music &&<th>Music</th>}
   {musicandmovement &&<th>Music_and_Movement</th>}
   {math && <th>Mathematics</th>}
   {environmental && <th>Environmental</th>}
   {hygiene && <th>Hygiene</th>}
   {literacy && <th>Literacy</th>}
   {cre && <th>Cre</th>}
   {creative && <th>Creative</th>}
   {home && <th>Homescience</th>}
   {science && <th>Science & T</th>}
   {physical && <th>Physical & H</th>}
   {social && <th>Social </th>}
    </tr>
  }
  </thead>
 
                <tbody>     
                {data.length==0? <div>No Records found</div>:data.map((detail,idx )=> (
                  <tr key={detail.id} >
                     <td className="col-2" >{Math.trunc((totals[idx]/numSubjects)) + " "+ gradeMarks(totals[idx]/numSubjects)}</td>
                     <td className="col-2" >{ totals[idx]}</td>
                     <td class="col-2">{detail.adm}</td>
                     <td class="col-2">{firstname[idx]+" "+ lastname[idx]}</td>
                     <td class="col-2">{ detail.classname}</td>
                     {language &&<td class="col-2">{ detail.language?detail.language + " "+ gradeMarks(detail.language):'-'}</td>}
                     {english &&<td class="col-2">{ detail.english?detail.english + " "+ gradeMarks(detail.english):'-'}</td>}
                     {kiswahili &&<td class="col-2">{ detail.kiswahili?detail.kiswahili + " "+ gradeMarks(detail.kiswahili):'-'}</td>}
                     {Psychomotor_Creative &&<td class="col-2">{ detail.Psychomotor_Creative?detail.Psychomotor_Creative + " "+ gradeMarks(detail.Psychomotor_Creative):'-'}</td>}
                     {braille &&<td class="col-2">{ detail.braille?detail.braille + " "+ gradeMarks(detail.braille):'-'}</td>}
                     {music &&<td class="col-2">{ detail.music?detail.music + " "+ gradeMarks(detail.music):'-'}</td>}
                     {musicandmovement &&<td class="col-2">{ detail.musicandmovement?detail.musicandmovement + " "+ gradeMarks(detail.musicandmovement):'-'}</td>}
                     
                     {math &&<td class="col-2">{ detail.mathematics?detail.mathematics + " "+ gradeMarks(detail.mathematics):'-'}</td>}
                     {environmental &&<td class="col-2">{ detail.environmental?detail.environmental + " "+ gradeMarks(detail.environmental):'-'}</td>}
                     {hygiene &&<td class="col-2">{ detail.hygiene?detail.hygiene + " "+ gradeMarks(detail.hygiene):'-'}</td>}
                     {literacy && <td class="col-2">{ detail.literacy?detail.literacy + " "+ gradeMarks(detail.literacy):'-'}</td>}
                     {cre && <td class="col-2">{ detail.cre?detail.cre + " "+ gradeMarks(detail.cre):'-'}</td>}
                     {creative &&<td class="col-2">{ detail.creative?detail.creative + " "+ gradeMarks(detail.creative):'-'}</td>}
                     {home &&<td class="col-2">{ detail.homescience?detail.homescience + " "+ gradeMarks(detail.homescience):'-'}</td>}
                     {science &&<td class="col-2">{ detail.scienceandt?detail.scienceandt + " "+ gradeMarks(detail.scienceandt):'-'}</td>}
                     {physical &&<td class="col-2">{ detail.physicalandhealth?detail.physicalandhealth + " "+ gradeMarks(detail.physicalandhealth):'-'}</td>}
                     {social &&<td class="col-2">{ detail.social?detail.social + " "+ gradeMarks(detail.social):'-'}</td>}
                   
                   </tr>
             ))}
    </tbody>
    
     
           </Table> 
           
           </div>
          {data.length>0 && <div className=' '>
            <h4 className='mt-1 ml-2'>Subjects Analysis</h4>
            <Table className=" pl-0 bg-light table-responsive" ref={tableRef1}>
  <thead>
  {data.length==0? <div></div>:
    <tr >
   
   <th>Grade</th>
   {language &&<th>Language</th>}
   {Psychomotor_Creative &&<th>Psychomotor_Creative</th>}
   {braille &&<th>Braille</th>}
   {music &&<th>Music</th>}
   {musicandmovement &&<th>Music_and_Movement</th>}
   {english &&<th>English</th>}
   {kiswahili &&<th>Kiswahili</th>}
   {math && <th>Mathematics</th>}
   {environmental && <th>Environmental</th>}
   {hygiene && <th>Hygiene</th>}
   {literacy && <th>Literacy</th>}
   {cre && <th>Cre</th>}
   {creative && <th>Creative</th>}
   {home && <th>Homescience</th>}
   {science && <th>Science & T</th>}
   {physical && <th>Physical & H</th>}
   {social && <th>Social </th>}
    </tr>
  }
  </thead>
 
                <tbody>     
                
                  <tr>
                     {<td className="col-2" >EE</td>}
                     {language &&<td className="col-2" >{countEe('language')}</td>}
                     {Psychomotor_Creative &&<td className="col-2" >{countEe('Psychomotor_Creative')}</td>}
                     {braille &&<td className="col-2" >{countEe('braille')}</td>}
                     {music &&<td className="col-2" >{countEe('music')}</td>}
                     {musicandmovement &&<td className="col-2" >{countEe('musicandmovement')}</td>}
                     {english &&<td className="col-2" >{countEe('english')}</td>}
                     {kiswahili &&<td className="col-2" >{countEe('kiswahili')}</td>}
                     {math &&<td className="col-2" >{countEe('mathematics')}</td>}
                     {environmental &&<td className="col-2" >{countEe('environmental')}</td>}
                     {hygiene &&<td className="col-2" >{countEe('hygiene')}</td>}
                     {literacy &&<td className="col-2" >{countEe('literacy')}</td>}
                     {cre &&<td className="col-2" >{countEe('cre')}</td>}
                     {creative &&<td className="col-2" >{countEe('creative')}</td>}
                     {home &&<td className="col-2" >{countEe('homescience')}</td>}
                     {science &&<td className="col-2" >{countEe('scienceandt')}</td>}
                     {physical &&<td className="col-2" >{countEe('physicalandhealth')}</td>}
                     {social &&<td className="col-2" >{countEe('social')}</td>}
                   </tr>
                   <tr>
                     {<td className="col-2" >ME</td>}
                     {language &&<td className="col-2" >{countMe('language')}</td>}
                     {Psychomotor_Creative &&<td className="col-2" >{countMe('Psychomotor_Creative')}</td>}
                     {braille &&<td className="col-2" >{countMe('braille')}</td>}
                     {music &&<td className="col-2" >{countMe('music')}</td>}
                     {musicandmovement &&<td className="col-2" >{countMe('musicandmovement')}</td>}
                     {english &&<td className="col-2" >{countMe('english')}</td>}
                     {kiswahili &&<td className="col-2" >{countMe('kiswahili')}</td>}
                     {math &&<td className="col-2" >{countMe('mathematics')}</td>}
                     {environmental &&<td className="col-2" >{countMe('environmental')}</td>}
                     {hygiene &&<td className="col-2" >{countMe('hygiene')}</td>}
                     {literacy &&<td className="col-2" >{countMe('literacy')}</td>}
                     {cre &&<td className="col-2" >{countMe('cre')}</td>}
                     {creative &&<td className="col-2" >{countMe('creative')}</td>}
                     {home &&<td className="col-2" >{countMe('homescience')}</td>}
                     {science &&<td className="col-2" >{countMe('scienceandt')}</td>}
                     {physical &&<td className="col-2" >{countMe('physicalandhealth')}</td>}
                     {social &&<td className="col-2" >{countMe('social')}</td>}
                   </tr>
                   <tr>
                     {<td className="col-2" >AE</td>}
                     {language &&<td className="col-2" >{countAe('language')}</td>}
                     {Psychomotor_Creative &&<td className="col-2" >{countAe('Psychomotor_Creative')}</td>}
                     {braille &&<td className="col-2" >{countAe('braille')}</td>}
                     {music &&<td className="col-2" >{countAe('music')}</td>}
                     {musicandmovement &&<td className="col-2" >{countAe('musicandmovement')}</td>}
                     {english &&<td className="col-2" >{countAe('english')}</td>}
                     {kiswahili &&<td className="col-2" >{countAe('kiswahili')}</td>}
                     {math &&<td className="col-2" >{countAe('mathematics')}</td>}
                     {environmental &&<td className="col-2" >{countAe('environmental')}</td>}
                     {hygiene &&<td className="col-2" >{countAe('hygiene')}</td>}
                     {literacy &&<td className="col-2" >{countAe('literacy')}</td>}
                     {cre &&<td className="col-2" >{countAe('cre')}</td>}
                     {creative &&<td className="col-2" >{countAe('creative')}</td>}
                     {home &&<td className="col-2" >{countAe('homescience')}</td>}
                     {science &&<td className="col-2" >{countAe('scienceandt')}</td>}
                     {physical &&<td className="col-2" >{countAe('physicalandhealth')}</td>}
                     {social &&<td className="col-2" >{countAe('social')}</td>}
                   </tr> 
                   <tr>
                     {<td className="col-2" >BE</td>}
                     {language &&<td className="col-2" >{countBe('language')}</td>}
                     {Psychomotor_Creative &&<td className="col-2" >{countBe('Psychomotor_Creative')}</td>}
                     {braille &&<td className="col-2" >{countBe('braille')}</td>}
                     {music &&<td className="col-2" >{countBe('music')}</td>}
                     {musicandmovement &&<td className="col-2" >{countBe('musicandmovement')}</td>}
                     {english &&<td className="col-2" >{countBe('english')}</td>}
                     {kiswahili &&<td className="col-2" >{countBe('kiswahili')}</td>}
                     {math &&<td className="col-2" >{countBe('mathematics')}</td>}
                     {environmental &&<td className="col-2" >{countBe('environmental')}</td>}
                     {hygiene &&<td className="col-2" >{countBe('hygiene')}</td>}
                     {literacy &&<td className="col-2" >{countBe('literacy')}</td>}
                     {cre &&<td className="col-2" >{countBe('cre')}</td>}
                     {creative &&<td className="col-2" >{countBe('creative')}</td>}
                     {home &&<td className="col-2" >{countBe('homescience')}</td>}
                     {science &&<td className="col-2" >{countBe('scienceandt')}</td>}
                     {physical &&<td className="col-2" >{countBe('physicalandhealth')}</td>}
                     {social &&<td className="col-2" >{countBe('social')}</td>}
                   </tr>
    </tbody>
    
     
           </Table> 
           </div>}
           <h4 className=' ml-1'>Class Streams subject mean</h4>
           <div className='bg-light'>
           <CChart
   
   width="200"
   height="100"
 type="bar"
 data={mapData}
 
/></div>
           </div>

           <Switch>
           <Route path='/cinvoice' component={Cinvoice}/>
          </Switch>
           </Router>
           <div className='col-lg-3 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Student?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting a Student Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={deleteData}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,setId,fetchPayment})(Viewmarks)