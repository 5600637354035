
import './Footer.css';
import { NavLink,BrowserRouter as Router,Route,Switch,Link,Redirect} from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import {connect} from 'react-redux';
import { logout } from './../actions/auth';
import React,{useEffect,useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faBuilding,faTimes,faBars,faSignOut,faUser,faUserAlt,faUserCircle} from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
function Adminnav({click,handleClick,adminMenuClick1,adminMenu,logout,useremail,fetchedUser,isAuthenticated,user1}) {
    const [names,setName]=useState("");
    useEffect(()=>{   
      
      },[]) 
      const reLoadPage=()=>{
       
        window.location.reload();
    }
    if(!isAuthenticated){
  
       /* <Router>
       return <Redirect to='/login'/>
    </Router>*/}
    return (
        <nav className="footer">
            <Router>
        <div className="nav_icon" onClick={handleClick}>
       
        {click? <FontAwesomeIcon icon={faTimes}className="mobilebtn" />:<FontAwesomeIcon icon={faBars}className="mobilebtn" />}
        </div>
        
        <div className="navbar_rightnew"onClick={adminMenuClick1} >
       
            SCHOOLSUITE Version 1.5 | Copyright  &copy; 2024 All Rights Reserved
        
      
       
        </div>
        <div className={adminMenu ?'adminmenu ':'adminactive'}>
           <ul>
               <li className="newbtn"onClick={reLoadPage}><Link exact to={`/account/`} ><FontAwesomeIcon icon={faUserCircle}className="mr-2" />Account</Link></li>
               <li onClick={e =>{logout()}} className="newbtn"><FontAwesomeIcon icon={faSignOut}className="mr-2" />Logout</li>
           </ul>
       </div>
       </Router>
        </nav>
    )
}
const mapStateToProps=state=>({
    useremail:state.auth.useremail,
    user1:state.auth.user1,
    isAuthenticated:state.auth.isAuthenticated,
    fetchedUser:state.auth.fetchedUser
    });

export default connect(mapStateToProps,{logout})(Adminnav)
