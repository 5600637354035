import React, {useState,useEffect,useRef} from 'react'
import {connect} from 'react-redux';
import "./Login.css"
import axios from "axios";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
 
} from "reactstrap";
import  { encrypt , decrypt } from 'react-crypt-gsm';
import { checkAuthenticated,load_user,login,fetchUser} from './../actions/auth';
import {Link, Redirect} from 'react-router-dom';
import validator from 'validator'
function Login({login, isAuthenticated,checkAuthenticated,load_user,user,loginCheck,fetchUser }) {
  const [formData,setformData]=useState({
    email:'',
    password:''
       });
       const [error,setError]=useState()
       const [clicked,setClicked]=useState(null)
       const [emailError,setEmailError]=useState({})
       const [passwordError,setPasswordError]=useState({})
       const {email,password}= formData;
       const loginuser=async(e)=>{
        e.preventDefault();
        const result = await axios({
          method:'post',
          url:'/login/',
          data:{username:email,password:password},
          })
          .then((Response)=>{
         
          })
          .catch((error) => {
         
          })
       }
       useEffect(()=>{
       // checkAuthenticated();
        setClicked(null)
          },[]) 
          
          useEffect(()=>{   
            if(email && password && !loginCheck && !email.trim().length==0 && !password.trim().length==0){
             
              setClicked(true)}
          },[loginCheck,clicked]) 
          useEffect(() => {
            return () => {
                // Anything in here is fired on component unmount.
            }
        }, [])
      
    
       const onChange=(e)=>setformData({...formData,[e.target.name]:e.target.value});
       const onSubmit=e=>{
           e.preventDefault();
           let encryptedStr
           const isValid=formValidation();
           if(isValid){
            encryptedStr = encrypt(password);
           
           login(email,encryptedStr.content);
          // fetchUser(email)
           //checkAuthenticated();
           //loginuser()
           setError(isAuthenticated);
     
          }
          
           }

           
      const formValidation=()=>{
    const emailErr={};
    const passwordErr={};
    let isValid=true;
    if(!email){
     
      emailErr.emailShort="Username is required";
      isValid=false;
    }
    
    if(!password){
      passwordErr.passwordShort="Password is required";
      isValid=false;
    }
    setEmailError(emailErr);
    setPasswordError(passwordErr);
    return isValid
      }
  return (
    <div>
        <div className='loginscreen col-lg-4 '>
<div className='title mt-3  '><h4><b>User Login</b></h4></div>
{clicked  && <h6 className='error1' style={{color:"red"}} >wrong Username or password</h6>}
<form onSubmit={e=>onSubmit(e)}> 
        <FormGroup>
<Label>Username</Label>
<Input type="text" placeholder="Enter Username"name="email"  value={email}
onChange={e=>onChange(e)} />
</FormGroup>
{Object.keys(emailError).map((key)=>{
  return <div style={{color:"red"}}>{emailError[key]}</div>
})}
<FormGroup>
<Label>Password</Label>
<Input type='password'
placeholder='enter your password'
name='password'
value={password}
onChange={e=>onChange(e)}  />
</FormGroup>
{Object.keys(passwordError).map((key)=>{
  return <div style={{color:"red"}}>{passwordError[key]}</div>
})}
<FormGroup>
  <Button className='primary'>Login</Button>
</FormGroup>
</form>
<div className=' mt-3'><Link exact to={'/resetpassword/'} >Forgot Password?</Link></div>
</div>
    </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  user:state.auth.user,
  loginCheck:state.auth.loginCheck
  });

export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchUser})(Login)