import React,{useEffect,useState} from 'react'
import axios from "axios";
import { Table } from 'react-bootstrap';
import { Card,Modal} from 'react-bootstrap';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
function Customers({isAuthenticated,checkAuthenticated,useremail,payment,fetchPayment}) {
  const [data,setData]=useState([""]);
  const [names,setName]=useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [id,setId]=useState("");
  const [edit,setEdit]=useState(false);
  const [showhide,setShowhide]=useState(false);
  const [heading,setHeading]=useState(['Names', 'Email', 'Mobile','Town','Action']);
  useEffect(()=>{
    let data1 ;

    axios({
      method:'post',
      url:'/totalcustomers/',
      data:{useremail:useremail},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
      console.log(data1)
    })
    .catch(err => {})
    checkAuthenticated();
    fetchPayment(useremail)
    
      if(!payment){
        return <Redirect to='/payment'/>}
      },[]) 
      const handleModalShowHide=()=> {
        setShowhide(!showhide);
    }
    const closeModal=()=> {
      setShowhide(false);
  }
  const refreshData=()=>{
    let data1 ;

    
    axios({
      method:'post',
      url:'/totalcustomers/',
      data:{useremail:useremail},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
       
    })
    .catch(err => {})
    
  }
  const handledate = () => {
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return date;
  }
  const handleSubmit = (e) => {
    if(!names|!email|!mobile|!town){
     
       }else
    if(id){
      e.preventDefault();
    
      axios({
        method:'put',
        url:`/api/customer/${id}/`,
        data:{names:names.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),email:email.toLowerCase(),mobile,town:town.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),handledate},
      }).then((Response)=>{
        setEdit(false);
        setId('');
        //clear fields
    cleaFields();
      })
      setUpdateSuccess(true)
    }
    else{
     
   axios({
     method:'post',
     url:'/api/customer/',
     data:{names:names.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),email:email.toLowerCase(),mobile,town:town.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),owner:useremail,handledate},
   }).then((Response)=>{
    //clear fields
    cleaFields();
   })
   
   setSaveSuccess(true)
  }
  };
  const editItem = (detail) => {
    setId(detail.id);
    setEdit(true);
    setShowhide(true);
    setName(detail.names);
    setEmail(detail.email);
    setMobile(detail.mobile);
    setTown(detail.town);

  };
  const handleDelete = (item) => {
    if(window.confirm('Are You Sure You want to delete this record?')){
    console.log(item);
    axios({
      method:'delete',
      url:`/api/customer/${item.id}/`,
    }).then((Response)=>{
      
      alert('Record deleted Succesfully')
      refreshData();
    })
  }
  };
  const cleaFields=()=>{
    setName('');
    setEmail('');
    setMobile('');
    setTown('');
    refreshData();
  }
  useEffect(()=>{
    checkAuthenticated();
    
      },[]) 
      useEffect(()=>{
     
      },[isAuthenticated]) 
      
        if(payment=="false"){
          return <Redirect to='/payment'/>}
  return (
    <div>
        <div className='bodysection col-lg-6'>
        <Button  onClick={handleModalShowHide} className="m-2 btn-primary">
           + Customer
       </Button>
       <Modal show={showhide}>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title>New Customer details</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
<FormGroup>
<Label>Names</Label>
<Input type="text" placeholder="Enter name"name="title" onChange={e => setName(e.target.value)} value={names} required/>
</FormGroup>

<FormGroup>
<Label>Email</Label>
<Input type="text" placeholder="Enter Email"value={email} onChange={e => setEmail(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup>
<Label>Mobile.</Label>
<Input type="number" placeholder="Enter mobile"name="author" value={mobile} onChange={e => setMobile(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Town.</Label>
<Input type="text" placeholder="Enter Town"name="town" value={town} onChange={e => setTown(e.target.value)} required />
</FormGroup>
<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       
       required
     
     />
</FormGroup>
   
           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
<       h4>Customers List</h4>
<Table responsive className="">
  <thead>
    <tr >
    {heading.map(head => <th>{head}</th>)}
    </tr>
  </thead>
 
                <tbody>     
                {data.length==0? <div>No Records found</div>:data.map(detail => (
                  <tr key={detail.id} >
                     <td className="col-3" >{detail.names}</td>
                     <td class="col-2">{detail.email}</td>
                     <td class="col-2">{detail.mobile}</td>
                     <td class="col-1">{detail.town}</td>
                     <td class="col-1"><span className='border'><i class="fa fa-edit btn1" onClick={() => editItem(detail)}></i></span ><span className="border"><i class="fa fa-trash btn1" onClick={() => handleDelete(detail)}></i></span></td>
                   </tr>
             ))}
    </tbody>
    
     
           </Table> 
           </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchPayment})(Customers)