import React,{useEffect,useState} from 'react'
import './Settings.css'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import axios from "axios";
import { Table } from 'react-bootstrap';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { Card,Modal} from 'react-bootstrap';
import  { encrypt , decrypt } from 'react-crypt-gsm';
import { checkAuthenticated,load_user,login,fetchPayment,register_user } from './../actions/auth';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
function Settings({isAuthenticated,checkAuthenticated,useremail,payment,fetchPayment,register_user,user1,username1,email1}) {
  const [town,setTown]=useState("");
  const [data,setData]=useState("");
  const [names,setName]=useState("");
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [saveSuccess1,setSaveSuccess1]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [building,setBuilding]=useState("");
  const [email2,setEmail]=useState("");
  const [email,setEmail1]=useState("");
  const [email3,setEmail3]=useState("");
  const [username,setUsername]=useState("");
  const [password,setPassword]=useState("");
  const [repassword,setRepassword]=useState("");
  const [mobile,setMobile]=useState("");
  const [showhide,setShowhide]=useState(false);
  const [check,setCheck]=useState(false);
  const [showhide1,setShowhide1]=useState(false);
  const [id,setId]=useState("");
  const [id1,setId1]=useState(false);
  const [id2,setId2]=useState('');
  const [edit,setEdit]=useState(false);
  const [till,setTill]=useState('');
  const [businessid,setBusinessId]=useState('');
  const [authid,setAuthId]=useState('');
  const [schoolid,setSchoolid]=useState('');
  const [isadmin,setIsadmin]=useState('');
  const [isstaff,setIstaff]=useState('');
  const [isstudent,setIsstudent]=useState('');
  const [isparent,setIsparent]=useState('');
  const [isprimary,setIsprimary]=useState('');
  const [owner,setOwner]=useState('');
  const [userdetails,setUserdetails]=useState(['']);
  const [heading,setHeading]=useState(['username' ,'lastname','schoolid','Action']);
  const closeModal=()=> {
    setShowhide(false);
}
const closeModal1=()=> {
  setShowhide1(false);
}
const handledate = () => {
  var today = new Date(),
  date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  return date;
}
useEffect(()=>{
//fetch details
checkAuthenticated();
fetchPayment(useremail)
    let data1 ;

    axios({
      method:'post',
      url:'/totalcompany/',
      data:{useremail:useremail},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
         //set fields
    setName(data1[0].names)
    setEmail3(data1[0].email)
    setMobile(data1[0].mobile)
    setTown(data1[0].town)
    setBuilding(data1[0].building)
   // setId1(data1[0].id && true)
    setId2(data1[0].id)
    setTill(data1[0].till)
    })
    .catch(err => {})

    //fetch user details
    let data2 ;

    axios({
      method:'get',
      url:'/api/systemuser/',
   
    })
    .then(res => {
        data2 = res.data;
        setUserdetails(data2);
       console.log(data2)
    })
    .catch(err => {})
    if(names){
setEdit(true)
    }
},[])
const handleModalShowHide=()=> {
  setShowhide(!showhide);
}
const handleModalShowHide1=()=> {
  setShowhide1(!showhide1);
}
const cleaFields=()=>{
  setName('');
  setEmail('');
  setMobile('');
  setTown('');
  
}
const handleSubmit1 = (e) => {
  e.preventDefault();
  if(check){
    
    axios({
      method:'put',
      url:`/api/company/${id2}/`,
      data:{names,building,mobile,email:email3,town,till,businessid,authid,handledate},
    }).then((Response)=>{
      setEdit(false);
      setId1('');
      //clear fields
  cleaFields();
    })
    setUpdateSuccess(true)
  }
  else{

    const encryptedStr = encrypt(password);
  
 axios({
   method:'post',
   url:'/api/systemuser/',
   data:{username,password:encryptedStr.content,lastname:names,email,phone:mobile,schoolid,isprimary,isadmin,isstaff,isstudent,isparent},
 }).then((Response)=>{
  //clear fields
  cleaFields();
 })
 setSaveSuccess(true)
}
};

const handleSubmit= (e) => {
  e.preventDefault()
  if (id2) {
    axios({
      method:'put',
      url:`/api/company/${id2}/`,
      data:{names,mobile,town,building,email:email3,till,authid,businessid,owner},
    }).then((Response)=>{
     //clear fields
     cleaFields();
    })
  }else{
    axios({
      method:'post',
      url:'/api/company/',
      data:{names,mobile,town,building,email:email3,till,authid,businessid,owner:owner},
    }).then((Response)=>{
     //clear fields
     cleaFields();
    })
  }
 
 setSaveSuccess1(true)

};
const checkEdit=()=>{
if(edit){
     //set fields
     setName(names)
     setEmail(useremail)
     setMobile(mobile)
     setTown(town)
     setBuilding(building)
}
}
const deleteData = async (userid) => {
  
     const result = await axios({
       method:'delete',
       url:`/api/systemuser/${userid}/`,
     })
     .then((Response)=>{
     
              
     })
     .catch((error) => {
      if (error.code="ERR_BAD_RESPONSE"){
       
              }
      
     })
    
     }
if(isAuthenticated=="false"){
  /*return <Redirect to='/login'/>*/}
  if(payment=="false"){
   /* return <Redirect to='/payment'/>*/}
  return (
    <div>
      <div className='bodysection col-lg-4 p-3'>
      {useremail=="6"&& <Button  onClick={e =>{handleModalShowHide1()}} className="m-2 btn-primary">
           + User
       </Button>}
       {useremail=="6"&& <Button  onClick={e =>{handleModalShowHide()}} className="m-2 btn-primary">
           + School Details
       </Button>}
       {saveSuccess && <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
       <Modal show={showhide}>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title>Account details</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           <Form  >
<FormGroup>
<Label> Last Name</Label>
<Input type="text" placeholder="Enter name"name="title" onChange={e => setName(e.target.value)} value={names} required/>
</FormGroup>
<FormGroup>
<Label>Mobile.</Label>
<Input type="number" placeholder="Enter mobile"name="author" value={mobile} onChange={e => setMobile(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Email.</Label>
<Input type="text" placeholder="Enter Email"name="building" value={email3} onChange={e => setEmail3(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Town.</Label>
<Input type="text" placeholder="Enter Town"name="town" value={town} onChange={e => setTown(e.target.value)} required />
</FormGroup>
<FormGroup>

<Label>Building.</Label>
<Input type="text" placeholder="Enter building"name="building" value={building} onChange={e => setBuilding(e.target.value)} required />
</FormGroup>
<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       
       required
     
     />
</FormGroup>
<FormGroup>
<Label>Till No..</Label>
<Input type="text" placeholder="Enter Till"name="till" value={till} onChange={e => setTill(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Auth Id.</Label>
<Input type="text" placeholder="Enter auth"name="auth" value={authid} onChange={e => setAuthId(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Business Id.</Label>
<Input type="text" placeholder="Enter business id"name="businessid" value={businessid} onChange={e => setBusinessId(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Owner.</Label>
<Input type="text" placeholder="Enter owner"name="owner" value={owner} onChange={e => setOwner(e.target.value)} required />
</FormGroup>
           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>

       <Modal show={showhide1}>
           <Modal.Header closeButton onClick={closeModal1}>
           <Modal.Title>New User</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           <Form  >

<FormGroup>
<Label>Username.</Label>
<Input type="text" placeholder="Enter username"name="username" value={username} onChange={e => setUsername(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Password.</Label>
<Input type="text" placeholder="Enter password"name="password" value={password} onChange={e => setPassword(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Reenter Password.</Label>
<Input type="text" placeholder="Enter password"name="password" value={repassword} onChange={e => setRepassword(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Name</Label>
<Input type="text" placeholder="Enter Name"name="email" onChange={e => setName(e.target.value)} value={names} required/>
</FormGroup>
<FormGroup>
<Label>Email</Label>
<Input type="email" placeholder="Enter email"name="email" onChange={e => setEmail1(e.target.value)} value={email} required/>
</FormGroup>
<FormGroup>
<Label>Phone</Label>
<Input type="text" placeholder="Enter Phone"name="email" onChange={e => setMobile(e.target.value)} value={mobile} required/>
</FormGroup>
<FormGroup>
<Label>School id</Label>
<Input type="text" placeholder="Enter school id"name="email" onChange={e => setSchoolid(e.target.value)} value={schoolid} required/>
</FormGroup>
<FormGroup>
<Label>isprimary</Label>
<Input type="text" placeholder="Enter isprimary"name="email" onChange={e => setIsprimary(e.target.value)} value={isprimary} required/>
</FormGroup>
<FormGroup>
<Label>isadmin</Label>
<Input type="text" placeholder="Enter isadmin"name="email" onChange={e => setIsadmin(e.target.value)} value={isadmin} required/>
</FormGroup>
<FormGroup>
<Label>isstaff</Label>
<Input type="text" placeholder="Enter istaff"name="email" onChange={e => setIstaff(e.target.value)} value={isstaff} required/>
</FormGroup>
<FormGroup>
<Label>isstudent</Label>
<Input type="text" placeholder="Enter isstudent"name="email" onChange={e => setIsstudent(e.target.value)} value={isstudent} required/>
</FormGroup>
<FormGroup>
<Label>isparent</Label>
<Input type="text" placeholder="Enter isparent"name="email" onChange={e => setIsparent(e.target.value)} value={isparent} required/>
</FormGroup>
<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       
       required
     
     />
</FormGroup>

           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit1(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
        <h4 className=''>Account Details</h4>
        <div className='row'>
          <div className='col-lg-6 mb-5 '>
         
            <span className='marginleft pb-5'>Name:</span> {user1?user1:"Not set"}<br/><br/>
          <span className='marginleft pt-5 mr-2'>Username:</span>{username1?username1:"Not set"}
          <br/><br/>
          <span className='marginleft mt-5 mr-2'>Email.:</span>{email1?email1:"Not set"}
          
          </div>
          
        </div>
        <div class="table-responsive ">
{useremail=="6" &&<Table className=" pl-0">
  <thead>
    <tr >
    {heading.map(head => <th>{head}</th>)}
    </tr>
  </thead>
 
                <tbody>     
                {userdetails.length==0? <div>No Records found</div>:userdetails.map(detail => (
                  <tr key={detail.id} >
                
                     <td className="col-3" ><Link exact to={`/viewmarks/${detail.examname && detail.examname.replace(/\s/g,"-")}`} >{ detail.username}</Link></td>
                     <td className="col-2" >{ detail.lastname}</td>
                     <td className="col-2" >{ detail.schoolid}</td>
                     <td class="col-2"><div className='row '><div onClick={e =>{ setId(detail.customerid);}} className="border"></div><span className='border'><i class="fa fa-trash btn1 border1" onClick={e =>{ deleteData(detail.id)}}></i></span></div></td>
                   </tr>
             ))}
    </tbody>
    
     
           </Table> }
           
           </div>
      </div>
      
    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  payment:state.auth.payment,
  user1:state.auth.user1,
  username1:state.auth.username1,
  email1:state.auth.email1
})

export default connect(mapStateToProps,{checkAuthenticated,load_user,fetchPayment,register_user})(Settings)