import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import { ToastProvider, useToasts } from 'react-toast-notifications';
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 import {Oval } from 'react-loader-spinner'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useKeyboardShortcut from 'use-keyboard-shortcut'
import Select from 'react-dropdown-select'
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation,useParams } from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addclass.css'
import Select1 from 'react-select'
const Selectguardian=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,id,useremail,payment,match})=> {
 
  const [showhide,setShowhide]=useState(false);
  const [customerFetch,setCustomerFetch]=useState(false);
  const [amountvisible,setAmountVisible]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [showhide1,setShowhide1]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [names, setName] = useState("");
  const [filteredinvoice, setFilteredInvoice] = useState("");
  const [name, setNames] = useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [id1,setId]=useState("");
  const [inumber,setInumber]=useState("hello");
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(true);
  const [editbtn1,setEditbtn1]=useState(false);
  const [savebtn1,setSavebtn1]=useState(false);
  const [wait,setWait]=useState(false);
  const [wait1,setWait1]=useState(false);
  const [hide,setHide]=useState(true);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [dropopen,setDropOpen]=useState(false);
  const [dropdown,setDropDown]=useState("Unpaid");
  const [issuedate,setIssueDate]=useState("");
  const [duedate,setDueDate]=useState("");
  const [product,setProduct]=useState("");
  const [price,setPrice]=useState("");
  const [subtotal,setSubTotal]=useState("");
  const [search,setSearch]=useState("");
  const [searchcustomer,setSearchCustomer]=useState("");
  const [quantity,setQuantity]=useState("");
  const [index,setIndex]=useState("");
  const [dateentered,setDateEntered]=useState("");
  const [details,setDetails]=useState(['']);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [details2bkp,setDetails2bkp]=useState(['']);
  const [details1,setDetails1]=useState(['']);
  const [details2,setDetails2]=useState(['']);
  const [details3,setDetails3]=useState(['']);
  const [products,setProducts]=useState(['']);
  const [irecords,setIRecords]=useState([]);
  const [grandtotal,setGrandTotal]=useState('');
  const [grandtotal1,setGrandTotal1]=useState('');
  const [totalPartialAmount,setTotalPartialAmount]=useState('');
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const [musyoki,setMusyoki]=useState('musyoki');
  const [inputList1,setInputList1]=useState([]);
  const [heading,setHeading]=useState(['Names', 'Mobile','Action']);
  const [inputList, setInputList] = useState([]);
  const [inputList2, setInputList2] = useState([]);
  const [payments, setPayments] = useState(['']);
  const [data2,setData2]=useState([""]);
  const inputRef = useRef(null);
  const [notFound,setNotFound]=useState(false);
  const [notInternet,setNoInternet]=useState(false);
  const [classnumeral,setCnumeral]=useState("");
  const [classnumeralError,setCnumeralError]=useState(false);
  const [classstream,setCsream]=useState("");
  const [classstreamError,setCsreamError]=useState(false);
  const [validated,setValidated]=useState(false);
  const [success,setSuccess]=useState(false);
  const [result,setResult]=useState(false);
  const [author,setAuthor]=useState("");
  const [authorError,setAuthorError]=useState(false);
  const [title,setTitle]=useState("");
  const [titleError,setTitleError]=useState(false);
  const [bookid,setBid]=useState("");
  const [bookidError,setBidError]=useState(false);
  const [subject,setSubject]=useState("");
  const [inputStyle, setInputStyle] = useState( { })
const [inputStyle1, setInputStyle1] = useState( { })
const [selectedOptions, setSelectedOptions] = useState([])
const [selectedOptions1, setSelectedOptions1] = useState([])
const [studentrecord,setSrecord]=useState([""]);
const [bookrecord,setBrecord]=useState([""]);
const [dateissued,setDissued]=useState("");
const [parentrecord,setParentrecord]=useState([""]);
const [recepients,setRecepients]=useState([""]);
const [dateissuedError,setDissuedError]=useState(false);
const [returnbyError,setRbyError]=useState(false);
const [admError,setSAdmError]=useState(false);
const [sending,setSending]=useState(false);
const [returnby,setRby]=useState("");
const [message,setMessage]=useState("");
const [responseData,setResponseData]=useState([""]);
const options=[
  { value: 'Telecomms', label: 'Telecomms' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Manufacturing', label: 'Manufacturing' },
]
const [newUser,getUser]=useState({
  name: "",
  businessSector:"",
 
})
const onChangeHandler = (change) => {
  getUser({
    ...newUser,
    businessSector: change
  });
};
const [valueState, setValueState] = useState('');

const filterOptions = [
  { value: 'first_name', label: 'First Name', },
  { value: 'last_name', label: 'Last Name' },
  { value: 'email', label: 'Email' },
 ]
  const handleSelectChange = (value) => {
    setValueState(value);
    if(valueState.length>0){
      console.log(valueState[0].label.split(',').shift())
    }
   
  }
  const renderList=()=>{
    return (responseData.map(data =>({label:data.phone+',' + data.parent_last_name +' '+'Student:'+data.studentadm,value:data.studentadm})))
  }
useEffect(()=>{
 //fetch student
 let data5 ;
 axios({
  method:'post',
  url:'/totalstudents/',
  data:{useremail:useremail},
})
.then(res => {
    data5 = res.data;
    //setParentrecord(data5);
    setResponseData(data5)
    
})
.catch(err => {})
 //fetch book id
 let data4 ;
 axios({
  method:'post',
  url:'/totalbooksavailable/',
  data:{useremail:useremail},
})
.then(res => {
    data4 = res.data;
    setBrecord(data4);
    
})
.catch(err => {})

},[]) 
const showToastMessage = () => {
  toast.success('Message sent succesfully', {
      position: toast.POSITION.TOP_CENTER
  });
};
const showToastMessage1 = () => {
  toast.error('You are not connected to the internet', {
      position: toast.POSITION.TOP_CENTER
  });
};
const showToastMessage2 = () => {
  toast.error('You dont have enough Messages to send this message.', {
      position: toast.POSITION.TOP_CENTER
  });
};
const showToastMessage3 = () => {

  toast.error('You dont have permission to perform this action', {
      position: toast.POSITION.TOP_CENTER
  });
};  
          useEffect(()=>{
            //save customer
        if(validated){
        
        //save student
        if(edit)
        {
        
        const editData = async () => {
        
        const result = await axios({
        method:'put',
        url:`http://localhost:8000/api/book/${match.params.id}/`,
        data:{classnumeral,classstream},
        })
        .then((Response)=>{
        if (Response.data){
        setSaveSuccess(true)
        setWait(false)
        
        //setSuccess(true)
        setValidated(false)
       // hidesuccess();
        
             }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
        setShowhide2(true)
        setWait(false)
        setNoInternet(true)
             }
        
        })
        
        }
        editData()
        }else
        {
        
        
        }
        
        }
            
              },[validated]) 
              const saveData = async (e) => {
                if(useremail=="4")
                {
                showToastMessage3();
                }
                else{
                if(message.length>3 && valueState.length>0 ){
                e.preventDefault()
                setSending(true)
                let copy1=[];
                if(valueState.length>0){
                  for (var i = 0; i < valueState.length; i++) {
                    copy1.push(valueState[i].label.split(',').shift())
                  }}
                  console.log(copy1)
                  const result = await axios({
                    method:'post',
                    url:'/smssend1/',
                    data:{recepients:copy1,message:message,useremail:useremail},
                    })
              .then((Response)=>{
              if (Response.data=="success"){
               setSaveSuccess(true)
               setWait(false)
               setSavebtn(true)
               //setSuccess(true)
               setSending(false)
               setValidated(false)
              showToastMessage()
               //hidesuccess();
             
              clearFields()
                     }
                     if(Response.data=="failed"){
      
                     }
                     if(Response.data=="nosms"){
                      setSending(false)
                      showToastMessage2()
                     }
              })
              .catch((error) => {
              if (error.code="ERR_BAD_RESPONSE"){
                showToastMessage1()
                setSending(false)
               setShowhide2(true)
               setWait(false)
               setNoInternet(true)
                     }
              
              })
            
              }}}

  const validateFields=(e)=>{
    
    if(message.length>3 && valueState.length>0 ){
      setValidated(true)}
    
    else{
        if(selectedOptions.length==0){
            setBidError(true)
          
                setInputStyle({
                  'border-color':'red'
                })
            }
            if(selectedOptions1.length==0){
                setSAdmError(true)
              
                    setInputStyle1({
                      'border-color':'red'
                    })
                }
      if(dateissued.trim().length==0){
        setDissuedError(true)
       
        }
        if(returnby.trim().length==0){
          setRbyError(true)
          }
          
    }}

    function hidesuccess() {
      return new Promise(resolve => {
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      });
    }
    const clearFields=()=>{
        setSelectedOptions([])
        setSelectedOptions1([])
        setIssueDate('')
        setRby('')
    }
    const handleChange = event => {
        console.log(event) //holds the selected option correctly
        // if selection/addition
        setRecepients([...recepients, event.target.value]);
    }     
   if(payment=="false"){
    return <Redirect to='/payment'/>}
  return (
    <div>
      <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>/ Sms / Some Guardians</div>
        <div className='bodysection col-lg-7 pt-2'>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
       
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
 
<Form >
<FormGroup className='m-2'>
<Label></Label>

<Select1
       value={valueState}
       defaultValue=""
       placeholder="Select Customer"
       isMulti
       options={renderList()}
       onChange={ (value) => handleSelectChange(value) }
       name="ColumnSelect"
       label=""
       isClearable
/>
</FormGroup>
<FormGroup className='m-2 '>
<Input width="200px"height="200 px"rows="4"type="textarea" placeholder="Type your message ..."value={message} onChange={e => setMessage(e.target.value)} name="email"  required/>
</FormGroup>
      <div className='m-2'>
      <div className=' '>
  <Button color="primary"className={message.trim().length>3?" mb-3 ":'mb-3   disabled'} type="submit"onClick={(e)=> saveData(e)}>
     <div className='row rowbtn '>
     <div className={sending?'col sendbtn pt-1 pb-0':'pb-1 pl-2 pr-2'}>
      {editbtn && "Update"}
      {!sending && "Send"}
      {sending && "Sending"}
      {wait && " Please wait.."}
      </div>
    {sending && <div className=' col spinner'>
    <Oval
    
      height={30}
      margin={0}
      className="spinner"
  strokeColor="white"
  strokeWidth="5"
  animationDuration="1"
  width="20"
  visible={true}
/>
</div> }
</div>
    </Button> 
   
    </div>

    
    </div>
    </Form>
       
</div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Selectguardian))