import React,{useEffect,useState} from 'react'
import { checkAuthenticated,load_user,login } from './../actions/auth';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from "axios";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { Card,Modal} from 'react-bootstrap';
function Payment({isAuthenticated,checkAuthenticated,useremail,payment}) {
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [showhide,setShowhide]=useState(false);
  const [month,setMonth]=useState("");
  const [year,setYear]=useState("");
  const [email,setEmail]=useState("");
  const handleModalShowHide=()=> {
    setShowhide(!showhide);
}
const closeModal=()=> {
  setShowhide(false);
}
const handleSubmit = (e) => {
  e.preventDefault()
  if(!email|!month|!year){
   
     }
  else{
    
    axios({
      method:'post',
      url:'/api/payment/',
      data:{email:email,month,year},
    })
 .then((Response)=>{
  //clear fields
  setEmail('');
 })

}
};
const handledate = () => {
  var today = new Date(),
  date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  return date;
}
  if(isAuthenticated=='false'){
        
   /* return <Redirect to='/login'/>*/}

  return (
    <div><div className='bodysection col-lg-4 p-4'>
      {payment=="false" && <h5 style={{color:'red'}}>Please Make your Yearly Payment to continue using this system</h5>}
        {useremail=="6" && <Button  onClick={handleModalShowHide} className="m-2 btn-primary">
           Pay
       </Button>}
        <h4>How to make your Payment</h4>
        1. Go to M-pesa<br/><br/>
        2. Lipa na M-pesa<br/><br/>
        3. Select Pay Bill<br/><br/>
        4. Enter Business Number 522522<br/><br/>
        5. Enter Account Number 1311403728<br/><br/>
        6. Enter amount Kshs. 10,000<br/><br/>
        7. Enter your Pin<br/><br/>
        8. Send<br/><br/>
        For assistance, call/whatsapp 0701833970
        </div>
        <Modal show={showhide}>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title>New Payment details</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}

           <Form  >

<FormGroup>
<Label>Email</Label>
<Input type="text" placeholder="Enter Email"value={email} onChange={e => setEmail(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup>
<Label>Month.</Label>
<Input type="number" placeholder="Enter month"name="month" value={month} onChange={e => setMonth(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Year.</Label>
<Input type="number" placeholder="Enter year"name="year" value={year} onChange={e => setYear(e.target.value)} required />
</FormGroup>

   
           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
    </div>
  )
}


const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user})(Payment)