import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import {toast} from 'react-toastify';
 
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
 
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import useKeyboardShortcut from 'use-keyboard-shortcut'

import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation,useParams } from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';

const Cinvoice=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,id,useremail,payment})=> {
 
  const [showhide,setShowhide]=useState(false);
  const [customerFetch,setCustomerFetch]=useState(false);
  const [amountvisible,setAmountVisible]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [showhide1,setShowhide1]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [names, setName] = useState("");
  const [filteredinvoice, setFilteredInvoice] = useState("");
  const [name, setNames] = useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [id1,setId]=useState("");
  const [inumber,setInumber]=useState("hello");
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(false);
  const [editbtn1,setEditbtn1]=useState(false);
  const [savebtn1,setSavebtn1]=useState(false);
  const [wait,setWait]=useState(false);
  const [wait1,setWait1]=useState(false);
  const [hide,setHide]=useState(true);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [dropopen,setDropOpen]=useState(false);
  const [dropdown,setDropDown]=useState("Unpaid");
  const [issuedate,setIssueDate]=useState("");
  const [duedate,setDueDate]=useState("");
  const [product,setProduct]=useState("");
  const [price,setPrice]=useState("");
  const [subtotal,setSubTotal]=useState("");
  const [search,setSearch]=useState("");
  const [searchcustomer,setSearchCustomer]=useState("");
  const [quantity,setQuantity]=useState("");
  const [index,setIndex]=useState("");
  const [dateentered,setDateEntered]=useState("");
  const [details,setDetails]=useState(['']);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [details2bkp,setDetails2bkp]=useState(['']);
  const [details1,setDetails1]=useState(['']);
  const [details2,setDetails2]=useState(['']);
  const [details3,setDetails3]=useState(['']);
  const [products,setProducts]=useState(['']);
  const [irecords,setIRecords]=useState([]);
  const [grandtotal,setGrandTotal]=useState('');
  const [grandtotal1,setGrandTotal1]=useState('');
  const [totalPartialAmount,setTotalPartialAmount]=useState('');
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const [musyoki,setMusyoki]=useState('musyoki');
  const [inputList1,setInputList1]=useState([]);
  const [heading,setHeading]=useState(['Names', 'Mobile','Action']);
  const [inputList, setInputList] = useState([]);
  const [inputList2, setInputList2] = useState([]);
  const [payments, setPayments] = useState(['']);
  const [data2,setData2]=useState([""]);
  const inputRef = useRef(null);
  const [notFound,setNotFound]=useState(false);
  const [notInternet,setNoInternet]=useState(false);

  useHotkeys('ctrl+m', () => handleModalShowHide1());
  useHotkeys('ctrl+B', () => handleModalShowHide());
  useHotkeys('ctrl+i', () => handleSubmit());
  useHotkeys('ctrl+k', () => createSendEmail());
  const toggle=()=>{
    setDropOpen(!dropopen);
  }
  toast.configure()
  const showToast = () => toast("Operation succesful",{position: toast.POSITION.TOP_CENTER});
  const getTotal=()=>{
  
    const res=inputList.reduce((prev,item)=>{
      
        return prev +(item.price1*item.quantity1);
    },0)
    setGrandTotal(res)
    console.log(res);
    }

    const getTotal1=()=>{
  
      const res=inputList1.reduce((prev,item)=>{
        
          return prev +(item.price*item.quantity);
      },0)
      setGrandTotal1(res)
      console.log(res);
      }
  const handledate = () => {
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return date;
  }
   // handle input change
   const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
 
  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    getTotal();
  };
 
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { firstName: "", lastName: "" }]);
  };

  useEffect(()=>{
 
    //check authentication
    checkAuthenticated();
    fetchPayment(useremail)
    // fetch products
    let data1 ;

    axios({
      method:'post',
      url:'/totalproducts/',
      data:{useremail:useremail},
    })
    .then(res => {
        data1 = res.data;
        setDetails(data1);
        setDetailsbkp(data1);
         
    })
    .catch(err => {})
    
     // fetch customers
     let data2 ;

     axios({
      method:'post',
      url:'/totalcustomers/',
      data:{useremail:useremail},
    })
     .then(res => {
         data2 = res.data;
         setDetails2(data2);
         setDetails2bkp(data2);
     
     })
     .catch(err => {})
//check if edit is true
const invoicenumber = /[^/]*$/.exec(window.location.href)[0];
const result = invoicenumber.includes("-") && invoicenumber.length<40 ? true : false;

     //fetch edit records when editing
     if(result){
     //fetch customer records
     axios({
      method:'post',
      url:'/customersearch/',
      data:{mobile:id},
    })
    .then((Response)=>{
     const datareceived=Response.data;
     console.log(datareceived)
    //set fields
    setName(datareceived[0].names)
    setEmail(datareceived[0].email)
    setMobile(datareceived[0].mobile)
    setTown(datareceived[0].town)
      })
     //set edit
     setEdit(true);
     setEditbtn(true)
     setEditbtn1(true)
       //set local invoice
       setInvoiceNumber(invoicenumber)
       //fetch invoice records
    
    axios({
      method:'post',
      url:'/ordersearch/',
      data:{invoicenumber},
    })
    .then((Response)=>{
       //filter record
     const datareceived=Response.data;
     setIRecords(datareceived)
    console.log(datareceived)
    //set fields
    setId2(datareceived[0].id)
    setDropDown(datareceived[0].status)
    setIssueDate(datareceived[0].issuedate)
    setDueDate(datareceived[0].duedate)
      })
      //fetch sales records
      
      axios({
        method:'post',
        url:'/salesearch/',
        data:{invoicenumber},
      })
      .then((Response)=>{
         //filter record
       const datareceived=Response.data
       setInputList1(datareceived)
       console.log(datareceived)
      
        })
      
      fetchPartial()
     }else{
      setSavebtn(true)
      setSavebtn1(true)
     }
      },[])
       //fetch partial payment records
       const fetchPartial=()=>{
        const invoicenumber = /[^/]*$/.exec(window.location.href)[0];
        axios({
         method:'post',
         url:'/paymentsearch/',
         data:{invoicenumber},
       })
       .then((Response)=>{
          //filter record
        const datareceived=Response.data
        setPayments(datareceived)
       console.log(datareceived)
         })  
       }
      //calculate total partial payments
      useEffect(()=>{

        if(payments.length!=0){
        //calculate total sales
       
        const res=payments.reduce((prev,item)=>{
               
         return prev +(item.amount);
       },0)
       setTotalPartialAmount(res)
        }
             },[payments]) 
      useEffect(()=>{
      getTotal1()
    },[inputList1]) 
   
    useEffect(()=>{
      
       //fetch account details
       let data1 ;

       axios({
         method:'post',
         url:'/totalcompany/',
         data:{useremail:useremail},
       })
       .then(res => {
           data1 = res.data;
           setData2(data1);
         }
       )
    },[]) 

    useEffect(()=>{
      checkSelected()
    },[dropdown]) 
      useEffect(()=>{
        console.log(inputList1)
        inputList1.forEach(inputList1 => {
          // push each  info into a row
          setInputList([...inputList, { name1: inputList1.product, quantity1: inputList1.quantity,price1:inputList1.price,total:inputList1.price*inputList1.quantity }]);
        });
        
        console.log(inputList)
      },[inputList1]) 

      useEffect(()=>{
getTotal()
      },[inputList])  
      useEffect(()=>{
console.log(inputList)
      },[inputList]) 
  const handleSubmit = (e) => {
    e.preventDefault()
    
    if(!names|!email|!mobile|!town| inputList.length==0| !duedate|!issuedate){
    alert("select customer,products and invoice details")
    
       }else
    if(edit){
      //update the invoice status
      setEditbtn(false)
      setSavebtn(false)
      setWait(true)
      axios({
        method:'put',
        url:`/api/order/${id2}/`,
        data:{status:dropdown},
      })
      .then((Response)=>{
        if (Response.data){
          setUpdateSuccess(true)
          setWait(false)
          setEditbtn(true)
          showToast()
                }
      })
      .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
          setNotFound(true)
          setShowhide2(true)
          setWait(false)
          setEditbtn(true)
                }
        
      })
      
      axios({
        method:'put',
        url:`/api/customer/${id1}/`,
        data:{names,email,mobile,town,handledate},
      }).then((Response)=>{
       
        //clear fields
    
      })
     

      //insert partial pament
      if(!partialamount==""){
      axios({
        method:'post',
        url:`/api/partialpayment/`,
        data:{invoicenumber:invoiceNumber,amount:partialamount,owner:useremail,handledate},
      }).then((Response)=>{
       
        //clear fields
      
    
      })}
      //window.location.reload();
    fetchPartial()
      
    }
    else{
      setEditbtn(false)
    setSavebtn(false)
    setWait(true)
     //generate invoice id
     const unique_id = uuid();
     const small_id ="INV-"+ unique_id.slice(0,8).toUpperCase();
     setInumber(small_id)  
    
      //insert partial pament
 if(!partialamount==""){
 
  axios({
    method:'post',
    url:`/api/partialpayment/`,
    data:{invoicenumber:small_id,amount:partialamount,owner:useremail},
  })
  .then((Response)=>{
    if (Response.data){
      
            }
  })
  .catch((error) => {
    if (error.code="ERR_BAD_RESPONSE"){
      
            }
    
  }) 
}
      
    
     //save the sales details
     
     let cartLen = inputList.length;
  
     for (let i = 0; i < cartLen; i++) {
        const invoicenumber1=small_id;
        const name1=inputList[i].name1;
        const quantity1=inputList[i].quantity1;
        const total=inputList[i].price1;
        const dateOrdered=handledate();
        
     axios({
      method:'post',
      url:'/api/sales/',
      data:{invoicenumber:invoicenumber1,product:name1,price:total,quantity:quantity1,owner:useremail,dateentered:dateOrdered},
    })
    .then((Response)=>{
      if (Response.data){
        
              }
    })
    .catch((error) => {
      if (error.code="ERR_BAD_RESPONSE"){
       
              }
      
    }) }
     //save invoice
   axios({
     method:'post',
     url:'/api/order/',
     data:{invoicenumber:small_id,type:"invoice",issuedate,duedate,customerid:mobile,status:dropdown,owner:useremail},
   })
   .then((Response)=>{
    if (Response.data){
      setSaveSuccess(true)
      setWait(false)
      setSavebtn(true)
      showToast()
            }
  })
  .catch((error) => {
    if (error.code="ERR_BAD_RESPONSE"){
      setNotFound(true)
      setShowhide2(true)
      setWait(false)
      setNoInternet(true)
            }
    
  })
   //save customer details
   
   if(!customerFetch){
   const dateOrdered=handledate();
  axios({
    method:'post',
    url:'/api/customer/',
    data:{names,email,mobile,town,owner:useremail,dateentered:dateOrdered},
  }).then((Response)=>{
   //clear fields
   
  })
}
if(!notInternet)
{
  clearFields();
}
 

}
//setEdit(false);
setId('');
//window.location.reload();
  }
  const clearFields=()=>{
    setName('')
    setEmail('')
    setMobile('')
    setTown('')
    setDropDown('Unpaid')
    setIssueDate("")
    setDueDate('')
    setInputList([])
    console.log(inputList)
  }
  //delete method
  const handleDelete = (item) => {
    console.log(item);
    axios({
      method:'delete',
      url:`/api/product/${item.id}/`,
    }).then((Response)=>{
      
alert('Record deleted Succesfully')
      
    })
    
  };
  //add productn
  const addProduct=(data)=>{
   setProducts([...products,data]);
   //set fields
   setInputList([...inputList, { name1: data.name, quantity1: 1,price1:data.price,total:data.price }]);
   
     closeModal();
     getTotal();
}
const createSendEmail=async(e)=>{
  e.preventDefault()
 
  
   //generate invoice id
   const unique_id = uuid();
   const small_id ="INV-"+ unique_id.slice(0,8).toUpperCase();
 //save records
 if(!names|!email|!mobile|!town| inputList.length==0| !duedate|!issuedate){
  alert("select customer,products and invoice details")
  
     }else
  if(edit){
    //update the invoice status
    setEditbtn1(false)
    setSavebtn1(false)
    setWait1(true)
    axios({
      method:'put',
      url:`/api/order/${id2}/`,
      data:{status:dropdown},
    }).then((Response)=>{
      
      //clear fields
     
    })
    
    axios({
      method:'put',
      url:`/api/customer/${id1}/`,
      data:{names,email,mobile,town,handledate},
    }).then((Response)=>{
     
      //clear fields
  
    })
   

    //insert partial pament
    if(!partialamount==""){
    axios({
      method:'post',
      url:`/api/partialpayment/`,
      data:{invoicenumber:invoiceNumber,amount:partialamount,owner:useremail,handledate},
    }).then((Response)=>{
     
      //clear fields
    
  
    })}

     //send the doc to backend


     axios({
      method:'post',
      url:'/upload/',
      data:{email:useremail,phone:mobile,invoicenumber:invoiceNumber,issuedate:issuedate,duedate:duedate,status:dropdown,items:inputList},
    }).then((Response)=>{
      if (Response.data="success"){
        setUpdateSuccess(true)
        setWait1(false)
        setEditbtn1(true)
        showToast()
              }
    })
    .catch((error) => {
      if (error.code="ERR_BAD_RESPONSE"){
        setWait1(false)
        setEditbtn1(true)
        setNotFound(true)
        setShowhide2(true)
              }
      
    })
    
   // window.location.reload();
  
    fetchPartial()
  }
  else{
    setEditbtn1(false)
    setSavebtn1(false)
    setWait1(true)
     
    //insert partial pament
if(!partialamount==""){
axios({
  method:'post',
  url:`/api/partialpayment/`,
  data:{invoicenumber:small_id,amount:partialamount,owner:useremail},
}).then((Response)=>{
 
  //clear fields


})}

   //save the sales details
  
   let cartLen = inputList.length;

   for (let i = 0; i < cartLen; i++) {
      const invoicenumber1=small_id;
      const name1=inputList[i].name1;
      const quantity1=inputList[i].quantity1;
      const total=inputList[i].price1;
      const dateOrdered=handledate();
      
      const res= await axios.post('/api/sales/',{
    invoicenumber:invoicenumber1,product:name1,price:total,quantity:quantity1,owner:useremail,dateentered:dateOrdered
  }).then((Response)=>{
   //clear fields
   
  })}
   //save invoice
   
 axios({
   method:'post',
   url:'/api/order/',
   data:{invoicenumber:small_id,type:"invoice",issuedate,duedate,customerid:mobile,status:dropdown,owner:useremail},
 }).then((Response)=>{
  //clear fields

 })
 
 //save customer details
 if(!customerFetch){
 const dateOrdered=handledate();
axios({
  method:'post',
  url:'/api/customer/',
  data:{names,email,mobile,town,owner:useremail,dateentered:dateOrdered},
}).then((Response)=>{
 //clear fields
 
})
}


 //send the doc to backend

 
 axios({
  method:'post',
  url:'/upload/',
  data:{email:useremail,phone:mobile,invoicenumber:small_id,issuedate:issuedate,duedate:duedate,status:dropdown,items:inputList},
}).then((Response)=>{
  if (Response.data="success"){
    setWait1(false)
    setSavebtn1(true)
    showToast()
          }
})
.catch((error) => {
  if (error.code="ERR_BAD_RESPONSE"){
    setWait1(false)
    setSavebtn1(true)
    setNotFound(true)
    setShowhide2(true)
    setNoInternet(true)
          }
  
})
if(!notInternet){
  clearFields()
}

}
//setEdit(false);
setId('');

   
      //window.location.reload();
}
//add product btn
const addProductBtn=()=>{
  if(!name|!price){
    alert("fill all the fields") 
  }else{
  setProducts([...products,{name,quantity,price}]);
  console.log(products)
  //clear fileds
  
   }
}
 
//add customer
const addCustomer=(data)=>{
 
    setName(data.names)
    setEmail(data.email);
    setMobile(data.mobile);
    setTown(data.town)
    setCustomerFetch(true)
      closeModal1();
}

  const editItem = (detail) => {
    setId(detail.id);
    setEdit(true);
    setName(detail.names);
    setEmail(detail.email);
    setMobile( detail.mobile);
    setTown(detail.town);

  };
  const cleaFields=()=>{
    setName('');
    setEmail('');
    setMobile('');
    setTown('');
   
  }
  const handleModalShowHide=()=> {
   
    setShowhide(!showhide);
    
}
const closeModal=()=> {
  setShowhide(false);
}
const handleModalShowHide1=()=> {
  setShowhide1(!showhide1);
  console.log("clicked");
}
const handleModalShowHide2=()=> {
  setShowhide2(!showhide2);
  console.log("clicked");
}
const closeModal1=()=> {
setShowhide1(false);
}
const closeModal2=()=> {
  setShowhide2(false);
  setNoInternet(false)
  }
const filterList = (event) => {
  var updatedList = detailsbkp;
  updatedList = updatedList.filter(function(list) {
    return (
      list.name.toLowerCase().search(search.toLowerCase()) !==
      -1
    );
  });
  setDetails(updatedList)
  
  };
  const filterListCustomer = (event) => {
    var updatedList = details2bkp;
    updatedList = updatedList.filter(function(list) {
      return (
        list.names.toLowerCase().search(searchcustomer.toLowerCase()) !==
        -1
      );
    });
    setDetails2(updatedList)
    
    };
    if(isAuthenticated=="false"){
    }
    
    console.log(inputList)
    useEffect(()=>{
      checkAuthenticated();
      
        },[]) 
  if(isAuthenticated=="false"){
   return <Redirect to='/login'/>}
  
   const checkSelected=()=>{
     if (dropdown=="Partial Payment"){
setAmountVisible(!amountvisible)
console.log(dropdown)
     }
   }
   if(payment=="false"){
    return <Redirect to='/payment'/>}
  return (
    <div>
        <div className='bodysection col-lg-8'>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
        {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
{edit?<h4>Invoice Details</h4> : <h4>Create New Invoice</h4>}
<Form >
  <div className='row'>
  <FormGroup className='mr-5 ml-3'>
<Label>Status</Label><br/>
<select id="cars" name="carlist" form="carform"onChange={e => {setDropDown(e.target.value);checkSelected()}}value={dropdown} className='select'style={{width:"150px",height:"37px",'border-color':"gainsboro"}}>

  <option value="Unpaid" >Unpaid</option>
  <option value="Paid" >Paid</option>
  <option value="Partial Payment" >Partial Payment</option>
</select>
</FormGroup>
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
</FormGroup>}
<FormGroup className='mr-5 ml-3'>
<Label>Issue Date</Label>
<Input type="date" placeholder="Enter Email"value={issuedate} onChange={e => setIssueDate(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup className='mr-5 ml-3'>
<Label>Due Date</Label>
<Input type="date" placeholder="Enter mobile"name="author" value={duedate} onChange={e => setDueDate(e.target.value)} required />
</FormGroup>

  </div>
  <h4>Customer Information {!edit && <Button onClick={handleModalShowHide1}>Select</Button>}</h4>
<FormGroup>
<Label>Names</Label>
<Input type="text" placeholder="Enter name"name="title" onChange={e => setName(e.target.value)} value={names} required/>
</FormGroup>

<FormGroup>
<Label>Email</Label>
<Input type="email" placeholder="Enter Email"value={email} onChange={e => setEmail(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup>
<Label>Mobile.</Label>
<Input type="number" placeholder="Enter mobile"name="author" value={mobile} onChange={e => setMobile(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Town.</Label>
<Input type="text" placeholder="Enter Town"name="town" value={town} onChange={e => setTown(e.target.value)} required />
</FormGroup>
<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       
       required
     
     />
</FormGroup>
    
<h3><a href="https://cluemediator.com">Product</a></h3>  
  {!edit && !hide && <Button onClick={handleAddClick}>Add</Button>} {!edit && <Button onClick={handleModalShowHide}className="ml-2">Select</Button>}
    
      <div className='row mb-1'>
<div className='col'>Product name</div>
<div className='col'>Qty</div>
<div className='col'>Price</div>
<div className='col'>Sub-Total</div>
      </div>
      {!edit && inputList.map((x, i) => {
        return (
          <div className="box row">
            <div className='col'>
            
            <Input
              name="name1"
   placeholder="Product Name"
              value={x.name1}
              onChange={e => handleInputChange(e, i)}
              required
            />
            
            </div>
            <div className='col'>
              
          
            <Input
            type="number"
              className="ml10"
              name="quantity1"
   placeholder="Enter Quantity"
              value={x.quantity1}
              onChange={e =>{ handleInputChange(e, i);getTotal()}}
            /></div>
             <div className='col'>
              
            <Input
            type='number'
              className="ml10"
              name="price1"
   placeholder="Price"
              value={x.price1}
              onChange={e =>{ handleInputChange(e, i);getTotal()}}
            /></div>
             <div className='col'>
              
            <Input
              className="ml10"
              name="total"
   placeholder=""
              value={x.price1 && x.quantity1 && x.price1*x.quantity1}
              onChange={e => handleInputChange(e, i)}
            /></div>
            <div className=" border2">
              {inputList.length !== 1 && !edit && <i 
                className="fa fa-trash btn1 mr10 mt-1 border"
                onClick={() => handleRemoveClick(i)}></i>}
              
            </div>
          </div>
        );
      })}
      {edit && inputList1.map((x, i) => {
        return (
          <div className="box row">
            <div className='col'>
            
            <Input
              name="name1"
   placeholder="Product Name"
              value={x.product}
              onChange={e => handleInputChange(e, i)}
              required
            />
            
            </div>
            <div className='col'>
              
          
            <Input
            type="number"
              className="ml10"
              name="quantity1"
   placeholder="Enter Quantity"
              value={x.quantity}
              onChange={e =>{ handleInputChange(e, i);getTotal()}}
            /></div>
             <div className='col'>
              
            <Input
            type='number'
              className="ml10"
              name="price1"
   placeholder="Price"
              value={x.price}
              onChange={e =>{ handleInputChange(e, i);getTotal()}}
            /></div>
             <div className='col'>
              
            <Input
              className="ml10"
              name="total"
   placeholder=""
              value={x.price && x.quantity && x.price*x.quantity}
              onChange={e => handleInputChange(e, i)}
            /></div>
            <div className="btn-box">
              {inputList.length !== 1 && !edit && <Button
                className="mr10 mt-1"
                onClick={() => handleRemoveClick(i)}>Remove</Button>}
              
            </div>
          </div>
        );
      })}
       <div style={{ marginTop: 20,fontSize:20,display:'inline',marginRight:5 }}>{inputList.length !== 0 && "Total"}</div>{!edit ?  <div style={{ display:'inline',fontSize:20 }}>{inputList.length !== 0 &&  JSON.stringify( grandtotal)}</div> : <div style={{ display:'inline',fontSize:20 }}>{inputList1.length !== 0 &&  JSON.stringify( grandtotal1)}</div>}
       <div style={{ marginTop: 20,fontSize:20,display:'inline',marginRight:5 }} className="ml-3">{edit && payments.length !== 0 && "Total Partial Payments"}</div>{edit &&  <div style={{ display:'inline',fontSize:20 }}>{payments.length !== 0 &&  JSON.stringify( totalPartialAmount)}</div> }
       <div style={{ marginTop: 20,fontSize:20,display:'inline',marginRight:5 }} className="ml-3">{edit && payments.length !== 0 && "Balance"}</div>{edit &&  <div style={{ display:'inline',fontSize:20 }}>{payments.length !== 0 &&  JSON.stringify( grandtotal1-totalPartialAmount)}</div> }
    <FormGroup>
      
      <Input type="hidden" placeholder="Enter your Phone number"name="jobtype"   required />
      <Input
                type="hidden"
                id="todo-description"
                name="datesend"
               
                required
            
                placeholder="Enter mobile Number"
              />
              
      </FormGroup>
      <div className='row'>
  <Button color="primary"className="mt-2 mb-5 ml-2" type="submit"onClick={(e)=> handleSubmit(e)}>
      {editbtn && "Update"}
      {savebtn && "Save"}
      {wait && " Please wait.."}
    </Button>  
    
    <Button color="primary"className="mt-2 mb-5 ml-5" type="submit"onClick={(e)=> createSendEmail(e)}>
    {editbtn1 && "Update and Send"}
      {savebtn1 && "Save and Send"}
      {wait1 && " Please wait.."}
    </Button> 
    </div>
    </Form>
       <Modal show={showhide}>
           <Modal.Header closeButton onClick={closeModal}>
          
           <Modal.Title>Products List</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           <Input type="text" placeholder="Search Product Name"name="town" value={search} onChange={e =>{ setSearch(e.target.value);filterList()}}onKeyDown={filterList}onKeyUp={filterList} required />
           <Table responsive className="ml-5">
  <thead>
    <tr >
    {heading.map(head => <th>{head}</th>)}
    </tr>
  </thead>
 
                <tbody>     
                {details.length==0? <div>No Records found</div>:details.map(detail => (
                  <tr key={detail.id} >
                     <td className="col-1" >{detail.name}</td>
                     <td class="col-2">{detail.category}</td>
                     <td class="col-1">{detail.price}</td>
                  
                     <td class="col-2"><Button color="primary"className="mt-2" type="submit"onClick={(e)=> addProduct(detail)}>
      Select
    </Button>  </td>
                   </tr>
             ))}
    </tbody>
    
     
           </Table>  
 


</Modal.Body>

       </Modal>

       <Modal show={showhide1}>
           <Modal.Header closeButton onClick={closeModal1}>
           <Modal.Title>Customers List</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           <Input type="text" placeholder="Search Product Name"name="town" value={searchcustomer} onChange={e =>{ setSearchCustomer(e.target.value);filterListCustomer()}}onKeyDown={filterListCustomer}onKeyUp={filterListCustomer} required />
           <Table responsive className="ml-5">
  <thead>
    <tr >
    {heading.map(head => <th>{head}</th>)}
    </tr>
  </thead>
 
                <tbody>     
                {details2.length==0? <div>No Records found</div>:details2.map(detail => (
                  <tr key={detail.id} >
                     <td className="col-3" >{detail.names}</td>
                     <td class="col-2">{detail.mobile}</td>
                     <td class="col-2"><Button color="primary"className="mt-2" type="submit"onClick={(e)=> addCustomer(detail)}>
      Select
    </Button>  </td>
                   </tr>
             ))}
    </tbody>
    
     
           </Table> 
</Modal.Body>

       </Modal>
</div>
<div className='col-lg-1 centermodal'>
           <Modal show={showhide2} className='modal1 modal-dialog-centered'>
           <Modal.Header closeButton onClick={closeModal2}className='modalheader1'>
           <Modal.Title className='modalheader'>Failed</Modal.Title>
           </Modal.Header>
           <Modal.Body className='modalbody'>
      
          
  {notFound && <div className='progress red mb-1'><i class="fa fa-exclamation-triangle check" aria-hidden="true"></i></div>}
{notFound && <label>Server not found Error. Check if you are connected to the Internet and try again</label>}
</Modal.Body>
<Modal.Footer className='modalfooter'> <Button color="primary ml-2 closebtn"className=" m-2 mt-3" type=""onClick={closeModal2}>
Close
</Button></Modal.Footer>
       </Modal>
       </div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Cinvoice))