import React,{useEffect,useState} from 'react'
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Viewstudents.css'
import { ToastContainer, toast } from 'react-toastify';
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { TailSpin} from  'react-loader-spinner'
  import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {connect} from 'react-redux';
import { setId} from './../actions/auth';
import { Table } from 'react-bootstrap';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import { Card,Modal} from 'react-bootstrap';
import { useSelect } from 'react-select-search';
import {Redirect} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrash,faEye} from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Cinvoice from './Cinvoice';
import jsPDF from "jspdf";
import "jspdf-autotable";

const Viewstudents=({isAuthenticated,checkAuthenticated,setId,useremail,payment,fetchPayment,isadmin,username1,isstaff})=> {
  const [dropdown,setDropDown]=useState("");
  const [loading,setLoading]=useState(false);
  const [notFound,setNotFound]=useState(false);
  const [successicon,setSuccessIcon]=useState(false);
  const [nosms,SetNoSms]=useState(false);
  const [emailSuccess,setEmailSuccess]=useState(false);
  const [messageSuccess,setMessageSuccess]=useState(false);
  const [message,setMessage]=useState(false);
  const [loadspinner,setLoadspinner]=useState(false);
  const [total,setTotal]=useState("");
  const [search,setSearch]=useState("");
  const [deleteid,setDeleteId]=useState("");
  const [details,setDetails]=useState([""]);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [data,setData]=useState([""]);
  const [data2,setData2]=useState([""]);
  const [customer,setCustomer]=useState([""]);
  const [data1,setData1]=useState([""]);
  const [invoices,setInvoices]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [success,setSuccess]=useState();
  const [notreachable,setNotReachable]=useState(false);
  const [dataReceived,setDataReceived]=useState([]);
  const [heading,setHeading]=useState(['ADM No.', 'First Name.', 'Surname','Category','Class','Action']);
  const [percentage, setPercentage] = useState(0);
  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;
  const options = {
    page: 1,
    sizePerPage: 10,
    nextPageText: '>',
    prePageText: '<',
    showTotal: true
  };

  const columns = [{
    dataField: 'studentadm',
    text: 'Adm No.',
    headerStyle: () => {
      return { width: "100px" };
    }
  }, {
    dataField: 'first_name',
    text: 'First Name',
    headerStyle: () => {
      return { width: "120px" };
    }
  },  {
    dataField: 'parent_last_name',
    text: 'Surname',
    headerStyle: () => {
      return { width: "120px" };
    }
  }, {
    dataField: 'category',
    text: 'Category',
    headerStyle: () => {
      return { width: "100px" };
    }
  },  {
    dataField: 'class_name',
    text: 'Class',
    headerStyle: () => {
      return { width: "100px" };
    }
  },   {
    dataField: "id",
    text: "Action",
    headerStyle: () => {
      return { width: "100px" };
    },
    csvExport: false,
    editable: false,
    formatter: (cellContent, row) => {
      return (
        <div>
      <div className='row '><div onClick={e =>{ setId(row.studentadm);reLoadPage()}} className="border"><Link exact to={`/addstudent/${row.id}`} >
                     {isadmin=="true"&& <span className=''><FontAwesomeIcon icon={faEdit}className="btn1" /></span>}</Link></div>{isadmin=="true"&&<span className='border'><FontAwesomeIcon icon={faTrash}className="btn1 border1" onClick={e =>{ handleModalShowHide1(row.id)}}/></span>}<div onClick={e =>{ setId(row.studentadm);reLoadPage()}} className="border"><Link exact to={`/viewstudent/${row.id}`} >
                     <span className=''><FontAwesomeIcon icon={faEye}className="btn1" /></span></Link></div></div>
      </div>
      );
    },
},];
  const showToastMessage2 = () => {

    toast.success('Record deleted succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
 
  

  useEffect(() => {
    setTimeout(() => {
      if (percentage < 100) {
        setPercentage(percentage + 1);
       
      }
    }, 25);
    if(percentage==100){
      
      setPercentage(0);
    }
  }, [percentage]);

  useEffect(() => {
    if(success){
      hidesuccess()
    }
  }, [success]);
  toast.configure()
  const showToast = () => toast("Operation succesful",{position: toast.POSITION.TOP_CENTER});
  useEffect(()=>{
    
     //fetch total students
     
    fetchAllStudents()
   
    
      },[]) 
      const closeModal1=()=> {
        setShowhide1(false);
        setNotFound(false)
        setMessage(false)
        setMessageSuccess(false)
        setEmailSuccess(false)
        setSuccessIcon(false)
        SetNoSms(false)
        setNotReachable(false)
        }
      
      const handleModalShowHide1=(id)=> {
        setDeleteId(id)
        setShowhide1(!showhide1);
        console.log("clicked");
      }
      
      const reLoadPage=()=>{
       
        window.location.reload();
    }
    const fetchAllStudents=()=>{
      if(isadmin=="true"| isstaff=="true"){
      let data4 ;
      axios({
       method:'post',
       url:'/totalstudents/',
       data:{useremail:useremail},
     })
     .then(res => {
         data4 = res.data;
         setData(data4);
         console.log(data4) 
         setDetailsbkp(data4);
         setLoading(false)
     })
     .catch(err => {})}
    else{
      let data4 ;
      axios({
       method:'post',
       url:'/totalstudents1/',
       data:{useremail:useremail,username:username1},
     })
     .then(res => {
         data4 = res.data;
         setData(data4);
         console.log(data4) 
         setDetailsbkp(data4);
         setLoading(false)
     })
     .catch(err => {})
    }}
   
    
const filterList = (event) => {
  //search student

  var updatedList = detailsbkp;
  updatedList = updatedList.filter(detail => detail.studentadm.toLowerCase().includes(search.toLowerCase()) | detail.first_name.toLowerCase().includes(search.toLowerCase()) | detail.parent_last_name.toLowerCase().includes(search.toLowerCase()) | detail.category.includes(search.toLowerCase()) | detail.class_name.toLowerCase().includes(search.toLowerCase()))
  setData(updatedList)
  
  };

  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const showToastMessage3 = () => {
     
    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 
  const deleteData = async () => {
    if(useremail=="4")
    {
showToastMessage3();
    }
    else{
 handleModalShowHide1()
    const result = await axios({
      method:'put',
      url:`/api/student/${deleteid}/`,
      data:{isdeleted:"deleted",deletedby:username1}
    })
    .then((Response)=>{
     showToastMessage2()
      // setSuccess(true)
       //hidesuccess();
       fetchAllStudents()
             
    })
    .catch((error) => {
     if (error.code="ERR_BAD_RESPONSE"){
      
             }
     
    })
  }
    }
useEffect(()=>{
  checkAuthenticated();
  fetchPayment(useremail)
    },[]) 
    useEffect(()=>{
      
      
        },[data]) 
    
useEffect(()=>{

  if(data1.length!=0){
  //calculate total sales
  setTotal(0)
  const res=data1.reduce((prev,item)=>{
         
   return prev +(item.price*item.quantity);
 },0)
 setTotal(res)
  }
       },[data1])

if(isAuthenticated=="false"){
  
  /*return <Redirect to='/login'/>*/}
  if(payment=="false"){
  
    return <Redirect to='/payment'/>}
    const value = 0.66;
    
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
      <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
 
      <Router>
      <div className='path'><i className='fa fa-home'></i>/ Students / View Students</div>
        <div className='bodysection col-lg-9'>
        <div className=' col-lg-12 theader'>< h5>Students List</h5> </div> <div className='row p-1'>
        <FormGroup className='mr-5 ml-4'>
<Input type="text" placeholder="Search..."name="town" value={search} onChange={e =>{ setSearch(e.target.value);filterList()}}onKeyDown={filterList}onKeyUp={filterList} required />
        
</FormGroup>
<FormGroup className='mr-2 ml-3'>
<Label className=''>Total Students:</Label>    
</FormGroup>
<FormGroup className='mr-5'>
<Label className=''>{data.length>0?data.length:0}</Label>    
</FormGroup>
</div>

<div className='bg-light p-1'>
           <ToolkitProvider
  keyField="id"
  data={[...data] }
  columns={ columns }
  exportCSV={{ onlyExportFiltered: true, exportAll: true }}
  search
>
  {
    props => (
      <div>
        <ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 
      
        <BootstrapTable keyField='id'
        {...props.baseProps}
         data={ data } 
         columns={ columns } 
         pagination={ paginationFactory(options) }className="pgnav"  
          wrapperClasses="table-responsive"> 
         </BootstrapTable>
      </div> 
    )
  }
</ToolkitProvider>
</div>   
           </div>

           <Switch>
           <Route path='/cinvoice' component={Cinvoice}/>
          </Switch>
           </Router>
           <div className='col-lg-3 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Student?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting a Student Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={deleteData}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  isadmin:state.auth.isadmin,
  isstaff:state.auth.isstaff,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,setId,fetchPayment})(Viewstudents)