import React,{useEffect,useState} from 'react'
import axios from "axios";
import { Table } from 'react-bootstrap';
import { Card,Modal} from 'react-bootstrap';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

function Products({isAuthenticated,checkAuthenticated,useremail,payment,fetchPayment}) {
  const [data,setData]=useState([""]);
  const [name,setName]=useState("");
  const [price,setPrice]=useState("");
  const [dateposted,setDateposted]=useState("");
  const [quantity,setQuantity]=useState("");
  const [category,setCategory]=useState("");
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [id,setId]=useState("");
  const [edit,setEdit]=useState(false);
  const [error1,setError]=useState(false);
  const [showhide,setShowhide]=useState(false);
  const [heading,setHeading]=useState(['Name', 'Category', 'Price','Quantity','Action']);
  useEffect(()=>{
   
    checkAuthenticated();
    
    fetchPayment(useremail);
  
    let data1 ;

    axios({
      method:'post',
      url:'/totalproducts/',
      data:{useremail:useremail},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
        console.log(data1)  
    })
    .catch(err => {})
    
      },[]) 
     const handleModalShowHide=()=> {
        setShowhide(!showhide);
    }
    const closeModal=()=> {
      setShowhide(false);
  }
  const refreshData=()=>{
    let data1 ;

    axios({
      method:'post',
      url:'/totalproducts/',
      data:{useremail:useremail},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
        console.log(data1)  
    })
    .catch(err => {})
    
  }
  const handledate = () => {
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return date;
  }
  const handleSubmit = (e) => {
 if(!name|!category|!price|!quantity){
setError(true)
 }else
    if(id){
      e.preventDefault();
      axios({
        method:'put',
        url:`/api/product/${id}/`,
        data:{name:name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),category:category.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),price,quantity,handledate},
      }).then((Response)=>{
        //clear fields
        setEdit(false);
        setId('');
    cleaFields();
      })
      setUpdateSuccess(true)
    }
    else{
   axios({
     method:'post',
     url:'/api/product/',
     data:{name:name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),category:category.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),price,quantity,owner:useremail,handledate},
   }).then((Response)=>{
    //clear fields
    cleaFields();
   })
   setSaveSuccess(true);
  }
  };
  const editItem = (detail) => {
    setId(detail.id);
    setEdit(true);
    setShowhide(true);
    setName(detail.name);
    setCategory(detail.category);
    setPrice(detail.price);
    setQuantity(detail.quantity);
  };
  const handleDelete = (item) => {
    if(window.confirm('Are You Sure You want delete this record?')){
    console.log(item);
    axios({
      method:'delete',
      url:`/api/product/${item.id}/`,
    }).then((Response)=>{
      
      
      alert("record deleted succesfully");
      refreshData();
    })
  }
  };
  const cleaFields=()=>{
    setName('');
    setCategory('');
    setPrice('');
    setQuantity('');
    refreshData();
  }
  useEffect(()=>{
    
    
      },[]) 
if(isAuthenticated=="false"){
 return <Redirect to='/login'/>}
 if(payment=="false"){
  return <Redirect to='/payment'/>}
  return (
    <div>
        <div className='bodysection col-lg-6'>
        <Button  onClick={handleModalShowHide} className="m-2 btn-primary">
           + Product
       </Button>
       <Modal show={showhide}>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title> New Product details</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
<FormGroup>

<Label>Name</Label>
<Input type="text" placeholder="Enter name"name="title" onChange={e => setName(e.target.value)} value={name} required/>
</FormGroup>

<FormGroup>
<Label>Category</Label>
<Input type="text" placeholder="Enter Category"value={category} onChange={e => setCategory(e.target.value)} name="photo"  required/>

</FormGroup>
<FormGroup>
<Label>Price.</Label>
<Input type="number" placeholder="Enter price"name="author" value={price} onChange={e => setPrice(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Quantity.</Label>
<Input type="number" placeholder="Enter price"name="author" value={quantity} onChange={e => setQuantity(e.target.value)} required />
</FormGroup>
<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       value={dateposted}
       required
     
     />
</FormGroup>
   
           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
<       h4>Products List</h4>
<Table responsive className="">
  <thead>
    <tr >
    {heading.map(head => <th>{head}</th>)}
    </tr>
  </thead>
 
                <tbody>     
                {data.length==0? <div>No Records found</div>:data.map(detail => (
                  <tr key={detail.id} >
                     <td className="col-2" >{detail.name}</td>
                     <td class="col-2">{detail.category}</td>
                     <td class="col-1">{detail.price}</td>
                     <td class="col-1">{detail.quantity}</td>
                    
                     <td class="col-1"><span className='border'><i class="fa fa-edit btn1" onClick={() => editItem(detail)}></i></span><span className='border'><i class="fa fa-trash btn1" onClick={() => handleDelete(detail)}></i></span></td>
                   </tr>
             ))}
    </tbody>
    
     
           </Table> 
           </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchPayment})(Products)