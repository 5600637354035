import React,{useEffect,useState} from 'react'
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Vinvoice.css'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { ToastContainer } from 'react-toastify';
import {toast} from 'react-toastify';
import { TailSpin} from  'react-loader-spinner'
  import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {connect} from 'react-redux';
import { setId} from './../actions/auth';
import { Table } from 'react-bootstrap';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import { Card,Modal} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrash} from '@fortawesome/free-solid-svg-icons'

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Cinvoice from './Cinvoice';
import jsPDF from "jspdf";
import "jspdf-autotable";
import {useRef} from 'react';
const Viewpayment=({isAuthenticated,checkAuthenticated,setId,useremail,payment,fetchPayment})=> {
  const [dropdown,setDropDown]=useState("");
  const [loading,setLoading]=useState(false);
  const [notFound,setNotFound]=useState(false);
  const [successicon,setSuccessIcon]=useState(false);
  const [nosms,SetNoSms]=useState(false);
  const [emailSuccess,setEmailSuccess]=useState(false);
  const [messageSuccess,setMessageSuccess]=useState(false);
  const [message,setMessage]=useState(false);
  const [loadspinner,setLoadspinner]=useState(false);
  const [total,setTotal]=useState("");
  const [studentadm,setStudentadm]=useState("");
  const [feetitle,setFeetitle]=useState("");
  const [search,setSearch]=useState("");
  const [deleteid,setDeleteId]=useState("");
  const [details,setDetails]=useState([""]);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [data,setData]=useState([""]);
  const [data2,setData2]=useState([""]);
  const [customer,setCustomer]=useState([""]);
  const [data1,setData1]=useState([""]);
  const [amountrecord,setArecord]=useState([""]);
  const [names,setNames]=useState([""]);
  const [invoices,setInvoices]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [success,setSuccess]=useState(false);
  const [notreachable,setNotReachable]=useState(false);
  const [dataReceived,setDataReceived]=useState([]);
  const [heading,setHeading]=useState(['Student Adm.','Name','Fee Item','Amount Paid','Balance','Action']);
  const [percentage, setPercentage] = useState(0);
  const tableRef = useRef(null); 
 
  useEffect(()=>{
    
    //fetch total payment
    
   fetchAllPayment()
   
     },[]) 
     useEffect(()=>{
    
        //fetch total payment
       
      fetchAmount()
      
         },[data]) 
         useEffect(()=>{
    if(amountrecord.length>0){
      
      fetchNames()
    }
          //fetch total payment
        
       
           },[amountrecord]) 
     const fetchAllPayment=async()=>{
      
      let data4 ;

     const fee= await axios({
       method:'post',
       url:'/totalfeepayment/',
       data:{useremail:useremail},
     })
     .then(res => {
         data4 = res.data;
         setData(data4);
         console.log(data4) 
         setDetailsbkp(data4);
         setLoading(false)
     })
     .catch(err => {})
   
}
    
    const fetchAmount=async()=>{
        let copy1=[];
 //fetch amount
 if(data.length>0){
    for (var i = 0; i < data.length; i++) {
        
        let data4 ;
        
        const result = await axios({
         method:'post',
         url:'/amountsearch/',
         data:{useremail:useremail,fee:data[i].feetype},
       })
       .then(res => {
           data4 = res.data;
          // setArecord(...amountrecord,data4);
           console.log(data4) 
           //setDetailsbkp(data4);
           setLoading(false)
           copy1.push({amount:data4[0].amount})
       })
       .catch(err => {})
    
    }
    setArecord(copy1);
    }
    }
    const fetchNames=async()=>{
      let copy1=[];
//fetch amount
if(data.length>0){
  for (var i = 0; i < data.length; i++) {
      if(data[i].studentadm){
      let data4 ;
      
      const result = await axios({
       method:'post',
       url:'/studentsearch1/',
       data:{useremail:useremail,id:data[i].studentadm},
     })
     .then(res => {
         data4 = res.data;
        console.log(data4)
        // setDetailsbkp(data4);
         setLoading(false)
         copy1.push({first_name:data4[0].first_name,last_name:data4[0].last_name})
     })
     .catch(err => {})
    }
  }
  setNames(copy1);
  }
  }
     const reLoadPage=()=>{
       
      window.location.reload();
  }

  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const filterList = (event) => {
    //search staff
  
    var updatedList = detailsbkp;
    updatedList = updatedList.filter(detail => detail.feetype.toLowerCase().includes(search.toLowerCase()) | detail.studentadm.toLowerCase().includes(search.toLowerCase()))
    setData(updatedList)
    
    };
  const closeModal1=()=> {
    setShowhide1(false);
    setNotFound(false)
    setMessage(false)
    setMessageSuccess(false)
    setEmailSuccess(false)
    setSuccessIcon(false)
    SetNoSms(false)
    setNotReachable(false)
    }
    const showToastMessage2 = () => {
     
      toast.error('You dont have permission to perform this action', {
          position: toast.POSITION.TOP_CENTER
      });
    }; 
    const showToastMessage = () => {
      toast.success('Record deleted succesfully', {
          position: toast.POSITION.TOP_CENTER
      });
    };  
    const deleteData = async () => {
      if(useremail=="1")
      {
showToastMessage2();
      }
      else{
      handleModalShowHide1()
      
         const result = await axios({
           method:'post',
           url:`/deletepayment/`,
           data:{studentadm,feetitle}
         })
         .then((Response)=>{
          
            //setSuccess(true)
            showToastMessage()
            fetchAllPayment()
                  
         })
         .catch((error) => {
          if (error.code="ERR_BAD_RESPONSE"){
           
                  }
          
         })
        }
         }

         const handleModalShowHide1=(student,fee)=> {
          setStudentadm(student)
          setFeetitle(fee)
          setShowhide1(!showhide1);
          console.log("clicked");
        }
   
if(isAuthenticated=="false"){
  
  /*return <Redirect to='/login'/>*/}
  if(payment=="false"){
  
    return <Redirect to='/payment'/>}
    const value = 0.66;
    
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>/ Finance / View Payments</div>
      <Router>
        <div className='bodysection col-lg-7'>
        <div className=' col-lg-12 theader'>< h5>Fee Payment List</h5> </div> <div className='row p-2'>
<FormGroup className='mr-5 ml-3'>
<Input type="text" placeholder="Search by Adm..."name="town" value={search} onChange={e =>{ setSearch(e.target.value);filterList()}}onKeyDown={filterList}onKeyUp={filterList} required />
        
</FormGroup>
<FormGroup className='mr-2 ml-3'>
<Label className='mt-2'>Total Records:</Label>    
</FormGroup>
<FormGroup className='mr-5'>
<Label className='mt-2'>{data.length>0? data.length:0}</Label>    
</FormGroup>
<DownloadTableExcel
                    filename={'fee payment'}
                    sheet={'fee payment'}
                    currentTableRef={tableRef.current}
                >

{data.length>0 &&  <Button className='ml-2 mt-2 text-right'> Download </Button>}

                </DownloadTableExcel>
</div>
<div class="table-responsive bg-light">
<Table className=" pl-0"ref={tableRef}>
  <thead>
    <tr >
    <th>Student adm</th>
    <th>Name</th>
    <th>Fee Item</th>
    <th>Amount Paid</th>
    <th>Balance</th>
    <th noExport>Action</th>
    </tr>
  </thead>
 
                <tbody>     
                {!data.length>0 ? <div>No Records found</div>:data.map((detail,idx)=> (
                  <tr key={detail.id} >
                
                     <td className="col-3" >{detail.studentadm}</td>
                     {names.length>0 && <td className="col-3" >{names[idx].first_name +" "+ names[idx].last_name}</td>}
                     <td className="col-2" >{detail.feetype}</td>
                     <td className="col-2" >{detail.amount}</td>
                     <td className="col-2" >{amountrecord.length==data.length && amountrecord[idx].amount-detail.amount}</td>
                     <td class="col-2 pr-4"><div className='row '><div onClick={e =>{ setId(detail.id);reLoadPage()}} className="border"><Link exact to={`/editfeetype/${detail.id}`} >
                     </Link></div><span className='border'><FontAwesomeIcon icon={faTrash}className="btn1 border1" onClick={e =>{ handleModalShowHide1(detail.studentadm,detail.feetype)}}/></span></div></td>
                   </tr>
             ))}
    </tbody>
    
     
           </Table> 
           
           </div>
          
           </div>

           <Switch>
           <Route path='/cinvoice' component={Cinvoice}/>
          </Switch>
           </Router>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody1 pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Fee Payment?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting a Fee Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={deleteData}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,setId,fetchPayment})(Viewpayment)