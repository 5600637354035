
import './Adminnav.css';
import { NavLink,BrowserRouter as Router,Route,Switch,Link,Redirect} from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import {connect} from 'react-redux';
import { logout } from './../actions/auth';
import React,{useEffect,useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding,faTimes,faBars,faSignOut,faUser,faUserAlt,faUserCircle, faGraduationCap, faHome,faCaretUp, faAnglesUp, faAngleDoubleUp, faAngleUp, faBell} from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import { Button } from 'reactstrap';
function Adminnav({click,handleClick,adminMenuClick1,username,adminMenu,adminMenuClick2,adminMenu2,logout,useremail,fetchedUser,isadmin,isAuthenticated,user1,username1}) {
    const [names,setName]=useState("");
    const [count,setCount]=useState("");
    useEffect(()=>{   
        axios({
            method:'post',
            url:'/returnviews/',
            data:{useremail:useremail,username:username}
          })
          .then(res => {
             setCount(res.data)
          })
          .catch(err => {})
     
      },[]) 
      const reLoadPage=()=>{
       
        window.location.reload();
    }
    if(!isAuthenticated){
  
       /* <Router>
       return <Redirect to='/login'/>
    </Router>*/}
    const Capitalize=(str)=>{
        return str.charAt(0).toUpperCase() + str.slice(1);
        }
    return (
        <nav className="navbar">
            <Router>
        <div className="nav_icon" onClick={handleClick}>
       
        {click? <FontAwesomeIcon icon={faTimes}className="mobilebtn" />:<FontAwesomeIcon icon={faBars}className="mobilebtn" />}
        </div>
        
        <div className="navbar_right" >
        {isadmin=="true"&&<span className='mgn mr-5'>
            <h6 className='req'> advance requests</h6>
        <FontAwesomeIcon icon={faBell}className="wticon mt-2" />
<span className='noti mr-5 '>{count}</span>
        </span>}
            <Button className='btn-primary navbtn ml-5'onClick={adminMenuClick1}> + Add</Button>
        <a href="#" className="usericon"onClick={adminMenuClick2}>
            {!user1?"Admin" :'Hey, ' +Capitalize(username1)}
        
        <FontAwesomeIcon icon={faUserCircle}className="  ml-2" />
        </a>
       
        </div>
        <div className={adminMenu2 ?'adminmenu ':'adminactive'}>
           <ul>
               <li className="newbtn"onClick={reLoadPage}><Link exact to={`/account/`} ><FontAwesomeIcon icon={faUserCircle}className="mr-2" />Account</Link></li>
               <li onClick={e =>{logout()}} className="newbtn"><FontAwesomeIcon icon={faSignOut}className="mr-2" />Logout</li>
           </ul>
       </div>
       <div className={adminMenu ?' row adminmenu1 ':'adminactive'}>
           <div className='col'>
           <ul>
               <li className="newbtn"onClick={reLoadPage}><Link exact to={`/addstudent/`} ><FontAwesomeIcon icon={faGraduationCap}className="mr-2" />Student</Link></li>
               <li className="newbtn"onClick={reLoadPage}><Link exact to={`/addstaff/`} ><FontAwesomeIcon icon={faUserCircle}className="mr-2" />Staff</Link></li>
           </ul>
           </div>
           <div className='col'>
           <ul>
           <FontAwesomeIcon icon={faCaretUp}className="mr-2 anglebtn" />
               <li className="newbtn"onClick={reLoadPage}><Link exact to={`/addclass/`} ><FontAwesomeIcon icon={faHome}className="mr-2" />Class</Link></li>
               <li className="newbtn"onClick={reLoadPage}><Link exact to={`/adduser/`} ><FontAwesomeIcon icon={faUserAlt}className="mr-2" />User</Link></li>
           </ul>
           </div>

       </div>
       </Router>
        </nav>
    )
}
const mapStateToProps=state=>({
    useremail:state.auth.useremail,
    username1:state.auth.username1,
    user1:state.auth.user1,
    isAuthenticated:state.auth.isAuthenticated,
    fetchedUser:state.auth.fetchedUser,
    isadmin:state.auth.isadmin,
    username:state.auth.username1
    });

export default connect(mapStateToProps,{logout})(Adminnav)
