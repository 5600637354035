import React,{useEffect,useState} from 'react'
import axios from "axios";
import { Table } from 'react-bootstrap';
import { Card,Modal} from 'react-bootstrap';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import {Redirect,Link} from 'react-router-dom';
import {connect} from 'react-redux';
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Select1 from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
function Payroll({isAuthenticated,checkAuthenticated,useremail,payment,fetchPayment,isstaff,isadmin,username1}) {
  const [data,setData]=useState([""]);
  const [names,setName]=useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [id,setId]=useState("");
  const [edit,setEdit]=useState(false);
  const [showhide,setShowhide]=useState(false);
  const [heading,setHeading]=useState(['Names', 'Email', 'Mobile','Town','Action']);
  const [deleteid,setDeleteId]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [data1, setdata1] = useState([])
  const [selectedOptions1, setSelectedOptions1] = useState([''])
  const { ExportCSVButton } = CSVExport;
   const { SearchBar } = Search;
   const options = {
     page: 1,
     sizePerPage: 10,
     nextPageText: '>',
     prePageText: '<',
     showTotal: true
   };
   const closeModal1=()=> {
    setShowhide1(false);
    
    }
   const columns = [{
     dataField: 'employeeid',
     text: 'Emp. ID',
     headerStyle: () => {
       return { width: "150px" };
     }
   }
 ,

{
     dataField: "id",
     text: "Month",
     headerStyle: () => {
       return { width: "100px" };
     },
     csvExport: false,
     editable: false,
     formatter: (cellContent, row) => {
       return (
         <div>
          {row.month+ '/'+ row.year}
         </div>
       );
     },
 },
 {
    dataField: 'basic',
    text: 'Basic salary',
    headerStyle: () => {
      return { width: "150px" };
    }
  },
  {
    dataField: 'allowances',
    text: 'Allowances',
    headerStyle: () => {
      return { width: "150px" };
    }
  },
  {
    dataField: 'gross',
    text: 'Gross salary',
    headerStyle: () => {
      return { width: "150px" };
    }
  },
  {
    dataField: 'deductions',
    text: 'Deductions',
    headerStyle: () => {
      return { width: "150px" };
    }
  },
  {
    dataField: 'payee',
    text: 'Payee',
    headerStyle: () => {
      return { width: "150px" };
    }
  },
  {
    dataField: 'net',
    text: 'Net Pay',
    headerStyle: () => {
      return { width: "150px" };
    }
  },
 {
  dataField: "id",
  text: "Action",
  headerStyle: () => {
    return { width: "100px" };
  },
  csvExport: false,
  editable: false,
  formatter: (cellContent, row) => {
    return (
      <div>
    <div className='row pl-3'>{isadmin=="true"&&<span className='border'><i class="fa fa-trash btn1 border1" onClick={e =>{ handleModalShowHide1(row.id)}}></i></span>}<div onClick={e =>{ setId(row.idnumber);reLoadPage()}} className="border"><Link exact to={`/viewpayslip/${row.employeeid+ '-'+row.month+ '-'+ row.year}`} >
                     <span className=''><i  class="fa fa-eye btn1"></i></span></Link></div></div>
    </div>
    );
  },
}
];
const reLoadPage=()=>{
       
  window.location.reload();
}
 const renderList=()=>{
  return (data1.map(data =>({label:data.idnumber})))
} 
const handleSelectChange = (value) => {
  console.log(value.label)

  setSelectedOptions1(value);
  if(selectedOptions1.length>0){
    console.log(value)
  }
 
}

  useEffect(()=>{
    let data1 ;
    if(isadmin=="true"){
    axios({
      method:'post',
      url:'/totalpayroll/',
      data:{useremail:useremail},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
      console.log(data1)
    })
    .catch(err => {})}
    if(isstaff=="true"){
      axios({
        method:'post',
        url:'/searchpayroll1/',
        data:{owner:useremail,staff:username1},
      })
      .then(res => {
          data1 = res.data;
          setData(data1);
        console.log(data1)
      })
      .catch(err => {})}
    checkAuthenticated();
    fetchPayment(useremail)
    
      if(!payment){
        return <Redirect to='/payment'/>}
      },[]) 
      const handleModalShowHide=()=> {
        setShowhide(!showhide);
    }
    const closeModal=()=> {
      setShowhide(false);
  }
  const refreshData=()=>{
    let data1 ;

    
    axios({
      method:'post',
      url:'/totalpayroll/',
      data:{useremail:useremail},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
       
    })
    .catch(err => {})
    
  }
  const handledate = () => {
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return date;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if(!selectedOptions1.label){
   
       }else
    if(id){
     
      axios({
        method:'put',
        url:`/api/deductionnames/${id}/`,
        data:{name:names.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),owner:useremail,handledate},
      }).then((Response)=>{
        setEdit(false);
        setId('');
        //clear fields
    cleaFields();
      })
      setUpdateSuccess(true)
    }
    else{
     let dateobj= new Date()
     let month= dateobj.getUTCMonth() + 1
     let year= dateobj.getUTCFullYear()
    
   axios({
     method:'post',
     url:'/processpayroll/',
     data:{id:selectedOptions1.label,month:month,year:year,owner:useremail,handledate},
   }).then((Response)=>{
    //clear fields
    if(Response.data=='exists'){
showToastMessage1()
    }else{
      setSaveSuccess(true)
      cleaFields();
    }
   
   })
   
   
  }
  };
  const editItem = (detail) => {
    setId(detail.id);
    setEdit(true);
    setShowhide(true);
    setName(detail.name);
    setEmail(detail.email);
    setMobile(detail.mobile);
    setTown(detail.town);

  };
  const showToastMessage = () => {
    toast.success('Record deleted succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
  const showToastMessage1 = () => {
    toast.error('Payroll Record already exists', {
        position: toast.POSITION.TOP_CENTER
    });
  };  

  const handleDelete = (item) => {

    console.log(item);
    axios({
      method:'delete',
      url:`/api/payroll/${deleteid}/`,
    }).then((Response)=>{
      handleModalShowHide1()
      showToastMessage()
      refreshData();
    })
  
  };
  const cleaFields=()=>{
    setName('');
    setEmail('');
    setMobile('');
    setTown('');
    refreshData();
  }
  useEffect(()=>{
    checkAuthenticated();
        //fetch staff records
        
        axios({
          method:'post',
          url:'/totalstaffs/',
          data:{useremail:useremail},
        })
        .then((Response)=>{
         const datareceived=Response.data;
         console.log(datareceived)
        //set fields
       setdata1(datareceived)
        
          })
      },[]) 
      useEffect(()=>{
     
      },[isAuthenticated]) 
      const handleModalShowHide1=(id)=> {
        setDeleteId(id)
        setShowhide1(!showhide1);
        console.log("clicked");
      }
        if(payment=="false"){
          return <Redirect to='/payment'/>}
  return (
    <div>
      <div className='path'><i className='fa fa-home'></i>{edit? '/ Products / Edit Item':'/ Payroll / Generate '}</div>
        <ToastContainer
       hideProgressBar={true}
      />
        <div className='bodysection col-lg-9'>
        <div className=' col-lg-12 theader'>< h5>Payroll List</h5> </div>
        <Button  onClick={handleModalShowHide} className="m-2 btn-primary">
           + Payroll
       </Button>
       <Modal show={showhide}>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title>Payroll details</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record processed succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
<FormGroup>
<Label>Employee ID</Label>
<Select1
       value={selectedOptions1}
       defaultValue=""
       placeholder="Select staff"
       options={renderList()}
       onChange={ (value) => handleSelectChange(value) }
       name="ColumnSelect"
       label=""
       isClearable
/>
</FormGroup>


<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       
       required
     
     />
</FormGroup>
   
           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit(e)}>
Process
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
       <div className='bg-light p-1'>
       <ToolkitProvider
keyField="id"
data={[...data] }
columns={ columns }
exportCSV={{ onlyExportFiltered: true, exportAll: false }}
search
>
{
props => (
<div>
<ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 

<SearchBar { ...props.searchProps } />

<BootstrapTable keyField='id'
{...props.baseProps}
data={ data } 
columns={ columns } 
pagination={ paginationFactory(options) }className="pgnav"
wrapperClasses="table-responsive">  

</BootstrapTable>
</div> 
)
}
</ToolkitProvider>
</div>
           </div>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Payroll?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting an Payroll Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={handleDelete}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  isadmin:state.auth.isadmin,
  username1:state.auth.username1,
  isstaff:state.auth.isstaff,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchPayment})(Payroll)