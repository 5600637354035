import React, { useState,useEffect } from "react";
import "./Tabs.css";
import { ToastContainer, toast } from 'react-toastify';
 import './Newstaff.css'
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import  { encrypt , decrypt } from 'react-crypt-gsm';
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Select1 from 'react-select'
//import bcrypt from "bcrypt";
import axios from "axios";
import Select from 'react-dropdown-select'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import bcrypt from 'bcryptjs'
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import {Redirect,withRouter,useLocation} from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrash} from '@fortawesome/free-solid-svg-icons'

import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addstudent.css'
const Tabs = ({useremail,match,username1,id}) => {
  const [toggleState, setToggleState] = useState(1);
  const [tscnumber,setTscNumber]=useState("");
  const [tscnumberError,setTscNumberError]=useState(false);
  const [idnumber,setIdNumber]=useState("");
  const [idnumberError,setIdNumberError]=useState(false);
  const [phonenumber,setPhoneNumber]=useState("");
  const [phonenumberError,setPhoneNumberError]=useState(false);
  const [firstname,setFirstName]=useState("");
  const [firstnameError,setFNameError]=useState(false);
  const [lastname,setLName]=useState("");
  const [lastnameError,setLnameError]=useState(false);
  const [emailError,setEmailError]=useState(false);
  const [county,setCounty]=useState("");
  const [countyError,setCountyError]=useState(false);
  const [townError,setTownError]=useState(false);
  const [street,setStreet]=useState("");
  const [subjects,setSubjects]=useState("");
  const [classes,setClasses]=useState("");
  const [response,setResponse]=useState("");
  const [deleteid,setDeleteId]=useState("");
  const [streetError,setStreetError]=useState(false);
  const [notInternet,setNoInternet]=useState(false);
  const [validated,setValidated]=useState(false);
  const [validated1,setValidated1]=useState(false);
  const [validated2,setValidated2]=useState(false);
  const [success,setSuccess]=useState(false);
  const [result,setResult]=useState(false);
  const [genderError, setGenderError] = useState(false);
  const [gender, setGender] = useState("");
  const [data, setData] = useState([])
  const [data1, setdata1] = useState([])
  const [data2, setData2] = useState([])
  const [data3, setdata3] = useState([])
  const [amountvisible,setAmountVisible]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [showhide2,setShowhide2]=useState(false);
  const [amount, setAmount] = useState("");
  const [amount1, setAmount1] = useState("");
  const [filteredinvoice, setFilteredInvoice] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameError1, setNameError1] = useState(false);
  const [email,setEmail]=useState("");
  const [amountError,setAmountError]=useState(false);
  const [amountError1,setAmountError1]=useState(false);
  const [town,setTown]=useState("");
  const [id1,setId]=useState("");
  const [inumber,setInumber]=useState("hello");
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(true);
  const [editbtn1,setEditbtn1]=useState(false);
  const [savebtn1,setSavebtn1]=useState(true);
  const [wait,setWait]=useState(false);
  const [wait1,setWait1]=useState(false);
  const [hide,setHide]=useState(true);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false)
  const [last_nameError, setLnameError2] = useState(false);
  const [inputStyle4, setInputStyle4] = useState( { })
  const [salary,setSalary]=useState('');
  const [bank,setBank]=useState('');
  const [accountName,setAccountName]=useState('');
  const [kra, setKra] = useState("");
  const [nhif, setNhif] = useState("");
  const [nssf ,setNssf] = useState("");
  const [account,setAccount]=useState("");
  const [heading,setHeading]=useState(['Allowance Name','Amount','Action']);
  const [heading1,setHeading1]=useState(['Deduction Name','Amount','Action']);
  const [selectedOptions1, setSelectedOptions1] = useState([''])
  const [selectedOptions2, setSelectedOptions2] = useState([''])
  const [options4, setOptions4] = useState( [
    { id: 1, country: "Male" },
    { id: 2, country: "Female" }, 
    
  ] )
  const [showhide1,setShowhide1]=useState(false);
  const renderList=()=>{
    return (data1.map(data =>({label:data.name})))
  } 
  const renderList1=()=>{
    return (data3.map(data =>({label:data.name})))
  } 

  const toggleTab = (index) => {
    setToggleState(index);
  };
  const handleSelectChange = (value) => {
    console.log(value.label)

    setSelectedOptions1(value);
    if(selectedOptions1.length>0){
      console.log(value)
    }
   
  }

  const handleSelectChange1 = (value) => {
    setSelectedOptions2(value);
   
   
  }
  const showToastMessage = () => {
    toast.success('Record saved succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
  const showToastMessage1 = () => {
    toast.success('Record updated succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage2 = () => {

    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 
  const showToastMessage3 = () => {
    toast.success('Record deleted succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
  const deleteData = async () => {
    if(useremail=="4")
    {
showToastMessage2();
    }
    else{
    handleModalShowHide1()
       const result = await axios({
         method:'put',
         url:`/api/allowance/${deleteid}/`,
         data:{isdeleted:"deleted"}
       })
       .then((Response)=>{
        showToastMessage3()
         // setSuccess(true)
         // hidesuccess();
         refreshData()
                
       })
       .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
         
                }
        
       })
      }
       }
       const deleteData1 = async () => {
        if(useremail=="4")
        {
    showToastMessage2();
        }
        else{
        handleModalShowHide2()
           const result = await axios({
             method:'put',
             url:`/api/deduction/${deleteid}/`,
             data:{isdeleted:"deleted"}
           })
           .then((Response)=>{
            showToastMessage3()
             // setSuccess(true)
             // hidesuccess();
             refreshData1()
                    
           })
           .catch((error) => {
            if (error.code="ERR_BAD_RESPONSE"){
             
                    }
            
           })
          }
           }
    
  const handleModalShowHide1=(id)=> {
    setDeleteId(id)
    setShowhide1(!showhide1);
    console.log("clicked");
  }

  const handleModalShowHide2=(id)=> {
    setDeleteId(id)
    setShowhide2(!showhide2);
    console.log("clicked");
  }
  useEffect(()=>{
    //save 
if(validated){

//save staff
if(useremail=="4")
{
showToastMessage2();
}
else{
if(edit)
{

const editData = async () => {

const result = await axios({
method:'put',
url:`/api/staff/${match.params.id}/`,
data:{tscnumber,idnumber,phonenumber,firstname:firstname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),lastname:lastname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),email:email.toLowerCase(),county:county.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),town:town.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),
  street:street.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),gender:gender,subjects,classes,salary,account,accountname:accountName,bank,kra,nssf,nhif,owner:useremail,editedby:username1},
})
.then((Response)=>{
if (Response.data){
//setSaveSuccess(true)
setWait(false)
removeErrors()
showToastMessage1()
//setSuccess(true)
setValidated(false)
//hidesuccess();

     }
})
.catch((error) => {
if (error.code="ERR_BAD_RESPONSE"){
setShowhide2(true)
setWait(false)
setNoInternet(true)
     }

})

}
editData()
}else
{

  
const saveData = async () => {

const result = await axios({
method:'post',
url:'/api/staff/',
data:{tscnumber,idnumber,phonenumber,firstname:firstname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),lastname:lastname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),email:email.toLowerCase(),county:county.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),town:town.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),
  street:street.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),gender:gender,subjects,classes,salary,account,accountname:accountName,bank,kra,nssf,nhif,owner:useremail,postedby:username1},
})
.then((Response)=>{
if (Response.data){
 setSaveSuccess(true)
 setWait(false)
 setSavebtn(true)
 removeErrors()
 showToastMessage()
 //setSuccess(true)
 setValidated(false)
 //hidesuccess();
clearFields()
       }
})
.catch((error) => {
if (error.code="ERR_BAD_RESPONSE"){
 setShowhide2(true)
 setWait(false)
 setNoInternet(true)
       }

})

const unique_id = uuid();
const small_id = unique_id.slice(0,8);
const encryptedStr = encrypt(small_id);
 //check if user exists
 const result2 = await axios({
  method:'post',
  url:`/searchstaff/`,
  data:{username:idnumber,smallpas:small_id,password:encryptedStr.content,email:email,lastname:lastname,phone:phonenumber,schoolid:useremail,isstaff:"True",owner:useremail},

  })
  .then((Response)=>{
   
   })


}
saveData()
}
}
}
    
      },[validated]) 
      useEffect(()=>{
        //save 
    if(validated1){
    
    //save staff
    if(useremail=="4")
    {
    showToastMessage2();
    }
    else{
    if(edit)
  
    {
      
    const saveData = async () => {
   
    const result = await axios({
    method:'post',
    url:'/api/allowance/',
    data:{name:selectedOptions1.label,amount,staff:match.params.id,owner:useremail,postedby:username1},
    })
    .then((Response)=>{
    if (Response.data){
     setSaveSuccess(true)
     setWait(false)
   refreshData()
     removeErrors()
     showToastMessage()
     //setSuccess(true)
     setValidated(false)
     //hidesuccess();
    setAmount("")
    setSelectedOptions1([])
    setAmountError(false)
    setNameError(false)
           }
    })
    .catch((error) => {
    if (error.code="ERR_BAD_RESPONSE"){
     setShowhide2(true)
     setWait(false)
     setNoInternet(true)
           }
    
    })
   
    
    }
    saveData()
    }
    }
    }
        
          },[validated1]) 
          useEffect(()=>{
            //save 
        if(validated2){
        
        //save staff
        if(useremail=="4")
        {
        showToastMessage2();
        }
        else{
        if(edit)
      
        {
          
        const saveData = async () => {
       
        const result = await axios({
        method:'post',
        url:'/api/deduction/',
        data:{name:selectedOptions2.label,amount:amount1,staff:match.params.id,owner:useremail,postedby:username1},
        })
        .then((Response)=>{
        if (Response.data){
         setSaveSuccess(true)
         setWait(false)
       refreshData1()
         removeErrors()
         showToastMessage()
         //setSuccess(true)
         setValidated(false)
         //hidesuccess();
        setAmount("")
        setSelectedOptions1([])
        setAmountError(false)
        setNameError(false)
               }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
         setShowhide2(true)
         setWait(false)
         setNoInternet(true)
               }
        
        })
       
        
        }
        saveData()
        }
        }
        }
            
              },[validated2])          

      useEffect(()=>{
        //check if edit is true
        const invoicenumber = /[^/]*$/.exec(window.location.href)[0];
         if(match.params.id>=0)
         {
          setResult(true)
          setSavebtn(false)
         }
             //fetch edit records when editing
             if(result){
             //fetch student records
             axios({
              method:'post',
              url:'/staffsearch/',
              data:{adm:id},
            })
            .then((Response)=>{
             const datareceived=Response.data;
             console.log(datareceived)
            //set fields
            setTscNumber(datareceived[0].tscnumber)
            
              })
             
             //set edit
             setEdit(true);
             setEditbtn(true)
            
               //set local invoice
               setInvoiceNumber(invoicenumber)
              
             }else{
              
              
             }
        
          },[])
        
          useEffect(()=>{
        if(result){
         //fetch student records
         axios({
          method:'post',
          url:'/staffsearch/',
          data:{id:match.params.id},
        })
        .then((Response)=>{
         const datareceived=Response.data;
         console.log(datareceived)
        //set fields
        setTscNumber(datareceived[0].tscnumber)
        setIdNumber(datareceived[0].idnumber)
        setPhoneNumber(datareceived[0].phonenumber)
        setFirstName(datareceived[0].firstname)
        setLName(datareceived[0].lastname)
        setCounty(datareceived[0].county)
        setTown(datareceived[0].town)
        setStreet(datareceived[0].street)
        setEmail(datareceived[0].email)
        setSubjects(datareceived[0].subjects)
        setClasses(datareceived[0].classes)
        setGender(datareceived[0].gender)
        setSalary(datareceived[0].salary)
        setBank(datareceived[0].bank)
        setAccount(datareceived[0].account)
        setAccountName(datareceived[0].accountname)
        setKra(datareceived[0].kra)
        setNssf(datareceived[0].nssf)
        setNhif(datareceived[0].nhif)
        
          })
         //set edit
         setEdit(true);
         setEditbtn(true)
        
        
        }
     
            //fetch allowance records
        
         axios({
          method:'post',
          url:'/totalallowancenames/',
          data:{useremail:useremail},
        })
        .then((Response)=>{
         const datareceived=Response.data;
         console.log(datareceived)
        //set fields
       setdata1(datareceived)
        
          })
            //fetch deduction records
        
         axios({
          method:'post',
          url:'/totaldeductionnames/',
          data:{useremail:useremail},
        })
        .then((Response)=>{
         const datareceived=Response.data;
         console.log(datareceived)
        //set fields
       setdata3(datareceived)
        
          })
          refreshData()
          refreshData1()
          },[result])
          const refreshData=()=>{
    //fetch allowance records
        
    axios({
      method:'post',
      url:'/allowancesearch/',
      data:{useremail:useremail,staff:match.params.id},
    })
    .then((Response)=>{
     const datareceived=Response.data;
     console.log(datareceived)
    //set fields
    setData(datareceived)
    
      })

          }

          const refreshData1=()=>{
            //fetch allowance records
                
            axios({
              method:'post',
              url:'/deductionsearch/',
              data:{useremail:useremail,staff:match.params.id},
            })
            .then((Response)=>{
             const datareceived=Response.data;
             console.log(datareceived)
            //set fields
            setData2(datareceived)
            
              })
        
                  }        
      const removeErrors=()=>{
        setTscNumberError(false)
        setPhoneNumberError(false)
        setIdNumberError(false)
        setFNameError(false)
        setLnameError(false)
        setCountyError(false)
        setTownError(false)
        setStreetError(false)
        setEmailError(false)
      }    
      const clearFields=()=>{
        setTscNumber('')
        setPhoneNumber('')
        setIdNumber('')
        setFirstName('')
        setLName('')
        setCounty('')
        setTown('')
        setStreet('')
        setEmail('')
        setSubjects('')
        setClasses('')
        setSalary('')
        setBank('')
        setAccount('')
        setAccountName('')
        setKra('')
        setNssf('')
        setNhif('')
      }
      const reLoadPage=()=>{
       
        window.location.reload();
    }
     
  const validateFields=(e)=>{
    e.preventDefault()
    if(tscnumber && idnumber && phonenumber && firstname && lastname && email && town && county && street && !gender.trim().length==0 &&
    gender!=="Select Gender"){
      setValidated(true)}
    
    else{
      if(tscnumber.trim().length==0){
        setTscNumberError(true)
       
        }
        if(gender.trim().length==0){
          setGenderError(true)
          }
        if(phonenumber.trim().length==0){
          setPhoneNumberError(true)
         
          }
          if(idnumber.trim().length==0){
            setIdNumberError(true)
           
            }
            if(firstname.trim().length==0){
              setFNameError(true)
             
              }
              if(lastname.trim().length==0){
                setLnameError(true)
               
                }
                if(county.trim().length==0){
                  setCountyError(true)
                 
                  }
                  if(town.trim().length==0){
                    setTownError(true)
                   
                    }
                    if(street.trim().length==0){
                      setStreetError(true)
                     
                      }
                      if(email.trim().length==0){
                        setEmailError(true)
                       
                        }
    }
  }
  const validateFields1=(e)=>{
    
    e.preventDefault()
    if(amount && selectedOptions1 ){
      setValidated1(true)
     
    }
    
    else{
      if(amount.trim().length==0){
        setAmountError(true)
       
        }
        if(!selectedOptions1.length==0){
         setNameError(true)
          }
       
         
    }
  }

  const validateFields2=(e)=>{
    
    e.preventDefault()
    if(amount1 && selectedOptions2 ){
      setValidated2(true)
     
    }
    
    else{
      if(amount1.trim().length==0){
        setAmountError1(true)
       
        }
        if(!selectedOptions2.length==0){
         setNameError1(true)
          }
       
         
    }
  } 
  const closeModal1=()=> {
    setShowhide1(false);
    
    }
    const closeModal2=()=> {
      setShowhide2(false);
      
      }
  const onOptionChangeHandler3 = (event) => {
    setGender(event.target.value) 
  } 
  const getActiveClass = (index, className) =>
    toggleState === index ? className : "";

  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
       <div className='path'><i className='fa fa-home'></i>/ Staff / Add Staff</div>  
    <div className="container bodysection4 col-md-7">
      <ul className="tab-list">
        <li
          className={`tabs ${getActiveClass(1, "active-tabs")}`}
          onClick={() => toggleTab(1)}
        >
          Staff Details
        </li>
        <li
          className={`tabs ${getActiveClass(2, "active-tabs")}`}
          onClick={() => toggleTab(2)}
        >
          Allowances
        </li>
        <li
          className={`tabs ${getActiveClass(3, "active-tabs")}`}
          onClick={() => toggleTab(3)}
        >
         Deductions
        </li>
      </ul>
      <div className="content-container">
        <div className={`content1 ${getActiveClass(1, "active-content")}`}>
        <Form >
  <div className='row pl-4'>
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input type="number"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
</FormGroup>}
<FormGroup className='mr-2 ml-2'>

<Input className={tscnumberError &&'errorborder'} type="text" placeholder="TSC Number"value={tscnumber} onChange={e => setTscNumber(e.target.value)} name="email"  required/>
<div className={tscnumberError ?'errmessage1':'errmessagehide1'}>TSC Number is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={idnumberError &&'errorborder'} type="number" placeholder="ID Number"name="author" value={idnumber} onChange={e => setIdNumber(e.target.value)} required />
<div className={idnumberError ?'errmessage1':'errmessagehide1'}>ID Number is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input  className={phonenumberError &&'errorborder'} type="number" placeholder="Phone Number"name="author" value={phonenumber} onChange={e => setPhoneNumber(e.target.value)} required />
<div className={phonenumberError ?'errmessage1':'errmessagehide1'}>Phone Number is Reguired</div>
</FormGroup>

  
 
 {amountvisible && <FormGroup className='mr-5 ml-3'>
 <Label>Amount</Label>
 <Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
 </FormGroup>}
 <FormGroup className='mr-2 ml-2'>
 
 <Input className={firstnameError &&'errorborder'} type="text" placeholder="First Name"value={firstname} onChange={e => setFirstName(e.target.value)} name="email"  required/>
 <div className={firstnameError ?'errmessage':'errmessagehide'}>First Name is Reguired</div>
 </FormGroup>
 <FormGroup className='mr-2 ml-2'>
 <Input className={lastnameError &&'errorborder'} type="text" placeholder="Last Name"name="author" value={lastname} onChange={e => setLName(e.target.value)} required />
 <div className={lastnameError ?'errmessage':'errmessagehide'}>Last Name is Reguired</div>
 </FormGroup>
 <FormGroup className='mr-2 ml-2'>
 <Input className={emailError &&'errorborder'} type="text" placeholder="Email"name="author" value={email} onChange={e => setEmail(e.target.value)} required />
 <div className={emailError ?'errmessage':'errmessagehide'}>Email is Reguired</div>
 </FormGroup>
 
   </div>
  <div className='col-lg-6 ml-2 studenthealth' >
 
</div>
 
 
   <div className='row pl-4'>
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
</FormGroup>}
<FormGroup className='mr-2 ml-2'>

<Input className={countyError &&'errorborder'} type="text" placeholder="County"value={county} onChange={e => setCounty(e.target.value)} name="email"  required/>
<div className={countyError ?'errmessage1':'errmessagehide1'}>County is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={townError &&'errorborder'} type="text" placeholder="Town"name="author" value={town} onChange={e => setTown(e.target.value)} required />
<div className={townError ?'errmessage1':'errmessagehide1'}>Town is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={streetError &&'errorborder'} type="text" placeholder="Street"name="author" value={street} onChange={e => setStreet(e.target.value)} required />
<div className={streetError ?'errmessage1':'errmessagehide1'}>Street is Reguired</div>
</FormGroup>

<FormGroup className='mr-2 ml-2 '>
<div style={{width:'220px'}}className={last_nameError &&'errorborder'} >
              
          <select onChange={onOptionChangeHandler3} className={genderError ? 'errorborder newselect1':'newselect'}>
 
 <option>{edit && gender.trim().length !== 0?  gender :'Select Gender'}</option>
 {options4.map((option, index) => {
     return <option key={index} >
         {option.country}
     </option>
 })}
</select>    
          
            </div>
<div className={genderError ?'errmessage1':'errmessagehide1'}>Gender is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>

<Input className={countyError &&''} type="text" placeholder="Basic Salary"value={salary} onChange={e => setSalary(e.target.value)} name="email"  required/>
<div className={townError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={townError &&''} type="text" placeholder="Bank name"name="author" value={bank} onChange={e => setBank(e.target.value)} required />
<div className={townError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={streetError &&''} type="text" placeholder="Account name"name="author" value={accountName} onChange={e => setAccountName(e.target.value)} required />
<div className={streetError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>

<Input className={countyError &&''} type="text" placeholder="Account number"value={account} onChange={e => setAccount(e.target.value)} name="email"  required/>
<div className={countyError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={townError &&''} type="text" placeholder="Kra Pin"name="author" value={kra} onChange={e => setKra(e.target.value)} required />
<div className={townError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={streetError &&''} type="text" placeholder="NSSF No."name="author" value={nssf} onChange={e => setNssf(e.target.value)} required />
<div className={streetError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={streetError &&''} type="text" placeholder="NHIF No."name="author" value={nhif} onChange={e => setNhif(e.target.value)} required />
<div className={streetError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
  </div>
  <div className='pl-3 col-md-7 mt-2'>
  
  <FormGroup className=' '>
<Input width="300px"type="textarea" placeholder="Enter subjects taught and separate them with a comma"value={subjects} onChange={e => setSubjects(e.target.value)} name="email"  required/>
</FormGroup>
  </div>

  <div className='col-md-7 pl-3 ' >
  <FormGroup className=' '>
<Input width="300px"type="textarea" placeholder="Classes staff is a classteacher"value={classes} onChange={e => setClasses(e.target.value)} name="email"  required/>
</FormGroup>

  </div>
      <div className='row pl-4'>
  <Button color="primary"className=" ml-2 mb-3" type="submit"onClick={(e)=> validateFields(e)}>
      {editbtn && "Update"}
      {savebtn && "Submit"}
      {wait && " Please wait.."}
    </Button>  
    
    </div>
    </Form>
        </div>
        <div className={`content1 ${getActiveClass(2, "active-content col-md-12")}`}>
          <div className="row col-md-12"><div className="col-md-6"><h6>Add Allowance</h6>
          <Form >
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input  type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />

</FormGroup>}
<FormGroup className='mr-2 ml-2'>
<Label>Name</Label>
<Select1
       value={selectedOptions1}
       defaultValue=""
       placeholder="Select staff"
       options={renderList()}
       onChange={ (value) => handleSelectChange(value) }
       name="ColumnSelect"
       label=""
       isClearable
/>
<div className={nameError ?'errmessage':'errmessagehide'}>Name is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>Amount</Label>
<Input className={amountError &&'errorborder'} type="number" placeholder="Amount"value={amount} onChange={e => setAmount(e.target.value)} name="email"  required/>
<div className={amountError ?'errmessage':'errmessagehide'}>Amount is Reguired</div>
</FormGroup>
 
    
  <Button color="primary"className=" mb-3 ml-2" type="submit"onClick={(e)=> validateFields1(e)}>
      {editbtn && "Submit"}
      {savebtn && "Submit"}
      {wait && " Please wait.."}
    </Button>  

  
    </Form>
   
    </div>
   
          <div className="col-md-6">Added Allowances
          <div class="table-responsive bg-light col-md-12 mb-1">
         
<Table className=" pl-0">
  <thead>
    <tr >
    {heading.map(head => <th className="col-3">{head}</th>)}
    </tr>
  </thead>
 
                <tbody>     
                {data.length==0? <div>No Records found</div>:data.map(detail => (
                  <tr key={detail.id} >
                
                     <td className="col-3" >{ detail.name}</td>
                     <td className="col-3" >{ detail.amount}</td> 
                     <td class="col-2"><div className='row ml-1'><span className='border'><FontAwesomeIcon icon={faTrash}className="btn1 border1" onClick={e =>{ handleModalShowHide1(detail.id)}}/></span></div></td>
                   </tr>
             ))}
    </tbody>
    
     
           </Table> 
           
           </div>
          
           </div>
          
          </div>
        
        </div>
        <div className={`content1 ${getActiveClass(3, "active-content")}`}>
        <div className="row col-md-12"><div className="col-md-6"><h6>Add Deduction</h6>
          <Form >
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input  type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />

</FormGroup>}
<FormGroup className='mr-2 ml-2'>
<Label>Name</Label>
<Select1
       value={selectedOptions2}
       defaultValue=""
       placeholder="Select staff"
       options={renderList1()}
       onChange={ (value) => handleSelectChange1(value) }
       name="ColumnSelect"
       label=""
       isClearable
/>
<div className={nameError1 ?'errmessage':'errmessagehide'}>Name is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>Amount</Label>
<Input className={amountError1 &&'errorborder'} type="number" placeholder="Amount"value={amount1} onChange={e => setAmount1(e.target.value)} name="email"  required/>
<div className={amountError1 ?'errmessage':'errmessagehide'}>Amount is Reguired</div>
</FormGroup>
 
    
  <Button color="primary"className=" mb-3 ml-2" type="submit"onClick={(e)=> validateFields2(e)}>
      {editbtn && "Submit"}
      {savebtn && "Submit"}
      {wait && " Please wait.."}
    </Button>  

  
    </Form>
   
    </div>
   
          <div className="col-md-6">Added Deductions
          <div class="table-responsive bg-light col-md-12 mb-1">
         
<Table className=" pl-0">
  <thead>
    <tr >
    {heading1.map(head => <th className="col-3">{head}</th>)}
    </tr>
  </thead>
 
                <tbody>     
                {data2.length==0? <div>No Records found</div>:data2.map(detail => (
                  <tr key={detail.id} >
                
                     <td className="col-3" >{ detail.name}</td>
                     <td className="col-3" >{ detail.amount}</td> 
                     <td class="col-2"><div className='row ml-1'><span className='border'><FontAwesomeIcon icon={faTrash}className="btn1 border1" onClick={e =>{ handleModalShowHide2(detail.id)}}/></span></div></td>
                   </tr>
             ))}
    </tbody>
    
     
           </Table> 
           
           </div>
          
           </div>
          
          </div>
        </div>
      </div>
    </div>
    <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Allowance?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting an Allowance Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={deleteData}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
       <div className='col-lg-1 centermodal'>
           <Modal show={showhide2} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Deduction?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting an Deduction Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={deleteData1}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal2}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
    </div>
  );
};

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Tabs))