import { Redirect } from 'react-router-dom';
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    LOGOUT,
    IS_ACTIVE,
    NOT_ACTIVE,
    INVOICENUMBER_SET,
    INVOICENUMBER_REMOVE,
    USER_SUCCESS,
    USER_FETCHED,
    PAYMENT_FAIL,
    PAYMENT_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL
} from '../actions/types'

const initialState={
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: localStorage.getItem("authenticated"),
    user:null,
    isActive: null,
    id:localStorage.getItem('id'),
    useremail:localStorage.getItem("useremail"),
    user1:localStorage.getItem("user1"),
    username1:localStorage.getItem("username"),
    email1:localStorage.getItem("email"),
    isadmin:localStorage.getItem("isadmin"),
    isstaff:localStorage.getItem("isstaff"),
    isparent:localStorage.getItem("isparent"),
    isprimary:localStorage.getItem("isprimary"),
    students:localStorage.getItem("students"),
    staff:localStorage.getItem("staff"),
    classes:localStorage.getItem("classes"),
    dorms:localStorage.getItem("dorms"),
    exams:localStorage.getItem("exams"),
    finance:localStorage.getItem("finance"),
    library:localStorage.getItem("library"),
    sms:localStorage.getItem("sms"),
    payroll:localStorage.getItem("payroll"),
    users:localStorage.getItem("users"),
    loginCheck:null,
    fetchedUser:localStorage.getItem("adminname"),
    payment:localStorage.getItem("payment"),
    resetpassword:null,
    clicked:null,
    preset:localStorage.getItem("preset"),
};
export default function(state= initialState,action){
    
    const {type,payload}= action;
    switch(type) {
        case AUTHENTICATED_SUCCESS:
            localStorage.removeItem('authenticated');
            localStorage.setItem('authenticated', 'true');  
            return{
                ...state,
                
            }
        case  LOGIN_SUCCESS:
            console.log("hello")
            localStorage.removeItem('authenticated');
            localStorage.setItem('authenticated', 'true');  
            
        return{
            ...state,
            isAuthenticated:"true",
            access:payload.access,
            refresh: payload.refresh,
            useremail:localStorage.getItem("useremail"),
            user1:localStorage.getItem("user1"),
            email1:localStorage.getItem("email"),
            username1:localStorage.getItem("username"),
            isadmin:localStorage.getItem("isadmin"),
            isprimary:localStorage.getItem("isprimary"),
            isstaff:localStorage.getItem("isstaff"),
            loginCheck:true
        }
       
    
        case  LOGIN_FAIL:
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        localStorage.removeItem('useremail');
        localStorage.removeItem('authenticated');
        localStorage.removeItem('adminname');
        localStorage.setItem('authenticated', "false"); 
        return{
          ...state,
          access:null,
          refresh:null,
          isAuthenticated:"false",
          user:null,
          useremail:null,
          loginCheck:false

        }
        case  LOGOUT:
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        localStorage.removeItem('useremail');
        localStorage.removeItem('authenticated');
        localStorage.removeItem('adminname');
        localStorage.setItem('authenticated', "false"); 
        return{
          ...state,
          access:null,
          refresh:null,
          isAuthenticated:"false",
          user:null,
          useremail:null,
          loginCheck:null

        }
        case AUTHENTICATED_FAIL:
            localStorage.removeItem('useremail');
            
            return{
                ...state,
                isAuthenticated:"false"
            }
            case PASSWORD_RESET_SUCCESS:
              return{
                ...state,
                clicked:true,
                preset:true
            }
            case PASSWORD_RESET_FAIL:
              return{
                ...state,
                clicked:false
            }
            case PAYMENT_SUCCESS:
              localStorage.removeItem('payment');
              localStorage.setItem('payment', JSON.stringify(true));
            return{
                ...state,
                payment:"true"
            }
            case PAYMENT_FAIL:
              localStorage.removeItem('payment');
              localStorage.setItem('payment', JSON.stringify(false));
              return{
                  ...state,
                  payment:"false"
              }
        case  USER_SUCCESS:
        return{
          ...state,
         user:payload
        }
        case  USER_LOADED_FAIL:
        return{
          ...state,
         user:null
        }
        case  PASSWORD_RESET_CONFIRM_FAIL:
        return{
          ...state,
         resetpassword:false
        }
        case  PASSWORD_RESET_CONFIRM_SUCCESS:
          localStorage.removeItem('preset');
        return{
          ...state,
         resetpassword:true,
         
        }
        case  USER_FETCHED:
        return{
          ...state,
         fetchedUser:localStorage.getItem("adminname")
        }
        case  IS_ACTIVE:
            return{
              ...state,
             isActive:"active1"
            }
            case  INVOICENUMBER_SET:
                return{
                  ...state,
                 id:localStorage.getItem('id')
                
                }
                case  INVOICENUMBER_REMOVE:
                return{
                  ...state,
                 invoicenumber:null
                 
                }
        case  NOT_ACTIVE:
            return{
              ...state,
             isActive:null
            }
    
        default:
            return state;
    }
    
}