import React, {useState,useEffect,useRef} from 'react'
import {connect} from 'react-redux';
import "./Login.css"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
 
} from "reactstrap";
import { v4 as uuid } from 'uuid';
import  { encrypt , decrypt } from 'react-crypt-gsm';
import { checkAuthenticated,load_user,login,fetchUser,resetPassword} from './../actions/auth';
import {Link, Redirect} from 'react-router-dom';
import validator from 'validator'
function Login({login, isAuthenticated,checkAuthenticated,load_user,user,loginCheck,fetchUser,resetPassword,clicked }) {
  const [formData,setformData]=useState({
    email:'',
    password:''
       });
       const [error,setError]=useState()
       const [requestSent,setRequestSent]=useState(false)
       const [clicked2,setClicked]=useState(false)
       const [emailError,setEmailError]=useState({})
       const [passwordError,setPasswordError]=useState({})
       const {email,password}= formData;
       useEffect(()=>{
        
        
          },[]) 
          
          useEffect(()=>{   
            if(email && password && !loginCheck && !email.trim().length==0 && !password.trim().length==0){
              
              setClicked(true)}
          },[loginCheck]) 
      
    if(isAuthenticated=="true"){
     return <Redirect to='/'/>}
       const onChange=(e)=>setformData({...formData,[e.target.name]:e.target.value});
       const onSubmit=e=>{
           e.preventDefault();
           setClicked(false)
           const isValid=formValidation();
           if(isValid){
            //generate token
            const unique_id = uuid();
            const small_id = unique_id.slice(0,8);
            const encryptedStr = encrypt(small_id);
           resetPassword(email,encryptedStr.content);
           setRequestSent(true)
           setError(isAuthenticated);
           setClicked(true)
          }
          
           }

           
      const formValidation=()=>{
    const emailErr={};
    const passwordErr={};
    let isValid=true;
    if(!email){
     
      emailErr.emailShort="Email is required";
      isValid=false;
    }
    if( email && !email.trim().length==0 && !validator.isEmail(email)){
      emailErr.emailInvalid="Enter a valid Email";
      isValid=false;
    }
    setEmailError(emailErr);

    return isValid
      }
  return (
    <div>
        <div className='loginscreen col-lg-4 '>
<div className=' mt-3  '><h3><b>Password Reset</b></h3></div>
<form onSubmit={e=>onSubmit(e)}> 
        <FormGroup>
        If you forgot your password, enter your email below and we'll send you an email to help you reset it.       

<Input type="text" placeholder="Enter Email"name="email"  value={email}
onChange={e=>onChange(e)} />
</FormGroup>
{Object.keys(emailError).map((key)=>{
  return <div style={{color:"red"}}>{emailError[key]}</div>
})}

<FormGroup>
  <Button className='primary'>Reset</Button>
</FormGroup>
{clicked && <h6 className='error1' style={{color:"blue"}} >You will receive an email with instructions about 
how to reset your password in a few minutes.</h6>}
{clicked==false && <h6 className='error1' style={{color:"red"}} >Oops... email not found</h6>}

</form>
</div>
    </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  user:state.auth.user,
  clicked:state.auth.clicked,
  loginCheck:state.auth.loginCheck
  });

export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchUser,resetPassword})(Login)