import React,{useEffect,useState} from 'react'
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Vinvoice.css'
import {toast} from 'react-toastify';
import './Viewinv.css'
import { TailSpin} from  'react-loader-spinner'
  import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {connect} from 'react-redux';
import { setId} from './../actions/auth';
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Table } from 'react-bootstrap';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import { Card,Modal} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Cinvoice from './Cinvoice';
import jsPDF from "jspdf";
import "jspdf-autotable";

const Vinvoice=({isAuthenticated,checkAuthenticated,setId,useremail,payment,fetchPayment})=> {
  const [dropdown,setDropDown]=useState("");
  const [loading,setLoading]=useState(false);
  const [notFound,setNotFound]=useState(false);
  const [successicon,setSuccessIcon]=useState(false);
  const [nosms,SetNoSms]=useState(false);
  const [emailSuccess,setEmailSuccess]=useState(false);
  const [messageSuccess,setMessageSuccess]=useState(false);
  const [message,setMessage]=useState(false);
  const [loadspinner,setLoadspinner]=useState(false);
  const [total,setTotal]=useState("");
  const [search,setSearch]=useState("");
  const [details,setDetails]=useState([""]);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [data,setData]=useState([""]);
  const [data2,setData2]=useState([""]);
  const [data4,setData4]=useState([""]);
  const [data5,setData5]=useState([""]);
  const [customer,setCustomer]=useState([""]);
  const [data1,setData1]=useState([""]);
  const [data3,setData3]=useState([""]);
  const [invoices,setInvoices]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [notreachable,setNotReachable]=useState(false);
  const [studentadm, setStudentadm] = useState("");
  const [email, setEmail] = useState("");
  const [town, setTown] = useState("");
  const [names, setNames] = useState("");
  const [dataReceived,setDataReceived]=useState([]);
  const [heading,setHeading]=useState(['Invoice No.', 'Issue date.', 'Student Adm','Total.','Balance','Action']);
  const [percentage, setPercentage] = useState(0);
  const [inputFields, setInputFields] = useState([
    
  ])
  const [inputFields1, setInputFields1] = useState([
    
  ])
  const [books, setBooks] = useState([
    
  ])
  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;
  const options = {
    page: 1,
    sizePerPage: 10,
    nextPageText: '>',
    prePageText: '<',
    showTotal: true
  };

  const columns = [{
    dataField: 'invoice',
    text: 'Invoice No.',
    headerStyle: () => {
      return { width: "120px" };
    }
  },  {
    dataField: 'duedate',
    text: 'Due Date',
    headerStyle: () => {
      return { width: "120px" };
    }
  }, {
    dataField: 'adm',
    text: 'Student Adm',
    headerStyle: () => {
      return { width: "100px" };
    }
  }, {
    dataField: 'names',
    text: 'Name',
    headerStyle: () => {
      return { width: "120px" };
    }
  }, {
    dataField: 'total',
    text: 'Total',
    headerStyle: () => {
      return { width: "120px" };
    }
  }, {
    dataField: 'balances',
    text: 'Balance',
    headerStyle: () => {
      return { width: "120px" };
    }
  },  {
    dataField: "id",
    text: "Action",
    csvExport: false,
    editable: false,
    headerStyle: () => {
      return { width: "100px" };
    },
    formatter: (cellContent, row) => {
      return (
        <div>
       <div className='row '><div onClick={e =>{ setId(row.adm);reLoadPage()}} className="border"><Link exact to={`/editinvoice/${row.invoice}`} >
                     <span className=''><i  class="fa fa-edit btn1"></i></span></Link></div><div onClick={e =>{ setId(row.adm);reLoadPage()}} className="border"><Link exact to={`/viewinvoice/${row.invoice}`} >
                     <span className=''><i  class="fa fa-eye btn1"></i></span></Link></div>
                    
                     <span className='border'><i className="fa fa-envelope btn1 pl-0"onClick={e =>{ sendPdf(row)}}></i></span>
                     <span className='border'><i text="send sms"class="fa fa-comment btn1"onClick={e =>{ sendSms(row)}}></i></span></div>
      </div>
      );
    },
},];

  useEffect(() => {
    setTimeout(() => {
      if (percentage < 100) {
        setPercentage(percentage + 1);
       
      }
    }, 25);
    if(percentage==100){
      
      setPercentage(0);
    }
  }, [percentage]);
  toast.configure()
  const showToast = () => toast("Operation succesful",{position: toast.POSITION.TOP_CENTER});
  useEffect(()=>{
    
     //fetch total invoices
     
     fetchAllInvoices()
    //fetch sales
 
      //fetch account details
      let data1 ;

      axios({
        method:'post',
        url:'/totalcompany/',
        data:{useremail:useremail},
      })
      .then(res => {
          data1 = res.data;
          setData2(data1);
        }
      )
    
      },[]) 
     
      useEffect(()=>{
   
   
  
         },[data4,data1,data3])

      const closeModal1=()=> {
        setShowhide1(false);
        setNotFound(false)
        setMessage(false)
        setMessageSuccess(false)
        setEmailSuccess(false)
        setSuccessIcon(false)
        SetNoSms(false)
        setNotReachable(false)
        }
      const createPdf=(data)=>{
        const filtered1 = data4.filter(data => {
          return data.invoice === data.invoicenumber;
        });
var adm= filtered1.studentadm

        axios({
          method:'post',
          url:'/pdfcreate/',
          data:{email:useremail,phone:data.customerid,invoicenumber:data.invoicenumber,issuedate:data.issuedate,duedate:data.duedate,status:data.status},
        })
        .then((Response)=>{
         //clear fields
         const file = new Blob([Response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
        })
      }
      const handleModalShowHide1=()=> {
        setShowhide1(!showhide1);
        console.log("clicked");
      }
      const sendPdf=(data)=>{
        
        setLoadspinner(true)
        handleModalShowHide1()
        axios({
          method:'post',
          url:'/upload1/',
          data:{email:useremail,adm:data.adm,invoicenumber:data.invoice,issuedate:data.issuedate,duedate:data.duedate,status:data.status},
        }).then((Response)=>{
          if (Response.data="success"){
            setEmailSuccess(true)
            setLoadspinner(false)
            setSuccessIcon(true)
                  }
        })
        .catch((error) => {
          if (error.code="ERR_BAD_RESPONSE"){
    setNotFound(true)
    setLoadspinner(false)
          }
          
        })
      }
      
      const sendSms=(data1)=>{
       
        setMessage(true)
     handleModalShowHide1()
     setLoadspinner(true)
    axios({
      method:'post',
      url:'/smssend/',
      data:{email:useremail,phone:data1.adm,invoicenumber:data1.invoice,duedate:data1.duedate},
    })
    .then((response) => {
      console.log(response.data)
        if(response.data=="failed"){
           setNotReachable(true)
            }
            if (response.data=="success"){
              console.log(response.data)
              setMessageSuccess(true)
              setSuccessIcon(true)
              setLoadspinner(false)
                    }
                    if (response.data=="nosms"){
            
              setSuccessIcon(false)
              SetNoSms(true)
    }})
    .catch((error) => {
      if (error.code="ERR_BAD_RESPONSE"){
setNotFound(true)
setLoadspinner(false)
      }
      
    })
    
      }
      const reLoadPage=()=>{
       
        window.location.reload();
    }
    const getNames=(id)=>{
  
      const filtered = data4.filter(data => {
        return data.studentadm === id;
      });
      console.log()
      
      return filtered[0].first_name+" "+filtered[0].last_name}
    const fetchAllInvoices=async()=>{
      setLoading(true)
      let copy1=[];
      let data4 ;
      const rec=await axios({
       method:'post',
       url:'/totalinvoices1/',
       data:{useremail:useremail},
     })
     .then(res => {
         data4 = res.data;
         setData(data4);
         setInputFields1(data4)
         console.log(data4) 
         setDetailsbkp(data4);
         setLoading(false)
         let newfield;
         if(data1.length>0){
          //alert(data1.length)
         }
          
         setLoading(false)  
     })
     .catch(err => {})
    
    }
    

       
    //funtion to generate pdfs
    // define a generatePDF function that accepts a tickets argument
    const generatePDF = async (invoices) => {
  setInvoices(invoices);
//fetch data
try {
  
  console.log(dataReceived)
  const resp = await axios.post('/salesearch/', {invoicenumber:invoices.invoicenumber});
  console.log(resp.data);
  setDataReceived(resp.data)
    console.log(dataReceived)

} catch (err) {
  // Handle Error Here
  console.error(err);
}
//fetch customer
axios({
  method:'post',
  url:'/customersearch/',
  data:{mobile:invoices.customerid},
})
.then((Response)=>{
 const datareceived=Response.data;
setCustomer(datareceived)
})
  
};
const filterList = (event) => {
 
  var updatedList = detailsbkp;
  updatedList = updatedList.filter(detail => detail.duedate.includes(search) | detail.invoice.toLowerCase().includes(search.toLowerCase()) | detail.adm.toLowerCase().includes(search.toLowerCase())| detail.names.toLowerCase().includes(search.toLowerCase()))
  setData(updatedList)
 //alert(updatedList.length)
  };
useEffect(() => {
  if(dataReceived.length!=0){
// define the columns we want and their titles
const tableColumn = ["Product", "Quantity", "Price", "Total"];
// define an empty array of rows
const tableRows = [];

// for each ticket pass all its data into an array
dataReceived.forEach(data => {
  const ticketData = [
    data.product,
    data.quantity,
    data.price,
    data.quantity*data.price
   
  ];
 
  // push each tickcet's info into a row
  tableRows.push(ticketData);
 
});
// initialize jsPDF
const doc = new jsPDF();
//get the total
const res=dataReceived.reduce((prev,item)=>{
         
  return prev +(item.price*item.quantity);
},0)

const total = [
  "",
  "",
  "Total",
  res
 
];

tableRows.push(total);
tableRows.push(doc.line(20,20,20,20));

// startY is basically margin-top
doc.autoTable(tableColumn, tableRows,{ startY: 100 });
let finalY = doc.previousAutoTable.finalY; // The y position on the page
doc.line(128, finalY-15, 170, finalY-15);
doc.line(128, finalY-8, 170, finalY-8);
const date = Date().split(" ");
// we use a date string to generate our filename.
const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
// ticket title. and margin-top + margin-left

doc.text("INVOICE.", 100, 15);
doc.line(20, 22, 200, 22);
doc.line(20, 59, 200, 59);
doc.setFontSize(12);
doc.text(` ${data2[0].names}`, 20,30 );
doc.text(` ${data2[0].town}`, 20, 36);
doc.text(` ${data2[0].building} Building`, 20, 42);
doc.text(` ${'0'+data2[0].mobile}`, 20, 48);
doc.text(` ${useremail}`, 20, 54);
doc.text("Bill to:", 20, 64);
doc.text(`Invoice Number: ${invoices.invoicenumber}`, 120,65);
doc.text(`Issue Date: ${invoices.issuedate}`, 120,72);
doc.text(`Due Date: ${invoices.duedate}`, 120,79);
doc.text(`Status: ${invoices.status}`, 120,86);
doc.text(`${customer[0].names}`, 20,70);
doc.text(` ${customer[0].email}`,20,77);
doc.text(`${'0'+customer[0].mobile}`, 20,84);
doc.text(`${invoices.town?invoices.town:''}`, 20,91);
// we define the name of our PDF file.
doc.save(`invoice_${dateStr}.pdf`);


}}, [dataReceived && customer]);
useEffect(()=>{
  checkAuthenticated();
  fetchPayment(useremail)
    },[]) 
    useEffect(()=>{
      
      
        },[data]) 
   
useEffect(()=>{

  if(data1.length!=0){
  //calculate total sales
  setTotal(0)
  const res=data.reduce((prev,item)=>{
         
   return prev +(item.total);
 },0)
 setTotal(res)
  }
       },[data])
const getTotalInvoice=(inv)=>{
  const filtered = data1.filter(data => {
    return data.invoicenumber === inv;
  });
  const res=filtered.reduce((prev,item)=>{
         
    return prev +(item.price*item.quantity);
  },0)
  return res;
}
const getTotalBalances=(inv)=>{
 
  const res=data.reduce((prev,item)=>{
         
    return prev +(item.balances);
  },0)

  return res 
}
const getTotalBalances1=(inv)=>{

  const res=data.reduce((prev,item)=>{
         
    return prev +(item.balances);
  },0)

  return res 
}

if(isAuthenticated=="false"){
  
  return <Redirect to='/login'/>}
  if(payment=="false"){
  
    return <Redirect to='/payment'/>}
    const value = 0.66;
    
  return (
    <div>
        <div className='path'><i className='fa fa-home'></i>/ Finance / View Invoices</div>
      <Router>
        <div className='bodysection col-lg-8'>
        <div className=' col-lg-12 theader'>< h5>Invoices List</h5> </div> <div className='row pl-3'><FormGroup className='mr-5'>{loading &&<span className='' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>loading...</span>}


</FormGroup>
<FormGroup className='mr-2 searchlft'>
<Input type="text" placeholder="Search..."name="town" value={search} onChange={e =>{ setSearch(e.target.value);filterList()}}onKeyDown={filterList}onKeyUp={filterList} required />
        
</FormGroup>
<FormGroup className='mr-1'>
<Label className='mt-2'>Invoices:</Label>    
</FormGroup>
<FormGroup className='mr-2'>
<Label className='mt-2 pl-2'>{data.length>0?'Kshs.'+' '+total:0}</Label>    
</FormGroup><br/>
<FormGroup className='mr-2'>
<Label className='mt-2'>Balances:</Label>    
</FormGroup>
<FormGroup className='mr-2'>
<Label className='mt-2'>{data.length>0?'Kshs.'+' '+getTotalBalances1():0}</Label>    
</FormGroup>
</div>

           <div className='bg-light p-1'>
           <ToolkitProvider
  keyField="id"
  data={[...data] }
  columns={ columns }
  exportCSV={{ onlyExportFiltered: true, exportAll: true }}
  search
>
  {
    props => (
      <div>
        <ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 
       
        <BootstrapTable keyField='id'
        {...props.baseProps}
        data={ data } 
        pagination={ paginationFactory(options) }className="pgnav"  
        wrapperClasses="table-responsive"> 
         </BootstrapTable>
      </div> 
    )
  }
</ToolkitProvider>


</div> 
           </div>

           <Switch>
           <Route path='/cinvoice' component={Cinvoice}/>
          </Switch>
           </Router>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal2 modal-dialog-centered'>
           <Modal.Header closeButton onClick={closeModal1}className='modalheader1'>
           <Modal.Title className='modalheader'>{message?"Sending Payment reminder message":"Sending Invoice Email"}</Modal.Title>
           </Modal.Header>
           <Modal.Body className='modalbody1'>
      
  
          { loadspinner  && <span className='progress d-flex justify-content-center'><TailSpin color="#00BFFF" height={30} className='check pl-5' /></span>}
  {notFound && <div className='progress red mb-1'><i class="fa fa-exclamation-triangle check" aria-hidden="true"></i></div>}
  {nosms && <div className='progress red mb-1'><i class="fa fa-exclamation-triangle check" aria-hidden="true"></i></div>}
  {successicon && <span className='progress'><i class="fa fa-check check green" aria-hidden="true"></i></span>}
  {nosms && <label>You have no smss remaining in your account. Top up your sms account and try again</label>}
  {notreachable && <label>Number could not be reached. Check the client Number and try again</label>}
{notFound && <label>Server not found Error. Check if you are connected to the Internet and try again</label>}
{messageSuccess&& <span className='mb-1'><label className='mt-1'>Payment Reminder Message sent succesfully</label></span>}
{emailSuccess&& <label>Fee Invoice sent succesfully</label>}
</Modal.Body>
<Modal.Footer className='modalfooter'> <Button color="primary ml-2 closebtn"className=" m-2 mt-3" type=""onClick={closeModal1}>
Close
</Button></Modal.Footer>
       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,setId,fetchPayment})(Vinvoice)