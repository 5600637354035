import React,{useEffect,useState} from 'react'
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Vinvoice.css'
import { ToastContainer, toast } from 'react-toastify';
import { TailSpin} from  'react-loader-spinner'
import 'react-toastify/dist/ReactToastify.css';
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from "axios";
import {connect} from 'react-redux';
import { setId} from './../actions/auth';
import { Table } from 'react-bootstrap';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import { Card,Modal} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Cinvoice from './Cinvoice';
import jsPDF from "jspdf";
import "jspdf-autotable";

const Issuedbooks=({isAuthenticated,checkAuthenticated,setId,useremail,payment,fetchPayment,username1})=> {
  const [dropdown,setDropDown]=useState("");
  const [loading,setLoading]=useState(false);
  const [notFound,setNotFound]=useState(false);
  const [successicon,setSuccessIcon]=useState(false);
  const [nosms,SetNoSms]=useState(false);
  const [emailSuccess,setEmailSuccess]=useState(false);
  const [messageSuccess,setMessageSuccess]=useState(false);
  const [message,setMessage]=useState(false);
  const [loadspinner,setLoadspinner]=useState(false);
  const [total,setTotal]=useState("");
  const [search,setSearch]=useState("");
  const [deleteid,setDeleteId]=useState("");
  const [editid,setEditId]=useState("");
  const [details,setDetails]=useState([""]);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [data,setData]=useState([""]);
  const [data2,setData2]=useState([""]);
  const [customer,setCustomer]=useState([""]);
  const [data1,setData1]=useState([""]);
  const [amountrecord,setArecord]=useState([""]);
  const [invoices,setInvoices]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [success,setSuccess]=useState(false);
  const [notreachable,setNotReachable]=useState(false);
  const [dataReceived,setDataReceived]=useState([]);
  const [titleRecord,setTrecord]=useState([]);
  const [heading,setHeading]=useState(['Book ID.','Title','Issued To','Issue  Date','Return Date','Action']);
  const [percentage, setPercentage] = useState(0);
  const [inputFields, setInputFields] = useState([
    
  ])
  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;
  const options = {
    page: 1,
    sizePerPage: 10,
    nextPageText: '>',
    prePageText: '<',
    showTotal: true
  };

  const columns = [{
    dataField: 'bookid',
    text: 'Book ID.',
    headerStyle: () => {
      return { width: "100px" };
    }
  }, {
    dataField: 'title',
    text: 'Title',
    headerStyle: () => {
      return { width: "100px" };
    },

    formatter: (cellContent, row) => {
      return (
        <div>
      {getTitle(row.bookid)}
      </div>
      );
  }}, {
    dataField: 'studentadm',
    text: 'Issued To',
    headerStyle: () => {
      return { width: "100px" };
    }
  }, {
    dataField: 'dateissued',
    text: 'Issue Date',
    headerStyle: () => {
      return { width: "100px" };
    }
  }, {
    dataField: 'returnby',
    text: 'Return Date',
    headerStyle: () => {
      return { width: "100px" };
    }
  },  {
    dataField: "id",
    text: "Action",
    headerStyle: () => {
      return { width: "70px" };
    },
    csvExport: false,
    editable: false,
    formatter: (cellContent, row) => {
      return (
        <div>
       <Button className='btn primary'onClick={e =>{ handleModalShowHide1(row.id,row.bookid)}}>Return</Button>
      </div>
      );
    },
},];
  const showToastMessage = () => {

    toast.success('Record updated succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };
  const showToastMessage2 = () => {

    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 
  useEffect(()=>{
    
    //fetch total payment
   
    fetchTitle()
   
     },[]) 
     useEffect(()=>{
      
      fetchAllIssuedBooks()
      //fetch total payment
      if( detailsbkp>0){
        //generateObj()
      }
    
       },[detailsbkp]) 
     const fetchAllIssuedBooks=async()=>{
      let copy1=[];
      let data4 ;

     const fee= await axios({
       method:'post',
       url:'/totalissuedbook/',
       data:{useremail:useremail},
     })
     .then(res => {
         data4 = res.data;
         setData(data4);
       
        // setDetailsbkp(data4);
         setLoading(false)
         
          let newfield;
          for (var i = 0; i < data4.length; i++) {
            newfield = {bookid: data4[i].bookid, title: getTitle(data4[i].bookid), studentadm: data4[i].studentadm, dateissued: data4[i].dateissued, returnby:data4[i].returnby}
            
            copy1.push(newfield)
            }
     })
     .catch(err => {})
     setInputFields(copy1)
     
}
    
    const fetchTitle=async()=>{
        let copy1=[];
 //fetch amount
   let data4 ;
        
        const result = await axios({
         method:'post',
         url:'/totalbooks/',
         data:{useremail:useremail},
       })
       .then(res => {
           data4 = res.data;
          // setArecord(...amountrecord,data4);
           console.log(data4) 
           setDetailsbkp(data4);
           setLoading(false)
           copy1.push({amount:data4[0].title})
          // return(data4[0].title)
         // generateObj()
       })
       .catch(err => {})
    
    
   // setArecord(copy1);
    setTrecord(copy1);
    console.log(titleRecord)
    
    }
     const reLoadPage=()=>{
       
      window.location.reload();
  }

  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const filterList = (event) => {
    //search staff
  
    var updatedList = detailsbkp;
    updatedList = updatedList.filter(detail => detail.bookid.toLowerCase().includes(search.toLowerCase()) | detail.studentadm.toLowerCase().includes(search.toLowerCase()))
    setData(updatedList)
    
    };
  const closeModal1=()=> {
    setShowhide1(false);
    setNotFound(false)
    setMessage(false)
    setMessageSuccess(false)
    setEmailSuccess(false)
    setSuccessIcon(false)
    SetNoSms(false)
    setNotReachable(false)
    }
const getTitle=(id)=>{
  
  const filtered = detailsbkp.filter(data => {
    return data.bookid === id;
  });
  console.log(filtered)

  return filtered[0].title}
    const deleteData = async () => {
      if(useremail=="4")
      {
      showToastMessage2();
      }
      else{
      handleModalShowHide1()
         const result = await axios({
           method:'delete',
           url:`/api/issuedbook/${deleteid}/`,
         })
         .then((Response)=>{
          
            //setSuccess(true)
           // hidesuccess();
            fetchAllIssuedBooks()
                  
         })
         .catch((error) => {
          if (error.code="ERR_BAD_RESPONSE"){
           
                  }
          
         })
         //edit book record
         const result1 = await axios({
            method:'post',
            url:`/editstatus/`,
            data:{bookid:editid,useremail:useremail,status:'Available',editedby:username1}
          })
          .then((Response)=>{
           showToastMessage()
            // setSuccess(true)
            // hidesuccess();
             fetchAllIssuedBooks()
                   
          })
          .catch((error) => {
           if (error.code="ERR_BAD_RESPONSE"){
            
                   }
           
          })
        }
         }
const generateObj=()=>{
  
  if(detailsbkp.length>0 && data.length>0){
  let newfield;
  for (var i = 0; i < data.length; i++) {
    newfield = {bookid: data[0].bookid, title: getTitle(data[0].bookid), studentadm: data[0].studentadm, dateissued: data[0].dateissued, returnby:data[0].returnby}
    setInputFields([...inputFields, newfield])
    
    }}
}
         const handleModalShowHide1=(id,id1)=> {
          setDeleteId(id)
          setEditId(id1)
          setShowhide1(!showhide1);
          console.log("clicked");
        }
   
if(isAuthenticated=="false"){
  
  /*return <Redirect to='/login'/>*/}
  if(payment=="false"){
  
    return <Redirect to='/payment'/>}
    const value = 0.66;
    
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>/ Library / Issued Books</div>
      <Router>
        <div className='bodysection col-lg-8'>
        <div className=' col-lg-12 theader'>< h5>Issued Books List</h5> </div> <div className='row'>

<FormGroup className='mr-2 ml-3'>
<Label className='colorred ml-1'>Total Records:</Label>    
</FormGroup>
<FormGroup className='mr-5'>
<Label className=''>{data.length>0? inputFields.length:0}</Label>    
</FormGroup>
</div>

           <div className='bg-light p-1'>
           <ToolkitProvider
  keyField="id"
  data={[...inputFields] }
  columns={ columns }
  exportCSV={{ onlyExportFiltered: true, exportAll: false }}
  search
>
  {
    props => (
      <div>
        <ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 
        
        <SearchBar { ...props.searchProps } />
       
        <BootstrapTable keyField='id'
        {...props.baseProps}
         data={ inputFields } 
         columns={ columns } 
         pagination={ paginationFactory(options) }className="pgnav"   
         wrapperClasses="table-responsive">
         </BootstrapTable>
      </div> 
    )
  }
</ToolkitProvider>
</div>  
           </div>

           <Switch>
           <Route path='/cinvoice' component={Cinvoice}/>
          </Switch>
           </Router>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'green','font-size':'18px'}}>Return Book?</label><br/>
           <label style={{'font-size':'15px'}}>You have clicked on the return book button. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="success ml-2 "className="  pb-1 " type=""onClick={deleteData}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,setId,fetchPayment})(Issuedbooks)