import React,{useEffect,useState} from 'react'
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Vinvoice.css'
import {toast} from 'react-toastify';
import { TailSpin} from  'react-loader-spinner'
  import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {connect} from 'react-redux';
import { setId} from './../actions/auth';
import Table  from 'react-bootstrap/Table';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import { Card,Modal} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Cinvoice from './Cinvoice';
import jsPDF from "jspdf";
import "jspdf-autotable";

const Vinvoice=({isAuthenticated,checkAuthenticated,setId,useremail,payment,fetchPayment})=> {
  const [dropdown,setDropDown]=useState("");
  const [loading,setLoading]=useState(false);
  const [notFound,setNotFound]=useState(false);
  const [successicon,setSuccessIcon]=useState(false);
  const [nosms,SetNoSms]=useState(false);
  const [emailSuccess,setEmailSuccess]=useState(false);
  const [messageSuccess,setMessageSuccess]=useState(false);
  const [message,setMessage]=useState(false);
  const [loadspinner,setLoadspinner]=useState(false);
  const [total,setTotal]=useState("");
  const [search,setSearch]=useState("");
  const [details,setDetails]=useState([""]);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [data,setData]=useState([""]);
  const [data2,setData2]=useState([""]);
  const [customer,setCustomer]=useState([""]);
  const [data1,setData1]=useState([""]);
  const [invoices,setInvoices]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [notreachable,setNotReachable]=useState(false);
  const [dataReceived,setDataReceived]=useState([]);
  const [heading,setHeading]=useState(['Invoice No.', 'Issue date.', 'Due date','Customer No.','Status','Action']);
  const [percentage, setPercentage] = useState(0);
 
  useEffect(() => {
    setTimeout(() => {
      if (percentage < 100) {
        setPercentage(percentage + 1);
       
      }
    }, 25);
    if(percentage==100){
      
      setPercentage(0);
    }
  }, [percentage]);
  toast.configure()
  const showToast = () => toast("Operation succesful",{position: toast.POSITION.TOP_CENTER});
  useEffect(()=>{
    
     //fetch total invoices
     
    fetchAllInvoices()
    //fetch sales
    
      fetchAllSales()

      //fetch account details
      let data1 ;

      axios({
        method:'post',
        url:'/totalcompany/',
        data:{useremail:useremail},
      })
      .then(res => {
          data1 = res.data;
          setData2(data1);
        }
      )
    
      },[]) 
      const closeModal1=()=> {
        setShowhide1(false);
        setNotFound(false)
        setMessage(false)
        setMessageSuccess(false)
        setEmailSuccess(false)
        setSuccessIcon(false)
        SetNoSms(false)
        setNotReachable(false)
        }
      const createPdf=(data)=>{
       
        axios({
          method:'post',
          url:'/pdfcreate/',
          data:{email:useremail,phone:data.customerid,invoicenumber:data.invoicenumber,issuedate:data.issuedate,duedate:data.duedate,status:data.status},
        })
        .then((Response)=>{
         //clear fields
         const file = new Blob([Response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
        })
      }
      const handleModalShowHide1=()=> {
        setShowhide1(!showhide1);
        console.log("clicked");
      }
      const sendPdf=(data)=>{
        setLoadspinner(true)
        handleModalShowHide1()
        axios({
          method:'post',
          url:'/upload/',
          data:{email:useremail,phone:data.customerid,invoicenumber:data.invoicenumber,issuedate:data.issuedate,duedate:data.duedate,status:data.status},
        }).then((Response)=>{
          if (Response.data="success"){
            setEmailSuccess(true)
            setLoadspinner(false)
            setSuccessIcon(true)
                  }
        })
        .catch((error) => {
          if (error.code="ERR_BAD_RESPONSE"){
    setNotFound(true)
    setLoadspinner(false)
          }
          
        })
      }
      const sendSms=(data1)=>{
        
        setMessage(true)
     handleModalShowHide1()
     setLoadspinner(true)
    axios({
      method:'post',
      url:'/smssend/',
      data:{email:useremail,phone:data1.customerid,invoicenumber:data1.invoicenumber,duedate:data1.duedate},
    })
    .then((response) => {
      console.log(response.data)
        if(response.data=="failed"){
           setNotReachable(true)
            }
            if (response.data=="success"){
              console.log(response.data)
              setMessageSuccess(true)
              setSuccessIcon(true)
              setLoadspinner(false)
                    }
                    if (response.data=="nosms"){
            alert("really")
              setSuccessIcon(false)
              SetNoSms(true)
    }})
    .catch((error) => {
      if (error.code="ERR_BAD_RESPONSE"){
setNotFound(true)
setLoadspinner(false)
      }
      
    })
    
      }
      const reLoadPage=()=>{
       
        window.location.reload();
    }
    const fetchAllInvoices=()=>{
      let data4 ;
      axios({
       method:'post',
       url:'/totalinvoices/',
       data:{useremail:useremail},
     })
     .then(res => {
         data4 = res.data;
         setData(data4);
         console.log(data4) 
         setDetailsbkp(data4);
         setLoading(false)
     })
     .catch(err => {})}
    const fetchAllSales=()=>{
      axios({
        method:'post',
        url:'/totalsales/',
        data:{useremail:useremail},
      })
      .then((Response)=>{
         //set record
       const datareceived=Response.data
       setData1(datareceived)
      
        })}
    //funtion to generate pdfs
    // define a generatePDF function that accepts a tickets argument
    const generatePDF = async (invoices) => {
  setInvoices(invoices);
//fetch data
try {
  
  console.log(dataReceived)
  const resp = await axios.post('/salesearch/', {invoicenumber:invoices.invoicenumber});
  console.log(resp.data);
  setDataReceived(resp.data)
    console.log(dataReceived)

} catch (err) {
  // Handle Error Here
  console.error(err);
}
//fetch customer
axios({
  method:'post',
  url:'/customersearch/',
  data:{mobile:invoices.customerid},
})
.then((Response)=>{
 const datareceived=Response.data;
setCustomer(datareceived)
})
  
};
const filterList = (event) => {
  //get first character
  var first=Array.from(search)[0];

  if(first==0)
  {
    var newsearch = search.substring(1);
    
  }
  console.log(detailsbkp)
  
  var updatedList = detailsbkp;
  updatedList = updatedList.filter(detail => detail.duedate.includes(search) | detail.customerid.includes(newsearch? newsearch:search) | detail.status.toLowerCase().includes(search.toLowerCase()))
  setData(updatedList)
  
  };
useEffect(() => {
  if(dataReceived.length!=0){
// define the columns we want and their titles
const tableColumn = ["Product", "Quantity", "Price", "Total"];
// define an empty array of rows
const tableRows = [];

// for each ticket pass all its data into an array
dataReceived.forEach(data => {
  const ticketData = [
    data.product,
    data.quantity,
    data.price,
    data.quantity*data.price
   
  ];
 
  // push each tickcet's info into a row
  tableRows.push(ticketData);
 
});
// initialize jsPDF
const doc = new jsPDF();
//get the total
const res=dataReceived.reduce((prev,item)=>{
         
  return prev +(item.price*item.quantity);
},0)

const total = [
  "",
  "",
  "Total",
  res
 
];

tableRows.push(total);
tableRows.push(doc.line(20,20,20,20));

// startY is basically margin-top
doc.autoTable(tableColumn, tableRows,{ startY: 100 });
let finalY = doc.previousAutoTable.finalY; // The y position on the page
doc.line(128, finalY-15, 170, finalY-15);
doc.line(128, finalY-8, 170, finalY-8);
const date = Date().split(" ");
// we use a date string to generate our filename.
const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
// ticket title. and margin-top + margin-left

doc.text("INVOICE.", 100, 15);
doc.line(20, 22, 200, 22);
doc.line(20, 59, 200, 59);
doc.setFontSize(12);
doc.text(` ${data2[0].names}`, 20,30 );
doc.text(` ${data2[0].town}`, 20, 36);
doc.text(` ${data2[0].building} Building`, 20, 42);
doc.text(` ${'0'+data2[0].mobile}`, 20, 48);
doc.text(` ${useremail}`, 20, 54);
doc.text("Bill to:", 20, 64);
doc.text(`Invoice Number: ${invoices.invoicenumber}`, 120,65);
doc.text(`Issue Date: ${invoices.issuedate}`, 120,72);
doc.text(`Due Date: ${invoices.duedate}`, 120,79);
doc.text(`Status: ${invoices.status}`, 120,86);
doc.text(`${customer[0].names}`, 20,70);
doc.text(` ${customer[0].email}`,20,77);
doc.text(`${'0'+customer[0].mobile}`, 20,84);
doc.text(`${invoices.town?invoices.town:''}`, 20,91);
// we define the name of our PDF file.
doc.save(`invoice_${dateStr}.pdf`);


}}, [dataReceived && customer]);
useEffect(()=>{
  checkAuthenticated();
  fetchPayment(useremail)
    },[]) 
    useEffect(()=>{
      
      
        },[data]) 
    useEffect(()=>{
      filterRecords();
      
        },[dropdown]) 


useEffect(()=>{

  if(data1.length!=0){
  //calculate total sales
  setTotal(0)
  const res=data1.reduce((prev,item)=>{
         
   return prev +(item.price*item.quantity);
 },0)
 setTotal(res)
  }
       },[data1])
const filterRecords=()=>{
  setLoading(true)
  //fect invoices
  let data1
  let data2
  if(dropdown=="All")
  {
   
fetchAllSales()
fetchAllInvoices()
  }
  if(dropdown=="Today")
  {
  
  axios({
    method:'post',
    url:'/filterinvoices/',
    data:{useremail:useremail,days:0},
  })
  .then(res => {
      data1 = res.data;
      setData(data1);
      console.log(data1) 
      setDetailsbkp(data1);
      setLoading(false)
  })
  .catch(err => {})
  //FETCH SALES
  axios({
    method:'post',
    url:'/filtersales/',
    data:{useremail:useremail,days:0},
  })
  .then(res => {
      data2 = res.data;
      setData1(data2);
      
  })
  .catch(err => {})
}
if(dropdown=="Yesterday")
  {
  axios({
    method:'post',
    url:'/filterinvoices/',
    data:{useremail:useremail,days:1},
  })
  .then(res => {
      data1 = res.data;
      setData(data1);
      console.log(data1) 
      setDetailsbkp(data1);
      setLoading(false)
  })
  .catch(err => {})
  axios({
    method:'post',
    url:'/filtersales/',
    data:{useremail:useremail,days:1},
  })
  .then(res => {
      data2 = res.data;
      setData1(data2);
      
  })
  .catch(err => {})
}
if(dropdown=="Last 7 days")
  {
  axios({
    method:'post',
    url:'/filterinvoices/',
    data:{useremail:useremail,days:6},
  })
  .then(res => {
      data1 = res.data;
      setData(data1);
      console.log(data1) 
      setDetailsbkp(data1);
      setLoading(false)
  })
  .catch(err => {})
  axios({
    method:'post',
    url:'/filtersales/',
    data:{useremail:useremail,days:6},
  })
  .then(res => {
      data2 = res.data;
      setData1(data2);
      
  })
  .catch(err => {})
}
if(dropdown=="Last 30 days")
  {
  axios({
    method:'post',
    url:'/filterinvoices/',
    data:{useremail:useremail,days:30},
  })
  .then(res => {
      data1 = res.data;
      setData(data1);
      console.log(data1) 
      setDetailsbkp(data1);
      setLoading(false)
  })
  .catch(err => {})
  axios({
    method:'post',
    url:'/filtersales/',
    data:{useremail:useremail,days:30},
  })
  .then(res => {
      data2 = res.data;
      setData1(data2);
      
  })
  .catch(err => {})
}
if(dropdown=="Last 90 days")
  {
  axios({
    method:'post',
    url:'/filterinvoices/',
    data:{useremail:useremail,days:90},
  })
  .then(res => {
      data1 = res.data;
      setData(data1);
      console.log(data1) 
      setDetailsbkp(data1);
      setLoading(false)
  })
  .catch(err => {})
  axios({
    method:'post',
    url:'/filtersales/',
    data:{useremail:useremail,days:90},
  })
  .then(res => {
      data2 = res.data;
      setData1(data2);

  })
  .catch(err => {})
}
}
if(isAuthenticated=="false"){
  
  return <Redirect to='/login'/>}
  if(payment=="false"){
  
    return <Redirect to='/payment'/>}
    const value = 0.66;
    
  return (
    <div>
      <Router>
        <div className='bodysection col-lg-8'>
<       h4>Invoice List</h4> <div className='row'><FormGroup className='mr-5'>{loading &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>loading...</span>}

<select id="cars" name="carlist" form="carform"onChange={e => {setDropDown(e.target.value)}} className='select'style={{width:"150px",height:"37px",'border-color':"gainsboro"}}>

  <option value="All" >All</option>
  <option value="Today" >Today</option>
  <option value="Yesterday" >Yesterday</option>
  <option value="Last 7 days" >Last 7 days</option>
  <option value="Last 30 days" >Last 30 days</option>
  <option value="Last 90 days" >Last 90 days</option>
  
</select>
</FormGroup>
<FormGroup className='mr-5'>
<Input type="text" placeholder="Search..."name="town" value={search} onChange={e =>{ setSearch(e.target.value);filterList()}}onKeyDown={filterList}onKeyUp={filterList} required />
        
</FormGroup>
<FormGroup className='mr-2'>
<Label className='mt-2'>Total Sales:</Label>    
</FormGroup>
<FormGroup className='mr-5'>
<Label className='mt-2'>{data.length>0?'Kshs.'+' '+total:0}</Label>    
</FormGroup>
</div>

<Table responsive className=" pl-0">
  <thead>
    <tr >
    {heading.map(head => <th>{head}</th>)}
    </tr>
  </thead>
 
                <tbody>     
                {data.length==0? <div>No Records found</div>:data.map(detail => (
                  <tr key={detail.id} >
                     <td className="col-2" >{detail.invoicenumber}</td>
                     <td class="col-2">{detail.issuedate}</td>
                     <td class="col-2">{detail.duedate}</td>
                     <td class="col-2">{ detail.customerid}</td>
                     <td class="col-2">{detail.status}</td>
                     <td class="col-2"><div className='row '><div onClick={e =>{ setId(detail.customerid);reLoadPage()}} className="border"><Link exact to={`/cinvoice/${detail.invoicenumber}`} >
                     <span className=''><i  class="fa fa-edit btn1"></i></span></Link></div><span className='border'><i class="fa fa-download btn1 border1"onClick={e =>{ createPdf(detail)}}></i></span><span className='border'><i className="fa fa-envelope btn1 pl-0"onClick={e =>{ sendPdf(detail)}}></i></span><span className='border'><i class="fa fa-comment btn1"onClick={e =>{ sendSms(detail)}}></i></span></div></td>
                   </tr>
             ))}
    </tbody>
    
     
           </Table> 
           
          
          
           </div>

           <Switch>
           <Route path='/cinvoice' component={Cinvoice}/>
          </Switch>
           </Router>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           <Modal.Header closeButton onClick={closeModal1}className='modalheader1'>
           <Modal.Title className='modalheader'>{message?"Sending message":"Sending Email"}</Modal.Title>
           </Modal.Header>
           <Modal.Body className='modalbody'>
      
  
          { loadspinner  && <span className='progress'><TailSpin color="#00BFFF" height={30} className='check pl-5' /></span>}
  {notFound && <div className='progress red mb-1'><i class="fa fa-exclamation-triangle check" aria-hidden="true"></i></div>}
  {nosms && <div className='progress red mb-1'><i class="fa fa-exclamation-triangle check" aria-hidden="true"></i></div>}
  {successicon && <span className='progress'><i class="fa fa-check check green" aria-hidden="true"></i></span>}
  {nosms && <label>You have no smss remaining in your account. Top up your sms account and try again</label>}
  {notreachable && <label>Number could not be reached. Check the client Number and try again</label>}
{notFound && <label>Server not found Error. Check if you are connected to the Internet and try again</label>}
{messageSuccess&& <span className='mb-1'><label className='mt-1'>Payment Reminder Message sent succesfully</label></span>}
{emailSuccess&& <label>Invoice sent succesfully</label>}
</Modal.Body>
<Modal.Footer className='modalfooter'> <Button color="primary ml-2 closebtn"className=" m-2 mt-3" type=""onClick={closeModal1}>
Close
</Button></Modal.Footer>
       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,setId,fetchPayment})(Vinvoice)