import React,{useEffect,useState} from 'react'
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Vinvoice.css'
import {toast} from 'react-toastify';
import { TailSpin} from  'react-loader-spinner'
  import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {connect} from 'react-redux';
import { setId} from './../actions/auth';
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Table } from 'react-bootstrap';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import { Card,Modal} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Cinvoice from './Cinvoice';
import jsPDF from "jspdf";
import "jspdf-autotable";

const Viewbooks=({isAuthenticated,checkAuthenticated,setId,useremail,payment,fetchPayment})=> {
  const [dropdown,setDropDown]=useState("");
  const [loading,setLoading]=useState(false);
  const [notFound,setNotFound]=useState(false);
  const [successicon,setSuccessIcon]=useState(false);
  const [nosms,SetNoSms]=useState(false);
  const [emailSuccess,setEmailSuccess]=useState(false);
  const [messageSuccess,setMessageSuccess]=useState(false);
  const [message,setMessage]=useState(false);
  const [loadspinner,setLoadspinner]=useState(false);
  const [total,setTotal]=useState("");
  const [search,setSearch]=useState("");
  const [deleteid,setDeleteId]=useState("");
  const [details,setDetails]=useState([""]);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [data,setData]=useState([""]);
  const [data2,setData2]=useState([""]);
  const [customer,setCustomer]=useState([""]);
  const [data1,setData1]=useState([""]);
  const [amountrecord,setArecord]=useState([""]);
  const [invoices,setInvoices]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [success,setSuccess]=useState(false);
  const [notreachable,setNotReachable]=useState(false);
  const [dataReceived,setDataReceived]=useState([]);
  const [titleRecord,setTrecord]=useState([]);
  const [heading,setHeading]=useState(['Book ID.','Title','Author','Subject','Status']);
  const [percentage, setPercentage] = useState(0);
  
  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;
  const options = {
    page: 1,
    sizePerPage: 10,
    nextPageText: '>',
    prePageText: '<',
    showTotal: true
  };

  const columns = [{
    dataField: 'bookid',
    text: 'Book ID',
    headerStyle: () => {
      return { width: "100px" };
    }
  }, {
    dataField: 'title',
    text: 'Title',
    headerStyle: () => {
      return { width: "100px" };
    }
  }, {
    dataField: 'author',
    text: 'Author',
    headerStyle: () => {
      return { width: "100px" };
    }
  }, {
    dataField: 'subject',
    text: 'Subject',
    headerStyle: () => {
      return { width: "100px" };
    }
  },{
    dataField: 'status',
    text: 'Status',
    headerStyle: () => {
      return { width: "100px" };
    }
  },  ];
  useEffect(()=>{
    
    //fetch total payment
    
   fetchAllBooks()
   
     },[]) 
     
     const fetchAllBooks=async()=>{
      
      let data4 ;

     const fee= await axios({
       method:'post',
       url:'/totalbooks/',
       data:{useremail:useremail},
     })
     .then(res => {
         data4 = res.data;
         setData(data4);
         console.log(data4) 
         setDetailsbkp(data4);
         setLoading(false)
     })
     .catch(err => {})
   
}
    
   
     const reLoadPage=()=>{
       
      window.location.reload();
  }

  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const filterList = (event) => {
    //search staff
  
    var updatedList = detailsbkp;
    updatedList = updatedList.filter(detail => detail.bookid.toLowerCase().includes(search.toLowerCase()) | detail.title.toLowerCase().includes(search.toLowerCase())| detail.status.toLowerCase().includes(search.toLowerCase()))
    setData(updatedList)
    
    };
  const closeModal1=()=> {
    setShowhide1(false);
    setNotFound(false)
    setMessage(false)
    setMessageSuccess(false)
    setEmailSuccess(false)
    setSuccessIcon(false)
    SetNoSms(false)
    setNotReachable(false)
    }

    const deleteData = async () => {
      handleModalShowHide1()
         const result = await axios({
           method:'delete',
           url:`http://localhost:8000/api/feepayment/${deleteid}/`,
         })
         .then((Response)=>{
          
            setSuccess(true)
            hidesuccess();
            fetchAllBooks()
                  
         })
         .catch((error) => {
          if (error.code="ERR_BAD_RESPONSE"){
           
                  }
          
         })
         
         }

         const handleModalShowHide1=(id)=> {
          setDeleteId(id)
          setShowhide1(!showhide1);
          console.log("clicked");
        }
   
if(isAuthenticated=="false"){
  
 /* return <Redirect to='/login'/>*/}
  if(payment=="false"){
  
    return <Redirect to='/payment'/>}
    const value = 0.66;
    
  return (
    <div>
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>/ Library / View Books</div>
      <Router>
        <div className='bodysection col-lg-8'>
        <div className=' col-lg-12 theader'>< h5>Books List</h5> </div> <div className='row '>

<FormGroup className='mr-2 ml-3'>
<Label className=''>Total Records:</Label>    
</FormGroup>
<FormGroup className='mr-5'>
<Label className=''>{data.length>0? data.length:0}</Label>    
</FormGroup>
</div>

           <div className='bg-light p-1'>
           <ToolkitProvider
  keyField="id"
  data={[...data] }
  columns={ columns }
  exportCSV={{ onlyExportFiltered: true, exportAll: false }}
  search
>
  {
    props => (
      <div>
        <ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 
        
        <SearchBar { ...props.searchProps } />
       
        <BootstrapTable keyField='id'
        {...props.baseProps}
         data={ data } 
         columns={ columns } 
         pagination={ paginationFactory(options) }className="pgnav"   
         wrapperClasses="table-responsive">
         </BootstrapTable>
      </div> 
    )
  }
</ToolkitProvider>
</div>    
           </div>

           <Switch>
           <Route path='/cinvoice' component={Cinvoice}/>
          </Switch>
           </Router>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Fee Type?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting a Fee Type Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={deleteData}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,setId,fetchPayment})(Viewbooks)