import axios from 'axios';
const baseURL='/auth';
const axiosInstance=axios.create({
baseURL:baseURL,
timeout:5000,
headers:{
    Authorization:localStorage.getItem('access')
    ?'JWT' + localStorage.getItem('access')
    :null,
    'Content-Type':'application/json',
    acccept:'application/json',
},

});

export default axiosInstance;
