import React,{useEffect,useState} from 'react'
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Vinvoice.css'
import { ToastContainer, toast } from 'react-toastify';
import { TailSpin} from  'react-loader-spinner'
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
  import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {connect} from 'react-redux';
import { setId} from './../actions/auth';
import { Table } from 'react-bootstrap';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import { Card,Modal} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Cinvoice from './Cinvoice';
import jsPDF from "jspdf";
import "jspdf-autotable";

const Viewclass=({isAuthenticated,checkAuthenticated,setId,useremail,payment,fetchPayment,username})=> {
  const [dropdown,setDropDown]=useState("");
  const [loading,setLoading]=useState(false);
  const [notFound,setNotFound]=useState(false);
  const [successicon,setSuccessIcon]=useState(false);
  const [nosms,SetNoSms]=useState(false);
  const [emailSuccess,setEmailSuccess]=useState(false);
  const [messageSuccess,setMessageSuccess]=useState(false);
  const [message,setMessage]=useState(false);
  const [loadspinner,setLoadspinner]=useState(false);
  const [total,setTotal]=useState("");
  const [search,setSearch]=useState("");
  const [deleteid,setDeleteId]=useState("");
  const [details,setDetails]=useState([""]);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [data,setData]=useState([""]);
  const [data2,setData2]=useState([""]);
  const [customer,setCustomer]=useState([""]);
  const [data1,setData1]=useState([""]);
  const [invoices,setInvoices]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [success,setSuccess]=useState(false);
  const [notreachable,setNotReachable]=useState(false);
  const [dataReceived,setDataReceived]=useState([]);
  const [heading,setHeading]=useState(['Exam Name','Class','Start Date','End Date','Action']);
  const [percentage, setPercentage] = useState(0);

  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;
  const options = {
    page: 1,
    sizePerPage: 10,
    nextPageText: '>',
    prePageText: '<',
    showTotal: true
  };

  const columns = [{
    dataField: 'examname',
    text: 'Exam Name.',
    headerStyle: () => {
      return { width: "150px" };
    }
  }, {
    dataField: 'classname',
    text: 'Class',
    headerStyle: () => {
      return { width: "100px" };
    }
  }, {
    dataField: 'startdate',
    text: 'Start Date',
    headerStyle: () => {
      return { width: "100px" };
    }
  }, {
    dataField: 'enddate',
    text: 'End Date',
    headerStyle: () => {
      return { width: "100px" };
    }
  },  {
    dataField: "id",
    text: "Action",
    headerStyle: () => {
      return { width: "100px" };
    },
    csvExport: false,
    editable: false,
    formatter: (cellContent, row) => {
      return (
        <div>
      <div className='row '><div onClick={e =>{ setId(row.customerid);reLoadPage()}} className="border"><Link exact to={`/editexam/${row.id}`}onClick={reLoadPage} >
                     <span className=''><i  class="fa fa-edit btn1"></i></span></Link></div><span className='border'><i class="fa fa-trash btn1 border1" onClick={e =>{ handleModalShowHide1(row.id)}}></i></span><div onClick={e =>{ setId(row.customerid);reLoadPage()}} className="border"><Link exact to={`/viewmarks/${row.examname && row.examname.replace(/\s/g,"-")}`} >
                     <span className=''><i  class="fa fa-eye btn1"></i></span></Link></div></div>
      </div>
      );
    },
},];
  const showToastMessage = () => {
    toast.success('Record deleted succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 
  useEffect(()=>{
    
    //fetch total exams
    
   fetchAllExams()
  
   
     },[]) 
     const fetchAllExams=()=>{
      
      let data4 ;
      axios({
       method:'post',
       url:'/totalexam/',
       data:{useremail:useremail},
     })
     .then(res => {
         data4 = res.data;
         setData(data4);
         console.log(data4) 
         setDetailsbkp(data4);
         setLoading(false)
     })
     .catch(err => {})}

     const reLoadPage=()=>{
       
      window.location.reload();
  }

  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const filterList = (event) => {
    //search staff
  
    var updatedList = detailsbkp;
    updatedList = updatedList.filter(detail => detail.examname.toLowerCase().includes(search.toLowerCase()) | detail.classname.toLowerCase().includes(search.toLowerCase()) )
    setData(updatedList)
    
    };
  const closeModal1=()=> {
    setShowhide1(false);
    setNotFound(false)
    setMessage(false)
    setMessageSuccess(false)
    setEmailSuccess(false)
    setSuccessIcon(false)
    SetNoSms(false)
    setNotReachable(false)
    }
    const showToastMessage2 = () => {
     
      toast.error('You dont have permission to perform this action', {
          position: toast.POSITION.TOP_CENTER
      });
    }; 
    const deleteData = async () => {
      if(useremail=="4")
      {
showToastMessage2();
      }
      else{
      handleModalShowHide1()
         const result = await axios({
           method:'put',
           url:`/api/exam/${deleteid}/`,
           data:{isdeleted:"deleted",deletedby:username}
         })
         .then((Response)=>{
          showToastMessage()
           // setSuccess(true)
           // hidesuccess();
            fetchAllExams()
                  
         })
         .catch((error) => {
          if (error.code="ERR_BAD_RESPONSE"){
           
                  }
          
         })
        }
         }

         const handleModalShowHide1=(id)=> {
          setDeleteId(id)
          setShowhide1(!showhide1);
          console.log("clicked");
        }
   
if(isAuthenticated=="false"){
  
  /*return <Redirect to='/login'/>*/}
  if(payment=="false"){
  
    /*return <Redirect to='/payment'/>*/}
    const value = 0.66;
    
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>/ Exams / View Exams</div>
      <Router>
        <div className='bodysection col-lg-5'>
        <div className=' col-lg-12 theader'>< h5>Exam List</h5> </div><div className='row'>

<FormGroup className='mr-2'>
<Label className='mt-2 ml-3'>Total Exams:</Label>    
</FormGroup>
<FormGroup className='mr-5'>
<Label className='mt-2'>{data.length>0? data.length:0}</Label>    
</FormGroup>
</div>
<div class=" bg-light p-1">

           <ToolkitProvider
  keyField="id"
  data={[...data] }
  columns={ columns }
  exportCSV={{ onlyExportFiltered: true, exportAll: false }}
  search
>
  {
    props => (
      <div>
        <ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 
        
        <SearchBar { ...props.searchProps } />
       
        <BootstrapTable keyField='id'
        {...props.baseProps}
         data={ data } 
         columns={ columns } 
         pagination={ paginationFactory(options) }className="pgnav"
         wrapperClasses="table-responsive">  

         </BootstrapTable>
      </div> 
    )
  }
</ToolkitProvider>
           </div>
          
           </div>

           <Switch>
           <Route path='/cinvoice' component={Cinvoice}/>
          </Switch>
           </Router>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Exam?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting Exam Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={deleteData}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username:state.auth.username1,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,setId,fetchPayment})(Viewclass)